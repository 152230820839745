
export const FooterData =[
    {
        fotterTitles: 'eGRADTutor',
        text: 'eGRADTutor started with a team of graduates from IIT/IISc.We are experts in training students for UG, PG, MBA and CA entrance examinations.',
        footerCLass: 'footerContant1',
        footerCs: 'footerTitleColor',
        fb: '',
        insta: '',
        linkedin: '',
        youtube :'',
        faq: ' Frequently Asked Question',
        faqLink: '/faq',
        PrivacyPolicy: '/policy',
        TermsAndConditions: '/terms',

        RefundPolicy: '/CancellationRefundPolicy',
        home: 'Privacy Policy',
        about: 'Terms and conditions',
        career:'Pricing & Refund Policy',
        footerClass: 'footerNav',
        footerlinksug:'footerlinksug',
        footer_l: 'footer_link',

        pricingpolicy: 'Pricing Policy',
        pricingpolicylink: '/pricingpolicy'
    },
    // {
    //     // fotterTitles: 'Permalinks',
    //     // home: 'Home',
    //     // about: 'About',
    //     // career: 'Career',
    //     // contact: 'Contact',
    //     // footerClass: 'footerNav',
    //     // footerCLass: 'footerContant2',
    //     // footerCs: 'footerTitleColor',
    //     // PrivacyPolicy: '',
    //     // TermsAndConditions: '',
    //     // RefundPolicy: '',
       

    // },
    // {
    //     // fotterTitles: 'Privacy',
      
    //     // home: 'Privacy Policy',
    //     // about: 'Terms and conditions',
    //     // career:' Cancellation & Refund Policy',
    //     // footerClass: 'footerNav',
    //     // footerCLass: 'footerContant3',
    //     // footerCs: 'footerTitleColor',
       
        



    //     // RefundPolicy: '/refund',

    // },
    {
        fotterTitles: 'Contact Us',
        home: "H.NO-2-2-1132/10/C, R.K Nivas, 2nd Floor, Opposite Bakers Q, Shivam Road, New Nallakunta, Hyderabad-44.",
        about: '+91-7993270532',
        career: 'info@egradtutor.in',
        footerClass: 'footerNav',
        footerCLass: 'footerContant',
        footerCs: 'footerTitleColor',
        // icon: 'icons'
        PrivacyPolicy: '',
        TermsAndConditions: '',
        RefundPolicy: '',
        fb: 'fa-brands fa-facebook',
        insta: 'fa-brands fa-instagram',
        linkedin: 'fa-brands fa-linkedin',
        youtube :'fa-brands fa-youtube'


    }

]
