import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import TermsandConditions from "../TermsandConditions.pdf";
import { IoIosArrowBack } from "react-icons/io";
import '../NewOtsUgCss.css'

const JEE_MAINS_General_instructions = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const { examId, mockTestId,mockTestTypeId } = useParams();

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Function to navigate to instructions page
  const handleClick = () => {
    navigate(`/Instructionjee/${examId}/${mockTestId}/${mockTestTypeId}`);
  };

  // Function to handle the button click
  const handleBegin = () => {
    
    if (isChecked) {
      const url = `/Jee_mains_1/${examId}/${mockTestId}/${mockTestTypeId}`;
      navigate(url, { state: { examId, mockTestId } });
    } else {
      alert("Please accept all the terms and conditions!");
    }
  };

  return (
    <div className="Genralinstructions_containercss">
      <div>
        <div className="remaining">
          <div className="bitsatMockHeader">
            <img
              src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
              alt=""
            />
          </div>
          <h3 className='SubmitFormAndMail_h2'>GENERAL INSTRUCTIONS</h3>
        </div>
      </div>
      <ul className="Genralinstructions_list">
        <div className='Jee_mains_general_instructions_sub_heading'>
          <div className="Jee_mains_general_instructions_tables_container">
            <h2>JEE Main Exam Pattern (Paper 1)</h2>
            <table className='mock_test_first_table'>
              <thead>
                <tr>
                  <th>Particulars</th>
                  <th>BTech/BE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Exam mode</td>
                  <td>Online (Computer based)</td>
                </tr>
                <tr>
                  <td>Number of sections and subjects</td>
                  <td>3 (Physics, Chemistry, and Mathematics)</td>
                </tr>
                <tr>
                  <td>Duration of exam</td>
                  <td>3 hours (180 minutes) | 4 hours for PwD candidates</td>
                </tr>
                <tr>
                  <td>Type of questions</td>
                  <td>MCQs: 4 options with only 1 correct option<br />Numerical Value Questions: Questions whose answers are to be filled in as a numerical value</td>
                </tr>
                <tr>
                  <td>Section A (MCQ)</td>
                  <td>Mathematics: 20<br />Physics: 20<br />Chemistry: 20</td>
                </tr>
                <tr>
                  <td>Section B (Numerical Value)</td>
                  <td>Mathematics: 10<br />Physics: 10<br />Chemistry: 10<br />In Section B, candidates have to attempt any five questions out of 10.</td>
                </tr>
                <tr>
                  <td>How many questions in JEE Mains</td>
                  <td>90</td>
                </tr>
                <tr>
                  <td>JEE Mains Total Marks</td>
                  <td>300 marks</td>
                </tr>
              </tbody>
            </table>
            <h2>JEE Main 2024 Exam Pattern (Paper 2)</h2>
            <table className='jee_mock_test_second_table'>
              <thead>
                <tr>
                  <th>Particulars</th>
                  <th>BArch Exam Pattern</th>
                  <th>BPlanning Exam Pattern</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Exam mode</td>
                  <td>Online (Mathematics and Aptitude) and offline (Drawing test)</td>
                  <td>Online</td>
                </tr>
                <tr>
                  <td>Number of sections and subjects</td>
                  <td>3 (Drawing, Mathematics, and Aptitude)</td>
                  <td>3 (Mathematics, Aptitude, and Planning Based Questions)</td>
                </tr>
                <tr>
                  <td>Duration of exam</td>
                  <td>3 hours (180 minutes)</td>
                  <td>3 hours (180 minutes)</td>
                </tr>
                <tr>
                  <td>Sections</td>
                  <td>
                    Mathematics (Part I): 20 MCQ and 10 Numerical Value Questions<br />
                    Aptitude Test (Part II): 50<br />
                    Drawing Test (Part III): 2
                  </td>
                  <td>
                    Mathematics: 20 questions and 10 Numerical Value Questions<br />
                    Aptitude: 50<br />
                    Planning Based Questions: 25
                  </td>
                </tr>
                <tr>
                  <td>Number of questions</td>
                  <td>82</td>
                  <td>105</td>
                </tr>
                <tr>
                  <td>Type of questions</td>
                  <td>
                    Mathematics and Aptitude: Objective-type questions<br />
                    Drawing: Questions to test the candidate’s drawing and sketching skills
                  </td>
                  <td>
                    Mathematics and Aptitude: Objective-type questions<br />
                    Planning-Based Questions: Multiple-Choice Questions
                  </td>
                </tr>
                <tr>
                  <td>Total Marks of JEE Mains</td>
                  <td>400 marks (Drawing section will be of a total of 100 marks, marking of every question will be given along with it)</td>
                  <td>400</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='jee_mock_test_checkbox_container'>
            <input 
              type="checkbox" 
              onChange={handleCheckboxChange} 
              className="checkbox" 
              checked={isChecked} // Ensure the checkbox reflects the current state
            />
            <span style={{ fontWeight: 500, color: "black" }}>
              I accept all the
              <a 
                href={TermsandConditions} 
                target="_blank" 
                style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem" }}
                rel="noopener noreferrer" // Improve security
              >
                terms & conditions.
              </a>
            </span>
          </div>
        </div>
      </ul>

      <div className="intro_next_btn_container">
        <div>
          <button className="previous-button" onClick={handleClick}>
            <IoIosArrowBack /> Previous 
          </button>
        </div>

        <button 
          className='ug_mocktest_start_button'
          onClick={handleBegin} // Call handleBegin without parentheses
          disabled={!isChecked} // Disable the button if checkbox is not checked
          style={{ 
            backgroundColor: isChecked ? 'skyblue' : 'white', 
            color: isChecked ? 'white' : 'black',
            border: '1px solid #76cbe6',
            cursor: isChecked ? 'pointer' : 'not-allowed'// Optional: Add a border to make the button more visible
          }}
          title={!isChecked ? 'Please accept the terms' : ''} 
        >
          I am ready to begin <AiOutlineArrowRight />
        </button>
      </div>
    </div>
  );
}

export default JEE_MAINS_General_instructions;
