import React,{useState,useEffect} from 'react';
import { useNavigate} from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import Instructions_image from  '../../../Images/IITJAM_INSTRUCTIONS_IMAGE.png';
import NewCandidateImage_iitJam_exam from '../../../Images/NewCandidateImageIitJamExam.jpg';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

const Preliminary_Exam_General_Instructions_Page = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const {selectedSubjectId, selectedMockTestId} = useParams();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };
    

      const handleBegin = () => {
        console.log("doremonnnnnnnnn",selectedSubjectId,selectedMockTestId);
    
            if (isChecked) {
                const url = `/ESE_Mocktest1/${selectedSubjectId}/${selectedMockTestId}`;
                navigate(url, { state: { selectedSubjectId, selectedMockTestId } });
            }
        };




    const { mockId } = useParams();
  return (
    <div className='IITJAM_instruction_page_Container'>

        <div className='Instructions_page_left_container'>
        <div className='IITJAM_instruction_page_heading'> 
            <h1 className='IIT_jam_heading'>Other Important Instructions</h1>
        </div>

        <div className='All_instructions_container'>
            <div className='instructions_headingTwo_container'>
            <h2 className='instructions_headingTwo'>Paper-specific Instructions
            Preliminary Examination </h2>
            </div>
          
          <div className='IITJAM_Instructions_order_list_container'>
            <ul className='IITJAM_Instructions_order_list'>
                <li className='IITJAM_Instructions_list_tag'>This test paper has a total of 250 questions carrying 500 marks. The entire question paper is divided into two <span>papers, Paper I, Paper II</span>. All sections are compulsory. Both papers consist of objective-type (multiple-choice) questions. </li>
                <li className='IITJAM_Instructions_list_tag'><span>Paper I</span> contains <span>Multiple Choice Questions (MCQ).</span> Each MCQ type question has four choices out of which only one choice is the correct answer. This section has 100 Questions and carry a total of 200 marks. Each question carry 2 marks for correct and 1/3rd mark for each wrong answer. <span>Paper I: </span>Tests general knowledge, engineering aptitude, and reasoning skills. </li>
                <li className='IITJAM_Instructions_list_tag'><span>Paper II</span> contains <span>Multiple Choice Questions (MCQ).</span> Each MCQ type question has four choices out of which only one choice is the correct answer. This section has 150 Questions and carry a total of 300 marks. Each question carry 2 marks for correct and 1/3rd mark for each wrong answer. <span>Paper II: </span>Tests technical knowledge in the specific engineering discipline.</li>

                {/* <li className='IITJAM_Instructions_list_tag'> <span>Section – C </span> contains <span>Numerical Answer Type (NAT) </span>questions. For these questions, the answer is a real number which needs to be entered using the virtual numerical keypad on the monitor. No choices will be shown for these type of questions. This section has 20 Questions and carry a total of 30 marks. Q.1 – Q.10 carry 1 mark each and Questions Q.11 – Q.20 carry 2 marks each.</li> */}
                {/* <li className='IITJAM_Instructions_list_tag'>5. In all sections, questions not answered and saved will result in zero mark. <span>In Section – A</span>(MCQ), wrong answer will result in <span> NEGATIVE </span> marks. For all 1 mark questions, 1/3 marks will be deducted for each wrong answer. For all 2 marks questions, 2/3 marks will be deducted for each wrong answer. In <span>Section – B </span>(MSQ), there is <span>NO NEGATIVE</span> and <span>NO PARTIAL </span>marking provision. There is<span> NO NEGATIVE</span> marking in <span>Section – C </span>(NAT) as well.</li> */}
                <li className='IITJAM_Instructions_list_tag'>. A Virtual Scientific Calculator is provided on your computer screen. Any other calculators, charts, graph sheets, tables, smart watches, watches, bags, pouches, Bluetooth devices, mobile phones or electronic gadgets are NOT allowed inside the examination hall</li>

                {/* <li className='IITJAM_Instructions_list_tag'>You can use the scribble pad provided to you at the examination center for all your rough work. Write your name and registration number on the scribble pad before using it. The scribble pad MUST be returned to the invigilators after the end of the examination.</li> */}
                <li className='IITJAM_Instructions_list_tag'> Some useful data/notation required for your paper can be viewed by clicking on the Useful Data link that appears on top right hand side of the screen. The same information is also given below for your convenience.</li>
            </ul>
            <div className='Instructions_page_image_container'>
            <table className='mock_test_first_table'>
  <thead>
    <tr>
      <th>Exam Details</th>
      <th>Preliminary Examination </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>The ESE is conducted in three stages: </td>
      <td><li>Preliminary Examination </li>
      <li>Main Examination </li>
      <li>Personality Test </li></td>
    </tr>
    <tr>
      <td>Duration of Exam</td>
      <td>5 hours (300 minutes)</td>
    </tr>
    <tr>
      <td>Number of Subjects and Their Names </td>
      <td><li>Paper I: General Studies and Engineering Aptitude </li>
      <li>Paper II: Engineering Discipline-specific</li>
      </td>
    </tr>
    <tr>
      <td>Sections in Exam</td>
      <td><li>Paper I: General Studies and Engineering Aptitude </li>
      <li>Paper II: Engineering Discipline-specific</li>
      </td>
    </tr>
    <tr>
      <td>Type of Questions</td>
      <td>Both papers consist of objective-type (multiple-choice) questions. </td>
    </tr>
    <tr>
      <td>Number of Questions </td>
      <td><li>Paper I: 100 questions </li>
      <li>Paper II: 150 questions </li></td>
    </tr>
    <tr>
      <td>Marking Scheme</td>
      <td><li>2 marks for each correct answer in both papers. </li>
      <li>1/3rd mark deducted for each wrong answer in both papers.  </li></td>
    </tr>
    <tr>
      <td>Marking Scheme </td>
      <td><li>Paper I: 200 marks 
        </li>
        <li>Paper II: 300 marks </li>
        <li>Total Marks for Preliminary Exam: 500 marks </li></td>
    </tr>
    
  </tbody>
</table>
                
                </div>
          </div>
            </div>

            <div className="Instructions_checkbox_container">
            <div>
            <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />

            </div>
            <div>
            <span style={{ fontWeight: 500, color: "black" }}>
          I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, Bluetooth devices, etc. / any prohibited
            material with me into the Examination Hall. I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations.
          </span>
            </div>
         
        </div>

        <div className="Instructions_page_buttons_container">
          <button className="Instruction_page_pervious_btn">
            <Link to={`/IIT_JAM_Biotechnology_Instructions/${selectedSubjectId}/${selectedMockTestId}`}>
              <IoIosArrowBack /> Previous
            </Link>
          </button>
          <div className="intro_next_btn_container">
          {isChecked ? (
            <button className="disabled-link gn_next_btn_bull" onClick={handleBegin}>
              I am ready to begin <AiOutlineArrowRight /></button>
          ) : (
            <div>
              <span className="disabled-link gn_next_btn_bull ">
                I am ready to begin <AiOutlineArrowRight />
              </span>
            </div>
          )}
        </div>
        </div>




        </div>
        <div className='Instructions_page_right_container'>
            <img src = {NewCandidateImage_iitJam_exam} />
        </div>
    </div>
  )
}

export default Preliminary_Exam_General_Instructions_Page

