import React, { useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { FaSearch } from "react-icons/fa";
import Examheader from '../../../UG/Examheader';
import Footer from '../Footer/Footer';

const JeeAdvancedDownloadPage = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const pdfFiles = [
        { name: 'QUESTION PAPER', path: '/Downloads/Question Papers/JEE-Advanced-2021-paper-1-physics-question-paper.pdf' },
        { name: 'SOLUTION PAPER', path: '/Downloads/Question Papers/Physics_Paper1-provisional-answer-key.pdf' }
    ];

    const sessions = [
        'PHYSICS(Paper - 1)',
        'PHYSICS(Paper - 2)',
        'CHEMISTRY(Paper - 1)',
        'CHEMISTRY(Paper - 2)',
        'MATHS(Paper - 1)',
        'MATHS(Paper - 2)',
    ];

    const filteredSessions = sessions.filter(session =>
        session.toLowerCase().includes(searchQuery)
    );

    return (
        <div>
            <Examheader />
            <div className="IItMainDownloads">
                <div className="IitMainHeading">
                    <h2>JEE ADVANCED - PREVIOUS QUESTION PAPERS WITH SOLUTIONS</h2>
                </div>
                <div className="Iitmainstablediv">
                    <div className='Download_Searchbar'>
                        <FaSearch />
                        <input
                            type="text"
                            placeholder="Search by subject ('Physics')"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>

                    <table className="Iitmainstable">
                        <thead>
                            <tr className="Iitmainstrheading">
                                <th colSpan={4}>JEE ADVANCED - 2021 Question Paper With Solution PDFS </th>
                            </tr>
                            <tr>
                                <th>S.No</th>
                                <th>JEE Advanced 2021</th>
                                <th>QUESTION PAPER</th>
                                <th>SOLUTION PAPER</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredSessions.map((session, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td dangerouslySetInnerHTML={{ __html: session }}></td>
                                    {index === 0 && (
                                        <>
                                            <td>
                                                <a href={pdfFiles[0].path} download target="_blank" rel="noreferrer">
                                                    <button><MdDownload /> {pdfFiles[0].name}</button>
                                                </a>
                                            </td>
                                            <td>
                                                <a href={pdfFiles[1].path} download target="_blank" rel="noreferrer">
                                                    <button><MdDownload /> {pdfFiles[1].name}</button>
                                                </a>
                                            </td>
                                        </>
                                    )}
                                    {index !== 0 && (
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default JeeAdvancedDownloadPage;
