import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HomeDATA, myBanners } from "./HomeData";
import "./home.css";
import Marquee from "react-fast-marquee";

const Home = () => {
  const [randomBanner, setRandomBanner] = useState(null);

  useEffect(() => {
    const lastDisplayedBannerIndex = parseInt(
      localStorage.getItem("lastDisplayedBannerIndex")
    );

    const nextBannerIndex =
      isNaN(lastDisplayedBannerIndex) || lastDisplayedBannerIndex === myBanners.length - 1
        ? 0
        : lastDisplayedBannerIndex + 1;

    localStorage.setItem("lastDisplayedBannerIndex", nextBannerIndex.toString());
    setRandomBanner(myBanners[nextBannerIndex]);

    return () => {
      localStorage.removeItem("lastDisplayedBannerIndex");
    };
  }, []); // Only run the effect once when the component mounts

  return (
    <>
      <div className="home-container">
        {HomeDATA.map((homeData, index) => {
          return (
            <div className={homeData.home} key={index}>
              <Marquee pauseOnHover={true} className="marquee">
                <p>{homeData.scrollbar}</p>
              </Marquee>
              <div className={myBanners.banner_container}>
                <Carousel
                  autoPlay
                  infiniteLoop
                  showArrows={false}
                  interval={3000}
                  showThumbs={false}
                  showStatus={false}
                >
                  {/* 1 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.bg} alt="" />}
                  </div>
                  {/* 2 - using randomBanner */}
                  <div className={myBanners.banner}>
                    {randomBanner && <img src={randomBanner.url} alt={randomBanner.img} />}
                  </div>
                  {/* 3 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.b9} alt="" />}
                  </div>
                  {/* 4 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.b10} alt="" />}
                  </div>
                  {/* 5 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.b11} alt="" />}
                  </div>
                  {/* 6 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.b7} alt="" />}
                  </div>
                  {/* 7 */}
                  <div className={myBanners.banner}>
                    {homeData && <img src={homeData.b8} alt="" />}
                  </div>
                </Carousel>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Home;
