import React, { useState } from "react";


import "../../UG/header.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "../../UG/Header";


import { Pg_exploreExam } from "./Pg_Explor_Exam/Pg_exploreExam";
import { Pg_Course } from "./Pg_ouercourses/Pg_Course";
import Pg_header from "./Pg_Header/Pg_header";

import { TbArrowBarToUp } from "react-icons/tb";

import Footer2 from "../../UG/Footer2";

export const Pg_home_page = () => {
  const [isVisible, setVisible] = useState(false);

  const handleScrole = () => {
    const scrollY = window.scrollY;
    const showThreshold = 200;

    setVisible(scrollY > showThreshold);
  };


  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScrole);

    return () => {
      window.removeEventListener("scroll", handleScrole);
    };
  }, []);
  return (
    <>
      <div id="PgHome"></div>
      <Header />

      <Pg_header />
      <Pg_exploreExam />
      <br />

      <Pg_Course />

      {/* <Pg_About /> */}
      {/* <Aboutt /> */}

      {/* <Pg_Countact /> */}

      {/* <Pg_Fax /> */}
      <Footer2 />


      {isVisible && (
        <span className="PgUpArrow" onClick={ScrollToTop}>
          <a href="#topMove">
            <TbArrowBarToUp style={{ fontSize: "30px" }} />
          </a>
        </span>
      )}
    </>
  );
};
