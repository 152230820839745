import { React, useState, useEffect } from "react";
import "../SubmitFormAndMail.css";
import logo from "../../egate logo 1.png";
import { useNavigate, useParams } from "react-router-dom";
import { Nav } from "../../components/UG/Nav";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import Footer from "../../components/ug_homepage/components/Footer/Footer";
import Mock_Test_miniBanner_Two from "../../components/UG/Images/Mock_Test_miniBanner_Two.jpeg";
import mobile_view_image_one from "../../components/UG/Images/Neet_Chemistry_image_Two.jpeg";
import mobile_view_image_Two from "../../components/UG/Images/Neet_main_image_one.jpeg";
import banners_image_one from "../../components/UG/Images/banners_image_one.png";
 
const examMockTests = {
  GATE_Exam: [
    {
      id: 10, // ID for the subject "ME"
      Subject: "Mechanical Engineering (ME)",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" },
      ],
    },
    {
      id: 11, // ID for the subject "AE"
      Subject: "Civil Engineering (CE)",
      mockTests: [
        { id: 201, name: "Mock Test-1" },
        { id: 202, name: "Mock Test-2" },
        { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
    {
      id: 12, // ID for the subject "AE"
      Subject: "Engineering Sciences (XE)",
      mockTests: [
        { id: 201, name: "Mock Test-1" },
        { id: 202, name: "Mock Test-2" },
        { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
    {
      id: 13, // ID for the subject "AE"
      Subject: "Production and Industrial Engineering (PI)",
      mockTests: [
        { id: 201, name: "Mock Test-1" },
        { id: 202, name: "Mock Test-2" },
        { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
    {
      id: 14, // ID for the subject "AE"
      Subject: "Metallurgical Engineering (MT)",
      mockTests: [
        { id: 201, name: "Mock Test-1" },
        { id: 202, name: "Mock Test-2" },
        { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
    // {
    //   id: 15, // ID for the subject "AE"
    //   Subject: "Physics (PH)",
    //   mockTests: [
    //     { id: 201, name: "Mock Test-1" },
    //     { id: 202, name: "Mock Test-2" },
    //     { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
    //   ],
    // },
    // {
    //   id: 16, // ID for the subject "AE"
    //   Subject: "Engineering Sciences (XE)",
    //   mockTests: [
    //     { id: 201, name: "Mock Test-1" },
    //     { id: 202, name: "Mock Test-2" },
    //     { id: 203, name: "Mock Test-3" }, // Corrected duplicate ID
    //   ],
    // },
  ],
  IITJAM_Exam: [
    {
      id: 20, // ID for the subject "ME"
      Subject: "Mathematics (MA)",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" },
      ],
    },
    {
      id: 21, // ID for the subject "AE"
      Subject: "Physics (PH)",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
 
 
    // {
    //   id: 22, // ID for the subject "ME"
    //   Subject: "Economics (EN) ",
    //   mockTests: [
    //     { id: 101, name: "Mock Test-1" },
    //     { id: 102, name: "Mock Test-2" },
    //     { id: 103, name: "Mock Test-3" },
    //   ],
    // },
    // {
    //   id: 23, // ID for the subject "ME"
    //   Subject: "Geology (GG) ",
    //   mockTests: [
    //     { id: 101, name: "Mock Test-1" },
    //     { id: 102, name: "Mock Test-2" },
    //     { id: 103, name: "Mock Test-3" },
    //   ],
    // },
    // {
    //   id: 24, // ID for the subject "ME"
    //   Subject: "Mathematics (MA) ",
    //   mockTests: [
    //     { id: 101, name: "Mock Test-1" },
    //     { id: 102, name: "Mock Test-2" },
    //     { id: 103, name: "Mock Test-3" },
    //   ],
    // },
    // {
    //   id: 25, // ID for the subject "ME"
    //   Subject: "Mathematical Statistics (MS) ",
    //   mockTests: [
    //     { id: 101, name: "Mock Test-1" },
    //     { id: 102, name: "Mock Test-2" },
    //     { id: 103, name: "Mock Test-3" },
    //   ],
    // },
    // {
    //   id: 26, // ID for the subject "ME"
    //   Subject: "Physics (PH) ",
    //   mockTests: [
    //     { id: 101, name: "Mock Test-1" },
    //     { id: 102, name: "Mock Test-2" },
    //     { id: 103, name: "Mock Test-3" },
    //   ],
    // },
  ],
 
 
 
 
 
  ISRO: [
    {
      id: 27, // ID for the subject "ME"
      Subject: "Electronics Engineering (ECE) ",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" },
      ],
    },
    {
      id: 28, // ID for the subject "AE"
      Subject: "Mechanical Engineering (ME) ",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" }, // Corrected duplicate ID
      ],
    },
    {
      id: 29, // ID for the subject "ME"
      Subject: "Computer Science Engineering (CSE)",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" },
      ],
    }
 
  ],
 
 
 
 
  ESE: [
    {
      id: 30, // ID for the subject "ME"
      Subject: "Preliminary Examination",
      mockTests: [
        { id: 101, name: "Mock Test-1" },
        { id: 102, name: "Mock Test-2" },
        { id: 103, name: "Mock Test-3" },
      ],
    }
   
   
 
  ],
 
 
 
 
 
 
 
};
 
const StudentFormandMailGate = () => {
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [mockTests, setMockTests] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [TwoCurrentIndex, setTwoCurrentIndex] = useState(0);
  const banners = [
    { src: banners_image_one, alt: "Banner 1" },
    { src: Mock_Test_miniBanner_Two, alt: "Banner 2" },
  ];
  const banners_Two = [
    { src: mobile_view_image_one, alt: "Banner 3" },
    { src: mobile_view_image_Two, alt: "Banner 4" },
  ];
 
  useEffect(() => {
    const interval = setInterval(() => {
      setTwoCurrentIndex((prevIndex) => (prevIndex + 1) % banners_Two.length);
    }, 3000); // Change every 3 seconds
 
    return () => clearInterval(interval);
  }, []);
 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 10000); // Change banner every 10 seconds
 
    return () => clearInterval(interval);
  }, []);
 
  const handleExamChange = (e) => {
    const exam = e.target.value;
    setSelectedExam(exam);
    setSelectedSubject("");
    setMockTests([]);
    setFormData({
      ...formData,
      selectedExam: exam,
      selectedSubject: "",
      selectedMockTest: "",
    });
  };
 
  const handleSubjectChange = (e) => {
    const subjectId = e.target.value;
    setSelectedSubject(subjectId);
    const selectedExamData = examMockTests[selectedExam];
    const subject = selectedExamData.find(
      (subject) => subject.id === parseInt(subjectId, 10)
    );
    setMockTests(subject ? subject.mockTests : []);
    setFormData({
      ...formData,
      selectedSubject: subjectId,
      selectedMockTest: "",
    });
  };
 
  const navigate = useNavigate();
  const { mockId } = useParams();
  const [formData, setFormData] = useState({
    studentName: "",
    contactNumber: "",
    email: "",
    selectedExam: "",
    selectedSubject: "",
    selectedMockTest: "",
  });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
 
    if (name === "studentName") {
      // Allow only letters, spaces, and periods
      const filteredValue = value.replace(/[^a-zA-Z\s.]/g, "");
      setFormData({ ...formData, [name]: filteredValue });
    } else if (name === "contactNumber") {
      // Allow only digits and limit the length to 10 digits
      const filteredValue = value.replace(/[^0-9]/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: filteredValue });
    } else if (name === "email") {
      // Basic email validation pattern
      // Note: This is a simple pattern, for more complex validation, consider using a library or more robust regex
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(value) || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
 
    // Create a FormData object from the form data
    const data = new FormData(e.target);
 
    // Log form data to the console
    data.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
 
    // Extract form values
    const selectedExam = formData.selectedExam;
    const selectedSubjectId = parseInt(formData.selectedSubject, 10);
    const selectedMockTestId = parseInt(formData.selectedMockTest, 10);
 
    console.log("Selected Exam:", selectedExam);
    console.log("Selected Subject ID:", selectedSubjectId);
 
    const examData = examMockTests[selectedExam];
    if (!examData) {
      console.error("Invalid exam selected.");
      alert("Invalid exam selected.");
      return;
    }
    const subject = examData.find(
      (subject) => subject.id === selectedSubjectId
    );
    if (!subject) {
      console.error("Invalid subject selected.");
      alert("Invalid subject selected.");
      return;
 
      // }
 
      // const mockTest = subject.mockTests.find(mockTest => mockTest.id === selectedMockTestId);
      // if (!mockTest) {
      //   alert("Invalid mock test selected.");
      //   return;
    }
 
    fetch("https://formspree.io/f/xwperaen", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // Reset form
          setFormData({
            studentName: "",
            contactNumber: "",
            email: "",
            selectedExam: "",
            selectedSubject: "",
            selectedMockTest: "",
          });
 
          // Determine redirect URL based on conditions
          let redirectUrl = null;
          if (selectedExam === "GATE_Exam") {
            if (
              selectedSubjectId === 10 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/Gate_ME_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 11 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_CE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 12 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_XE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 13 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_PI_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 14 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_MT_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 15 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_Physics_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 16 &&
              (selectedMockTestId === 201 ||
                selectedMockTestId === 202 ||
                selectedMockTestId === 203)
            ) {
              redirectUrl = `/Gate_XE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            }
          } else if (selectedExam === "IITJAM_Exam") {
            if (
              selectedSubjectId === 20 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_MATHS_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 21 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_PHYSICS_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 22 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_Economics_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 23 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_Geology_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 24 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_MATHS_STATISTICS_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 25 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_MATHS_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 26 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/IIT_JAM_PHYSICS_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            }
          }
 
          else if (selectedExam === "ISRO") {
            if (
              selectedSubjectId === 27 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/Isro_ECE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            } else if (
              selectedSubjectId === 28 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/Isro_ECE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            }
            else if (
              selectedSubjectId === 29 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/Isro_ECE_Instructions/${selectedSubjectId}/${selectedMockTestId}`;
            }
          }
 
 
 
          else if (selectedExam === "ESE") {
            if (
              selectedSubjectId === 30 &&
              (selectedMockTestId === 101 ||
                selectedMockTestId === 102 ||
                selectedMockTestId === 103)
            ) {
              redirectUrl = `/Preliminary_Exam_instructions_Page/${selectedSubjectId}/${selectedMockTestId}`;
            }
          }
 
 
 
          else {
            // Default redirect if no valid condition is met
            redirectUrl = "/iitjee_otc";
          }
 
          // Open the URL in a new window with specified dimensions or fullscreen
          if (redirectUrl) {
            const screenWidth = window.screen.width;
            const screenHeight = window.screen.height;
 
            window.open(
              redirectUrl,
              "_blank",
              `width=${screenWidth},height=${screenHeight},fullscreen=yes`
            );
          }
        } else {
          alert("There was an error submitting the form.");
        }
      })
      .catch((error) => {
        alert("There was an error submitting the form.");
        console.error("Form submission error:", error);
      });
  };
  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
    // HTML5 validation will handle this in the form submission
  };
  return (
    <div>
      <div>
        <div className="ugexam_header">
          {Nav.map((NavData, index) => {
            return (
              <div className="header ug_exam_header" key={index}>
                <div className={NavData.logo_img_container}>
                  <Link to={"/"}>
                    <img src={NavData.logo} alt="" />
                  </Link>
                </div>
 
                <div className="exam_login_menu">
                  <li>
                    <Link
                      to="/home"
                      className={NavData.navlist}
                      id="exam_login_menu_home"
                    >
                      <IoMdHome /> {NavData.link1}
                    </Link>
                  </li>
                </div>
              </div>
            );
          })}
        </div>
      </div>
 
     
 
        <div className="Mock_test_form_and_banners_container">
          <div className="Banner_container">
            {banners.map((banner, index) => (
              <img
                key={index}
                className={`Banner_image ${
                  index === currentIndex ? "fade-in" : "fade-out"
                }`}
                src={banner.src}
                alt={banner.alt}
              />
            ))}
            <div className="Mobile_view_banner_container">
              <div className="slider">
                <img
                  className="new_img"
                  src={mobile_view_image_one}
                  alt="Image One"
                />
                <img
                  className="new_img"
                  src={mobile_view_image_Two}
                  alt="Image Two"
                />
                <img
                  className="new_img"
                  src={mobile_view_image_one}
                  alt="Image One"
                />
                <img
                  className="new_img"
                  src={mobile_view_image_Two}
                  alt="Image Two"
                />
              </div>
            </div>
          </div>
          <div className="SubmitFormAndMail_container">
            <div className="SubmitFormAndMail_header1">
              <div>
                <h2>MOCK TEST</h2>
              </div>
 
              {/* <div>
                {Nav.map((NavData, index) => (
                  <div key={index} className="Mock_test_header_item">
                    <img src={NavData.logo} alt="" />
                  </div>
                ))}
              </div> */}
            </div>
 
            <form onSubmit={handleSubmit} className="studentformmocktest">
              <div className="form-group">
                <label className="SubmitFormAndMail_label">Select Exam:</label>
                <select
                  className="SubmitFormAndMail_input"
                  name="selectedExam"
                  value={selectedExam}
                  onChange={handleExamChange}
                  required
                >
                  <option value="">Select an Exam</option>
                  {Object.keys(examMockTests).map((exam) => (
                    <option key={exam} value={exam}>
                      {exam}
                    </option>
                  ))}
                </select>
              </div>
 
              <div className="form-group">
                <label className="SubmitFormAndMail_label">
                  Select Department:
                </label>
                <select
                  className="SubmitFormAndMail_input"
                  name="selectedSubject"
                  value={selectedSubject}
                  onChange={handleSubjectChange}
                  required
                  disabled={!selectedExam}
                >
                  <option value="">Select Department</option>
                  {selectedExam &&
                    examMockTests[selectedExam].map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.Subject}
                      </option>
                    ))}
                </select>
              </div>
 
              <div className="form-group">
                <label className="SubmitFormAndMail_label">
                  Select Mock Test:
                </label>
                <select
                  className="SubmitFormAndMail_input"
                  name="selectedMockTest"
                  value={formData.selectedMockTest}
                  onChange={handleChange}
                  required
                  disabled={!selectedSubject}
                >
                  <option value="">Select a Mock Test</option>
                  {mockTests.map((mockTest) => (
                    <option key={mockTest.id} value={mockTest.id}>
                      {mockTest.name}
                    </option>
                  ))}
                </select>
              </div>
 
              <div className="form-group">
                <label className="SubmitFormAndMail_label">Student Name:</label>
                <input
                  className="SubmitFormAndMail_input"
                  type="text"
                  name="studentName"
                  placeholder="Enter Name"
                  value={formData.studentName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label className="SubmitFormAndMail_label">
                  Contact Number:
                </label>
                <input
                  className="SubmitFormAndMail_input"
                  type="number"
                  placeholder="Enter Mobile No"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
              <label className="SubmitFormAndMail_label">Email:</label>
              <input
                className="SubmitFormAndMail_input"
                type="email"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleEmailChange}
                required
              />
            </div>
 
              <div className="form-group1">
                <button type="submit" className="SubmitFormAndMail_button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
       
     
     
      <Footer />
    </div>
  );
};
 
export default StudentFormandMailGate;