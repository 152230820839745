import React from "react";
import { useParams, Link } from "react-router-dom";
import '../../../Style/IItjamInstructionsPage.css';
import "../../../Style/PgInstructionsPage.css";
import grayBox from "../../../asserts/grayBox.png";
import greenBox from "../../../asserts/greenBox.png";
import orangeBox from "../../../asserts/orangeBox.png";
import purpleBox from "../../../asserts/purpleBox.png";
import purpleTickBox from "../../../asserts/purpleTickBox.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import NewCandidateImage_iitJam_exam from '../../../Images/NewCandidateImageIitJamExam.jpg';

const Gate_MATHS_Instructions = () => {
  // Extract URL parameters
  const { selectedSubjectId, selectedMockTestId } = useParams();

  return (
    <div className="IITJAM_instruction_page_Container">
      <div className="Instructions_page_left_container">
        <div>
          <div className="pg_Instructionsdiv1">
            <div className="pg_Instructions"> Instructions</div>
            <div className="pg_readinstructionsdiv">
              <div className="pg_readinstructions">
                Please read the instructions carefully
              </div>

              <ul className="PG_General_Instructions_Ul_tag">
                <p className="pg_siteheding">General Instructions:</p>
                <li value="100">
                  1. Total duration of examination is <span>180</span> minutes.
                </li>
                <li>
                  2. The clock will be set at the server. The countdown timer in
                  the top right corner of screen will display the remaining time
                  available for you to complete the examination. When the timer
                  reaches zero, the examination will end by itself. You will not
                  be required to end or submit your examination.
                </li>
                <li>
                  3. The Question Palette displayed on the right side of screen
                  will show the status of each question using one of the following
                  symbols:
                </li>
                <ul>
                  <li>
                    <img src={grayBox} /> You have not visited the question yet.
                  </li>
                  <li>
                    <img src={orangeBox} /> You have not answered the question.
                  </li>
                  <li>
                    <img src={greenBox} />
                    You have answered the question.
                  </li>
                  <li>
                    <img src={purpleBox} /> You have NOT answered the question,
                    but have marked the question for review.
                  </li>
                  <li>
                    <img src={purpleTickBox} /> The question(s) "Answered and
                    Marked for Review" will be considered for evaluation.
                  </li>
                  <li>
                    The Marked for Review status for a question simply indicates
                    that you would like to look at that question again.
                  </li>
                </ul>
                <li>
                  4. You can click on the arrow which appears to the left of
                  question palette to collapse the question palette thereby
                  maximizing the question window. To view the question palette
                  again, you can click on which appears on the right side of
                  question window.
                </li>
                <li>
                  5. You can click on your "Profile" image on top right corner of
                  your screen to change the language during the exam for entire
                  question paper. On clicking of Profile image you will get a
                  drop-down to change the question content to the desired
                  language.
                </li>
                <li>
                  6. You can click on <i className="fa-solid fa-circle-down"></i> to
                  navigate to the bottom and <i className="fa-solid fa-circle-up"></i>{" "}
                  navigate to the top of the question area, without scrolling.
                </li>
                <p className="pg_siteheding"><span>Navigating to a Question:</span></p>
                <li>
                  7. To answer a question, do the following:
                  <ul>
                    <li>
                      a. Click on the question number in the Question Palette at
                      the right of your screen to go to that numbered question
                      directly. Note that using this option does NOT save your
                      answer to the current question.
                    </li>
                    <li>
                      b. Click on <span>Save & Next</span> to save your answer for the current
                      question and then go to the next question.
                    </li>
                    <li>
                      c. Click on <span>Mark for Review & Next</span> to save your answer for
                      the current question, mark it for review, and then go to the
                      next question.
                    </li>
                  </ul>
                </li>
                <p className="pg_siteheding"><span>Answering a Question :</span></p>
                <li>
                  8. Procedure for answering a multiple choice type question
                  <ul>
                    <li>
                      To select your answer, click on the button of one of the
                      options
                    </li>
                    <li>
                      To deselect your chosen answer, click on the button of the
                      chosen option again or click on the <span>Clear Response </span>button
                    </li>
                    <li>
                      To change your chosen answer, click on the button of another
                      option
                    </li>
                    <li>
                      To save your answer, you MUST click on the <span>Save & Next</span>
                      button
                    </li>
                    <li>
                      To mark the question for review, click on the <span>Mark for
                      Review & Next button.</span>
                    </li>
                  </ul>
                </li>
                <li>
                  9. To change your answer to a question that has already been
                  answered, first select that question for answering and then
                  follow the procedure for answering that type of question.
                </li>
                <p className="pg_siteheding">Navigating through sections:</p>
                <li>
                  10. Sections in this question paper are displayed on the top bar
                  of the screen. Questions in a section can be viewed by clicking
                  on the section name. The section you are currently viewing is
                  highlighted.
                </li>
                <li>
                  11. After clicking the Save & Next button on the last question
                  for a section, you will automatically be taken to the first
                  question of the next section.
                </li>
                <li>
                  12. You can shuffle between sections and questions anytime during
                  the examination as per your convenience only during the time
                  stipulated.
                </li>
                <li>
                  13. Candidate can view the corresponding section summary as part
                  of the legend that appears in every section above the question
                  palette.
                </li>
                <li>
                  14. To zoom the image provided in the question roll over it.
                </li>
              </ul>
            </div>
          </div>
          <div className="intro_next_btn_container">
            <button className="PG_GENERAL_INSTRUCTIONS_intro_next_btn">
              <Link to={`/Gate_MATHS_General_Instructions/${selectedSubjectId}/${selectedMockTestId}`}>
                NEXT <AiOutlineArrowRight />
              </Link>
            </button>
          </div>
        </div>
        {/* <div className="pg_StudentDetails">
          {" "}
          {studentDetails.map((student, index) => (
            <div key={index}>
              <img
                className="users_profile_img"
                src={`${BASE_URL}/uploads/studentinfoimeages/${student.UplodadPhto}`}
                alt={`no img${student.UplodadPhto}`}
              />
              <p>{student.candidateName}</p>
            </div>
          ))}
        </div> */}
      </div>

      <div className='Instructions_page_right_container'>
        <img src={NewCandidateImage_iitJam_exam} />
      </div>
    </div>
  );
};

export default Gate_MATHS_Instructions;
