import React from "react";
import { Exam_Explore_data, Examsliders } from "./Exam_Explore_data";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import welcom from './productivity_9__1_-removebg-preview.png'
import "./exam.css";

const ExamExplore = () => {

  return (
    <div id="exam" >
      <h2>EXPLORE EXAMS</h2>
      {/* <button onClick={onClick}>Go to exam section </button> */}
      <div className="exam_sub_container">
        <div className="exam_box_right">
          {Exam_Explore_data.map((Exam_Explore_data, index, Exam) => {
            return (
              <div key={index}>
                <div className={Exam_Explore_data.exam_boxs}>
                  <Link onClick={() => window.scrollTo(0, 0)}
                    to={Exam_Explore_data.examPage}
                    className={Exam_Explore_data.exams_buttons}
                  >
                    {Exam_Explore_data.exams_button}
                  </Link>
                  <Link onClick={() => window.scrollTo(0, 0)} 
                    to={Exam_Explore_data.otslink}
                    className={Exam_Explore_data.exams_buttons_normal}
                  >
                    {Exam_Explore_data.ots}
                  </Link>
                  <Link onClick={() => window.scrollTo(0, 0)}
                    to={Exam_Explore_data.olvclink}
                    className={Exam_Explore_data.exams_buttons_normal}
                  >
                    {Exam_Explore_data.olvc}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

        <div className="exam_box_left">
          {Examsliders.map((Examsliders, index) => {
            return (
              <div key={index}>
                {/* <Carousel
                  showThumbs={false}
                  showIndicators={false}
                  showStatus={false}
                  showArrows={false}
                >
                  <div>
                    <img src={Examsliders.slide1} alt="" />
                  </div>
                  <div>
                    <img src={Examsliders.slide2} alt="" />
                  </div>
                  <div>
                    <img src={Examsliders.slide3} alt="" />
                  </div>
                </Carousel> */}
                  <img src={welcom} alt="" />
              </div>
            );
          })}
            
        </div>
      </div>
    </div>
  );
};

export default ExamExplore;



// import React, { useState } from "react";

// import { Exam_Explore_data, Examsliders } from "./Exam_Explore_data";

// import { Link } from "react-router-dom";

// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import image from '../../../UG/CARDS/images/CHEMISTRY.png'
// import welcom from './productivity_9__1_-removebg-preview.png'
// import "./exam.css";
// import { IoMdClose } from "react-icons/io";

// const Exam_Explore = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [popupContent, setPopupContent] = useState("");

//   const togglePopup = (content) => {
//     setPopupContent(content);
//     setShowPopup(!showPopup);
//   };
//   return (
//     <div id="exam">
//       <h2>EXPLORE EXAMS</h2>
//       <div className="exam_sub_container">
     

//         <div className="exam_box_right">
//         {Exam_Explore_data.map((exam, index) => (
//   <div key={index}>
//     <div className={exam.exam_boxs}>
//       <a
//         onClick={() => window.scrollTo(0, 0)}
//         href={exam.examPage}
//         className={exam.exams_buttons}
//       >
//         {exam.exams_button}
//       </a>
//       {exam.exams_button === "BITSAT" ? (
//         <>
//           <a
//             href={exam.otslink}
//             className={exam.exams_buttons_normal}
//           >
//             {exam.ots}
//           </a>
//           <a
//             href={exam.olvclink}
//             className={exam.exams_buttons_normal}
//           >
//             {exam.olvc}
//           </a>
//         </>
//       ) : (
//         <>
//           <a
//             onClick={() => togglePopup(exam.ots)}
//             className={exam.exams_buttons_normal}
//           >
//             {exam.ots}
//           </a>
//           <a
//             onClick={() => togglePopup(exam.olvc)}
//             className={exam.exams_buttons_normal}
//           >
//             {exam.olvc}
//           </a>
//         </>
//       )}
//     </div>
//   </div>
// ))}
// {showPopup && (
//   <div className="popupExamCArd">
//     <div className="popup_innerExam">
//       <p>Admissions closed for 2024. Registrations to courses for 2025 will be open from May 15th, 2024</p>
//       <button onClick={() => setShowPopup(false)}>Close</button>
//     </div>
//   </div>
// )}


//         </div>

//         <div className="exam_box_left">
//           {Examsliders.map((Examsliders, index) => {
//             return (
//               <div key={index}>
//                 {/* <Carousel
//                   showThumbs={false}
//                   showIndicators={false}
//                   showStatus={false}
//                   showArrows={false}
//                 >
//                   <div>
//                     <img src={Examsliders.slide1} alt="" />
//                   </div>
//                   <div>
//                     <img src={Examsliders.slide2} alt="" />
//                   </div>
//                   <div>
//                     <img src={Examsliders.slide3} alt="" />
//                   </div>
//                 </Carousel> */}
//                   <img src={welcom} alt="" />
//               </div>
//             );
//           })}
            
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Exam_Explore;