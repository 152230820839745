import React,{useState,useEffect} from 'react';

import { IoIosArrowBack } from "react-icons/io";
import Instructions_image from "../../../Images/IITJAM_INSTRUCTIONS_IMAGE.png";
import NewCandidateImage_iitJam_exam from "../../../Images/NewCandidateImageIitJamExam.jpg";
// import GATE_Instrutions_table_Image from '../../../Images/GATE_Instrutions_table_Image.png';
import { Link,useNavigate, useParams } from "react-router-dom";
import "../../../Style/PgInstructionsPage.css";
import { AiOutlineArrowRight } from 'react-icons/ai';

const Gate_Physics_General_Instructions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const {selectedSubjectId, selectedMockTestId} = useParams();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };
      const handleBegin = () => {
        navigate(``)
      };
  const { mockId } = useParams();
  return (
    <div className="IITJAM_instruction_page_Container">
      <div className="Instructions_page_left_container">
        <div className="IITJAM_instruction_page_heading">
          <h1 className="IIT_jam_heading">Other Important Instructions</h1>
        </div>

        <div className="All_instructions_container">
          <div className="instructions_headingTwo_container">
            <h2 className="instructions_headingTwo">
              Paper-specific Instructions
            </h2>
          </div>

          <div className="IITJAM_Instructions_order_list_container">
            <ul className="IITJAM_Instructions_order_list">
              <p className="IITJAM_Instructions_list_tag">
                <span>Please Read The Following Carefully</span>
              </p>
              <li className="IITJAM_Instructions_list_tag">
                This question paper has 65 questions for a total of 100 marks.
                It consists of two sections: General Aptitude(GA) for 15 marks
                and the subjects specific section for 85 marks. Both sections
                are compulsory, The marks distribution is as follow.
              </li>

              <li className="IITJAM_Instructions_list_tag">
                <span>General Aptitude :</span>
                <p>
                  The "General Aptitude" section in the GATE exam is designed to
                  assess the candidate's ability to understand and analyze
                  various types of problems. This section typically includes
                  questions on:
                  <ul>
                    <li className="IITJAM_Instructions_list_tag">
                      <span>Verbal Ability:</span> This involves comprehension,
                      sentence completion, and grammar-based questions.
                      Candidates are tested on their ability to understand and
                      interpret written information.
                    </li>
                    <li className="IITJAM_Instructions_list_tag">
                      <span>Quantitative Ability:</span> Questions here include
                      basic mathematics such as arithmetic, algebra, and data
                      interpretation. The focus is on problem-solving skills and
                      numerical reasoning.
                    </li>
                  </ul>
                  <li className="IITJAM_Instructions_list_tag">
                  <strong>Marks:</strong> This section generally comprises 15%
                  of the total marks in the GATE exam.
                  <strong>Section Pattern:</strong> The General Aptitude section
                  typically contains 10 to 15 questions, divided equally between
                  Verbal and Quantitative Ability. The questions are usually of
                  multiple-choice (MCQ) or numerical answer type (NAT).</li>
                </p>
              </li>
              <li className="IITJAM_Instructions_list_tag">
                <span>Candidate-Opted-Subject: </span>The "Candidate-Opted"
                section refers to the part of the exam where candidates choose
                specific subjects or papers based on their academic background
                and area of interest. The GATE exam offers various papers across
                different engineering and science disciplines, and candidates
                can select one according to their specialization or intended
                field of study.
              </li>

              <div className="Table_main_container">
                <table border="1" className="gate_instrutions_table_container">
                  <thead>
                    <tr className="gate_instrutions_table_row">
                      <th colspan="2">GATE EXAM HIGHLIGHTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="gate_instrutions_table_row">
                      <th>Feature</th>
                      <th>Description</th>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Duration</span>
                      </td>
                      <td>180 minutes (3 hours)</td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Sectional Time Limit</span>
                      </td>
                      <td>None</td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Total Marks</span>
                      </td>
                      <td>100</td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Total Number of questions</span>
                      </td>
                      <td>65</td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Type of Questions</span>
                      </td>
                      <td>
                        <li>Multiple Choice Questions (MCQs)</li>
                        <li>Multiple Selective Questions (MSQs)</li>
                        <li>Numerical Answer type (NATs) Questions</li>
                      </td>
                    </tr>
                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Section-wise Number of Questions</span>
                      </td>
                      <td>
                        <li>General Aptitude- 10 questions,</li>
                        <lI>Core Discipline- 55 questions</lI>
                      </td>
                    </tr>
                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Section-wise Weightage</span>
                      </td>
                      <td>
                        <li>General Aptitude- 15 marks,</li>
                        <li>Core Discipline- 85 marks</li>
                      </td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Marking Scheme</span>
                      </td>
                      <td>1 or 2 marks for each correct answer</td>
                    </tr>

                    <tr className="gate_instrutions_table_row">
                      <td>
                        <span>GATE Negative Marking</span>
                      </td>
                      <td>
                        <li>
                          For 1 mark MCQ, 1/3 mark will be deducted for a wrong
                          answer;
                        </li>
                        <li>
                          For 2-mark MCQ, 2/3 mark will be deducted for a wrong
                          answer;
                        </li>
                        <li>No negative marking for MSQs and NATs</li>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <img src = {GATE_Instrutions_table_Image} /> */}
              </div>

              {/* <li className="IITJAM_Instructions_list_tag">
                To switch sections, click on the tab of the section.
              </li> */}
            </ul>
          </div>
        </div>

        {/* <div className="Instructions_checkbox_container">
          <span>
            <input
              type="checkbox"
              id="instructionsCheckbox"
              name="instructionsCheckbox"
              required
            />
          </span>
          <p>
            I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, Bluetooth devices, etc. / any prohibited
            material with me into the Examination Hall. I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations.
          </p>
        </div> */}


        <div className="Instructions_checkbox_container">
            <div>
            <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />

            </div>
            <div>
            <span style={{ fontWeight: 500, color: "black" }}>
          I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, Bluetooth devices, etc. / any prohibited
            material with me into the Examination Hall. I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations.
          </span>
            </div>
         
        </div>

        <div className="Instructions_page_buttons_container">
          <button className="Instruction_page_pervious_btn">
            <Link to={`/Gate_Physics_Instructions/${selectedSubjectId}/${selectedMockTestId}`}>
              <IoIosArrowBack /> Previous
            </Link>
          </button>
          <div className="intro_next_btn_container">
          {isChecked ? (
            <button className="disabled-link gn_next_btn_bull" onClick={handleBegin}>
              I am ready to begin <AiOutlineArrowRight /></button>
          ) : (
            <div>
              <span className="disabled-link gn_next_btn_bull ">
                I am ready to begin <AiOutlineArrowRight />
              </span>
            </div>
          )}
        </div>
        </div>
      </div>
      <div className="Instructions_page_right_container">
        <img src={NewCandidateImage_iitJam_exam} />
      </div>
    </div>
  );
};

export default Gate_Physics_General_Instructions;

