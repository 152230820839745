import React, { useState } from "react";
//  ----------------------components-------------------
import Header from "./components/Header/Header";
import Home from "./components/Home_Section/Home";
import ExamExplore from "./components/ExploreExam/ExamExplore";
import Course from "./components/Courses/Course";
import { TbArrowBarToUp } from "react-icons/tb";
import Footer2 from "../UG/Footer2";
const UG_HOME = () => {
  const [isVisible, setVisible] = useState(false);

  const handleScrole = () => {
    const scrollY = window.scrollY;
    const showThreshold = 200;

    setVisible(scrollY > showThreshold);
  };

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScrole);

    return () => {
      window.removeEventListener("scroll", handleScrole);
    };
  }, []);

  return (
    <div id="home">
        <Header />
        <Home />
        <ExamExplore />
        <Course />
        {/* <About /> */}
        {/* <Contact /> */}
        {/* <Faq /> */}

        {/* <IITJEE_OLVCDemo /> */}
        <Footer2 />

      


      {isVisible && (
        <span className="UpArrow" onClick={ScrollToTop}>
          <a href="#topMove">
            <TbArrowBarToUp style={{ fontSize: "30px" }} />
          </a>
        </span>
      )}
    </div>
  );
};

export default UG_HOME;
