import React, { useState, useEffect, useRef } from "react";
// import "../NEET/NEET.css";
import { useParams } from "react-router-dom";
import IITJAM_MockTest_11 from "./JSONDATA/IITjamMockTest1Data.json";
import IITJAM_MockTest_2 from "./JSONDATA/IITjamMockTest1Data.json";
import IITJAM_MockTest_3 from "./JSONDATA/IITjamMockTest1Data.json";
import "../GATE/OnlineTestSerices_pg.css";
import '../GATE/NewOtsPgCss.css'
import greenBox from "../../MockTest/asserts/greenBox.png";
import orangeBox from "../../MockTest/asserts/orangeBox.png";
import purpleBox from "../../MockTest/asserts/purpleBox.png";
import purpleTickBox from "../../MockTest/asserts/purpleTickBox.png";
import grayBox from "../../MockTest/asserts/grayBox.png";
import ScientificCalculator from "../GATE/ScientificCalculator";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import { HiDownload } from "react-icons/hi";
import jsPDF from "jspdf";
import logo from "../../MockTest/JEE(MAINS)/logoegrad.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Pie } from "react-chartjs-2";
const mockTestsData = {
  101: IITJAM_MockTest_11,
  102: IITJAM_MockTest_2,
  103: IITJAM_MockTest_3,
};

function IITJAM_MockTest_1() {
  const { selectedMockTestId } = useParams();
  const [showCalculator, setShowCalculator] = useState(false);
  const questionsData = mockTestsData[selectedMockTestId];
  const [selectedSubject, setSelectedSubject] = useState(
    questionsData.length > 0 ? questionsData[0].SubjectName : null
  );
  const duration = 180;
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [currentSolutionVideo, setCurrentSolutionVideo] = useState(null);
  const [showSolutionVideo, setShowSolutionVideo] = useState(false);
  const [activeButtonsoution, setActiveButtonsolution] = useState("");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [viewedQuestions, setViewedQuestions] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [markedForReview, setMarkedForReview] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [remainingTime, setRemainingTime] = useState(1 * 60);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [notAnsweredCount, setNotAnsweredCount] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const [showPopuptime, setShowPopuptime] = useState(false);
  const [showPopupscore, setShowPopupscore] = useState(false);
  const [showEvaluvationPopup, setShowEvaluvationPopup] = useState(false);
  const [activeButton, setActiveButton] = useState("performance");
  const [showPerformance, setShowPerformance] = useState(true);
  const [inputValues, setInputValues] = useState({});

  const getQuestionKey = (subject, sectionIndex, questionId) => {
    return `${subject}-${sectionIndex}-${questionId}`;
  };
  const [totalCorrectMarks, setTotalCorrectMarks] = useState(0.0);
  const [totalNegativeMarks, setTotalNegativeMarks] = useState(0.0);
  const [currentSolution, setCurrentSolution] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const handleShowSolutions = () => {
    setShowPerformance(false);
    setActiveButton("solutions");
  };
  const handleShowPerformance = () => {
    setShowPerformance(true);
    setActiveButton("performance");
  };
  const [showInstructions, setshowInstructions] = useState(false);

  const handleInstructions = () => {
    setshowInstructions(!showInstructions);
  };
  const [countDown, setCountDown] = useState(duration * 60);
  const [timeSpent, setTimeSpent] = useState(0);
  const timerId = useRef(null);



  // **************************************************************************
  const scroll = (direction) => {
    const palette = document.querySelector(".pg_numberpaletdiv");
    const scrollAmount = 50; // Adjust the amount of scrolling
    palette.scrollLeft += direction * scrollAmount;
  };


  const scrollSection = (direction) => {
    const section = document.querySelector(".Pg_sectiondiv");
    const scrollAmount = 150; // You can adjust the scroll amount based on your layout
    section.scrollLeft += direction * scrollAmount;
  };
  // **************************************************************************

  // Countdown logic
  useEffect(() => {
    if (countDown > 0) {
      timerId.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timerId.current);
    }
  }, [countDown]);
  // Calculate the time spent based on the initial duration and current countdown
  useEffect(() => {
    const initialDurationInSeconds = duration * 60;
    setTimeSpent(initialDurationInSeconds - countDown);
  }, [countDown, duration]);
  const hours = Math.floor(countDown / 3600);
  const minutes = Math.floor((countDown % 3600) / 60);
  const seconds = countDown % 60;

  // Calculate time spent in hours, minutes, and seconds
  const spentHours = Math.floor(timeSpent / 3600);
  const spentMinutes = Math.floor((timeSpent % 3600) / 60);
  const spentSeconds = timeSpent % 60;

  // State variable to store the formatted time spent
  const [formattedTimeSpent, setFormattedTimeSpent] = useState("");

  const formatTime = (hours, minutes, seconds) => {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // Convert total duration to seconds
  const totalDurationInSeconds = duration * 60;

  // Convert formatted time spent to seconds for remaining time calculation
  const formattedTimeParts = formattedTimeSpent.split(":").map(Number);
  const formattedTimeInSeconds =
    formattedTimeParts[0] * 3600 +
    formattedTimeParts[1] * 60 +
    formattedTimeParts[2];

  // Calculate remaining time display
  const remainingTimeInSeconds = duration * 60 - formattedTimeInSeconds;

  // Convert remaining time back to HH:MM:SS format
  const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
  const remainingMinutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
  const remainingSeconds = remainingTimeInSeconds % 60;

  const remainingTimeDisplay = `${remainingHours
    .toString()
    .padStart(2, "0")}:${remainingMinutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

  const handleSubmitPerformancePage = () => {
    setShowPopupscore(true);
  };
  const handleSubjectChangemenu = (event) => {
    setSelectedSubject(event.target.value);
  };
  const getInputValue = (selectedSubject, currentSectionIndex, questionId) => {
    // Get the key using the provided parameters
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      questionId
    );
    // console.log("Getting input value for key:", key); // <-- Move this line here

    // Retrieve the input value for the specified key from the inputValues state
    return inputValues[key] || "";
  };
  const selectedSubjectData = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const handleShowSolution = (solution) => {
    // Check if the current solution is the one being clicked
    if (currentSolution === solution) {
      // Toggle the visibility
      setShowSolution(!showSolution);
    } else {
      // Show the new solution
      setCurrentSolution(solution);
      setShowSolution(true);
    }
  };


  const pieOptions = {
    plugins: {
      datalabels: {
        color: "#fff", // Data label color
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`; // Display label and value
        },
      },
      legend: {
        position: "top",
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets[0].data.map((value, index) => ({
              text: `${chart.data.labels[index]}: ${value}`, // Label with data value
              fillStyle: chart.data.datasets[0].backgroundColor[index],
              hidden: !chart.data.datasets[0].data[index],
              index,
            }));
          },
        },
      },
    },
  };

  // const calculateCounts = () => {
  //   let answeredCount = 0;
  //   let markedForReviewCount = 0;
  //   let notVisitedCount = 0;
  //   let answeredAndMarkedForReview = 0;
  //   let notAnsweredCount = 0;

  //   questionsData.forEach((subject) => {
  //     subject.sections.forEach((section, sectionIndex) => {
  //       section.questions.forEach((question) => {
  //         const key = getQuestionKey(
  //           subject.SubjectName,
  //           sectionIndex,
  //           question.question_id
  //         );

  //         const response = responses[key];
  //         const viewed = viewedQuestions[key];
  //         const markedForReviewStatus = markedForReview[key];

  //         if (response !== undefined && response !== null) {
  //           if (markedForReviewStatus) {
  //             answeredAndMarkedForReview++;
  //           } else {
  //             answeredCount++;
  //           }
  //         } else {
  //           if (markedForReviewStatus) {
  //             markedForReviewCount++;
  //           } else if (!viewed) {
  //             notVisitedCount++;
  //           } else {
  //             notAnsweredCount++;
  //           }
  //         }
  //       });
  //     });
  //   });

  //   return {
  //     answeredCount,
  //     markedForReviewCount,
  //     answeredAndMarkedForReview,
  //     notVisitedCount,
  //     notAnsweredCount,
  //   };
  // };

  const calculateCounts = () => {
    const subjectCounts = {};
    let totalExams = 0;
    let totalAnsweredCount = 0;
    let totalMarkedForReviewCount = 0;
    let totalAnsweredAndMarkedForReview = 0;
    let totalNotVisitedCount = 0;
    let totalNotAnsweredCount = 0;

    // Initialize left questions variable
    let totalLeftQuestions = 0;

    questionsData.forEach((subject) => {
      const subjectName = subject.SubjectName;
      const sectionCounts = {};
      let subjectTotalQuestions = 0;
      let subjectAnsweredCount = 0;
      let subjectMarkedForReviewCount = 0;
      let subjectAnsweredAndMarkedForReview = 0;
      let subjectNotVisitedCount = 0;
      let subjectNotAnsweredCount = 0;

      subject.sections.forEach((section, sectionIndex) => {
        let answeredCount = 0;
        let markedForReviewCount = 0;
        let answeredAndMarkedForReview = 0;
        let notVisitedCount = 0;
        let notAnsweredCount = 0;

        section.questions.forEach((question) => {
          const key = getQuestionKey(
            subjectName,
            sectionIndex,
            question.question_id
          );
          const response = responses[key];
          const viewed = viewedQuestions[key];
          const markedForReviewStatus = markedForReview[key];

          if (response !== undefined && response !== null) {
            if (markedForReviewStatus) {
              answeredAndMarkedForReview++;
            } else {
              answeredCount++;
            }
          } else {
            if (markedForReviewStatus) {
              markedForReviewCount++;
            } else if (!viewed) {
              notVisitedCount++;
            } else {
              notAnsweredCount++;
            }
          }
        });

        // Update section counts
        sectionCounts[section.SectionName] = {
          answeredCount,
          markedForReviewCount,
          answeredAndMarkedForReview,
          notVisitedCount,
          notAnsweredCount,
          totalQuestions: section.questions.length,
        };

        // Update subject totals
        subjectTotalQuestions += section.questions.length;
        subjectAnsweredCount += answeredCount;
        subjectMarkedForReviewCount += markedForReviewCount;
        subjectAnsweredAndMarkedForReview += answeredAndMarkedForReview;
        subjectNotVisitedCount += notVisitedCount;
        subjectNotAnsweredCount += notAnsweredCount;
      });

      // Update subject counts
      subjectCounts[subjectName] = {
        totalQuestions: subjectTotalQuestions,
        answeredCount: subjectAnsweredCount,
        markedForReviewCount: subjectMarkedForReviewCount,
        answeredAndMarkedForReview: subjectAnsweredAndMarkedForReview,
        notVisitedCount: subjectNotVisitedCount,
        notAnsweredCount: subjectNotAnsweredCount,
      };

      // Update overall totals
      totalExams += subjectTotalQuestions;
      totalAnsweredCount += subjectAnsweredCount;
      totalMarkedForReviewCount += subjectMarkedForReviewCount;
      totalAnsweredAndMarkedForReview += subjectAnsweredAndMarkedForReview;
      totalNotVisitedCount += subjectNotVisitedCount;
      totalNotAnsweredCount += subjectNotAnsweredCount;

      // Log for debugging
      console.log(`Subject: ${subjectName}`, {
        totalQuestions: subjectTotalQuestions,
        answeredCount: subjectAnsweredCount,
        markedForReviewCount: subjectMarkedForReviewCount,
        answeredAndMarkedForReview: subjectAnsweredAndMarkedForReview,
        notVisitedCount: subjectNotVisitedCount,
        notAnsweredCount: subjectNotAnsweredCount,
      });
    });

    // Calculate left questions
    totalLeftQuestions = totalExams - totalAnsweredCount;

    // Log final totals
    console.log("Final Totals:", {
      totalExams,
      totalAnsweredCount,
      totalMarkedForReviewCount,
      totalAnsweredAndMarkedForReview,
      totalNotVisitedCount,
      totalNotAnsweredCount,
      totalLeftQuestions, // Log the left questions
    });

    return {
      totalExams,
      totalAnsweredCount,
      totalMarkedForReviewCount,
      totalAnsweredAndMarkedForReview,
      totalNotVisitedCount,
      totalNotAnsweredCount,
      totalLeftQuestions, // Return the left questions
      subjects: subjectCounts,
    };
  };

  const closePopuptime = () => {
    setShowPopuptime(false);
  };
  const counts = calculateCounts();

  const getQuestionClassName = (index, key) => {
    let className = "";
    if (index === currentQuestionIndex) className += " active ";
    if (markedForReview[key]) {
      className += responses[key] ? " marked-answered " : " marked ";
    } else if (responses[key]) {
      className += " answered ";
    } else if (viewedQuestions[key]) {
      className += " viewed ";
    } else {
      className += " not-visited ";
    }
    return className.trim();
  };

  const handleIconClick = () => {
    setShowCalculator(!showCalculator);
  };

  useEffect(() => {
    let count = 0;
    questionsData.forEach((subject) => {
      subject.sections.forEach((section) => {
        count += section.questions.length;
      });
    });
  }, []);

  const handleOptionChange = (option) => {
    console.log("Selected option:", option);
    if (currentQuestion) {
      const key = getQuestionKey(
        selectedSubject,
        currentSectionIndex,
        currentQuestion.question_id
      );
      console.log("Key:", key);
      setResponses({ ...responses, [key]: option });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (remainingTime === 0) {
      setIsAutoSubmit(true);
      setShowPopup(true);
      // handleAutoSubmit();
    }
  }, [remainingTime]);

  const clearResponse = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    if (responses[key]) {
      // Decrement the answered count and increment the not answered count
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount - 1);
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1);

      // Update marks based on the current awarded marks
      const previousMarks = awardedMarks[key] || 0;
      if (previousMarks > 0) {
        // If it was previously correct, decrement the counts
        setRightAnsweredCount((prev) => prev - 1);
        setTotalRightMarks((prev) => prev - previousMarks);
      } else if (previousMarks < 0) {
        // If it was previously incorrect, decrement the counts
        setWrongAnsweredCount((prev) => prev - 1);
        setTotalWrongMarks((prev) => prev - Math.abs(previousMarks));
      }
    }

    // Clear the response and reset the marks for this question
    setResponses({ ...responses, [key]: null });
    setAwardedMarks((prevMarks) => ({ ...prevMarks, [key]: 0 })); // Reset marks
    setAnsweredQuestions((prevAnswered) => ({ ...prevAnswered, [key]: false }));
    setViewedQuestions((prevViewed) => ({ ...prevViewed, [key]: true }));

    // Recalculate total marks immediately after clearing the response
    const updatedMarks = { ...awardedMarks, [key]: 0 }; // Updated marks with cleared response
    const newTotalMarks = Object.values(updatedMarks).reduce(
      (sum, marks) => sum + marks,
      0
    );

    setTotalMarks(newTotalMarks); // Update total marks
  };

  const moveToQuestion = (newSectionIndex, newQuestionIndex) => {
    const key = getQuestionKey(
      selectedSubject,
      newSectionIndex,
      currentSubject.sections[newSectionIndex].questions[newQuestionIndex]
        .question_id
    );
    if (!viewedQuestions[key]) {
      setViewedQuestions((prev) => ({ ...prev, [key]: true }));
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1); // Increment notAnsweredCount
    }
    setCurrentSectionIndex(newSectionIndex);
    setCurrentQuestionIndex(newQuestionIndex);
  };

  const nextQuestion = () => {
    const currentSection = currentSubject.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else if (currentSubjectIndex < questionsData.length - 1) {
        const nextSubject = questionsData[currentSubjectIndex + 1];
        setSelectedSubject(nextSubject.SubjectName);
        setCurrentSectionIndex(0);
        setCurrentQuestionIndex(0);
      }
    }
  };
  const handleShowEvaluvationPopup = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setShowEvaluvationPopup(true);
    setShowPopup(false);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };
  const handlebuttoNo = () => {
    setShowPopup(false);
    setIsTimerRunning(true);
  };
  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      moveToQuestion(currentSectionIndex, currentQuestionIndex - 1);
    } else {
      const currentSubjectIndex = questionsData.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const prevSectionIndex = currentSectionIndex - 1;
      if (prevSectionIndex >= 0) {
        setCurrentSectionIndex(prevSectionIndex);
        const prevSection = currentSubject.sections[prevSectionIndex];
        setCurrentQuestionIndex(prevSection.questions.length - 1);
      } else if (currentSubjectIndex > 0) {
        const prevSubject = questionsData[currentSubjectIndex - 1];
        setSelectedSubject(prevSubject.SubjectName);
        const lastSectionIndex = prevSubject.sections.length - 1;
        setCurrentSectionIndex(lastSectionIndex);
        setCurrentQuestionIndex(
          prevSubject.sections[lastSectionIndex].questions.length - 1
        );
      }
    }
  };

  // previous funtion
  // const saveAndNext = () => {
  //   const currentSection = currentSubject.sections[currentSectionIndex];
  //   const key = getQuestionKey(
  //     selectedSubject,
  //     currentSectionIndex,
  //     currentQuestion.question_id
  //   );

  //   if (responses[key] !== undefined) {
  //     // Update answered state
  //     setAnsweredQuestions({ ...answeredQuestions, [key]: true });

  //     // Check if question was marked for review
  //     if (markedForReview[key]) {
  //       setMarkedForReview({ ...markedForReview, [key]: false });
  //     }

  //     const correctAnswer = currentQuestion.answer; // Get the correct answer from JSON
  //     const userAnswer = responses[key];
  //     const marksForQuestion = parseFloat(currentQuestion.marks); // Convert marks to number

  //     let negativeMarks = 0;

  //     // Determine negative marking based on question type
  //     if (
  //       currentQuestion.qtype === "MCQ" ||
  //       currentQuestion.qtype === "MSQ" ||
  //       currentQuestion.qtype === "NATD"
  //     ) {
  //       if (marksForQuestion === 1) {
  //         negativeMarks = 1 / 3; // 1/3 for wrong answers
  //       } else if (marksForQuestion === 2) {
  //         negativeMarks = 2 / 3; // 2/3 for wrong answers
  //       }
  //     }

  //     // Check the user's answer
  //     if (
  //       userAnswer !== null &&
  //       correctAnswer &&
  //       correctAnswer === userAnswer
  //     ) {
  //       setCorrectAnswersCount((prevCount) => prevCount + 1);
  //       setTotalMarks((prevTotalMarks) =>
  //         parseFloat((prevTotalMarks + marksForQuestion).toFixed(2))
  //       );
  //     } else if (
  //       userAnswer !== null &&
  //       correctAnswer &&
  //       correctAnswer !== userAnswer
  //     ) {
  //       setWrongAnswersCount((prevCount) => prevCount + 1);
  //       setTotalMarks((prevTotalMarks) =>
  //         parseFloat((prevTotalMarks - negativeMarks).toFixed(2))
  //       ); // Deduct marks for incorrect answer
  //     }

  //     setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);

  //     // Move to next question
  //     if (currentQuestionIndex < currentSection.questions.length - 1) {
  //       moveToQuestion(currentSectionIndex, currentQuestionIndex + 1);
  //     } else {
  //       const currentSubjectIndex = questionsData.findIndex(
  //         (subject) => subject.SubjectName === selectedSubject
  //       );
  //       if (currentSubjectIndex < questionsData.length - 1) {
  //         const nextSubject = questionsData[currentSubjectIndex + 1];
  //         setSelectedSubject(nextSubject.SubjectName);
  //         setCurrentSectionIndex(0);
  //         setCurrentQuestionIndex(0);
  //       }
  //     }
  //   } else {
  //     setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1);
  //     nextQuestion();
  //   }
  // };
  // previous funtion end

  const maximumMarks = 100; // Define the maximum marks for the exam
  const [totalRightMarks, setTotalRightMarks] = useState(0);
  const [totalWrongMarks, setTotalWrongMarks] = useState(0);
  const [rightAnsweredCount, setRightAnsweredCount] = useState(0);
  const [wrongAnsweredCount, setWrongAnsweredCount] = useState(0);
  const [awardedMarks, setAwardedMarks] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const saveAndNext = () => {
    if (activeQuestion < questionsData.length - 1) {
      setActiveQuestion((prev) => prev + 1);
    }
 
    const currentSection = currentSubject.sections[currentSectionIndex];
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
 
    let inputValue = getInputValue(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    inputValue =
      typeof inputValue === "string" ? inputValue.trim().toLowerCase() : "";
 
    let correctAnswer = currentQuestion.answer?.trim().toLowerCase();
    let [rightMarks, wrongMarks] = currentQuestion.marks
      .split(",")
      .map((mark) => parseFloat(eval(mark)));
 
    let userResponse =
      responses[key] !== undefined
        ? responses[key].toString().trim().toLowerCase()
        : "";
    let newMarksAwarded = 0;
 
    const wasPreviouslyAnswered = awardedMarks[key] !== undefined;
    const previousMarks = awardedMarks[key] || 0;
 
    // Adjust previously awarded marks if changing the answer
    if (wasPreviouslyAnswered) {
      // If the previous answer was correct
      if (previousMarks > 0) {
        setRightAnsweredCount((prev) => prev - 1);
        setTotalRightMarks((prev) => prev - previousMarks);
      }
      // If the previous answer was incorrect
      else if (previousMarks < 0) {
        setWrongAnsweredCount((prev) => prev - 1);
        setTotalWrongMarks((prev) => prev - Math.abs(previousMarks));
      }
    }
 
    // Calculate marks for the current answer
    if (userResponse !== "") {
      // Handle Multiple Select Questions (MSQ)
      if (currentQuestion.qtype === "MSQ") {
        correctAnswer = correctAnswer.split(",").map((ans) => ans.trim());
        userResponse = Array.isArray(userResponse)
          ? userResponse
          : userResponse.split(",").map((ans) => ans.trim());
 
        const totalCorrect = correctAnswer.length;
        const userCorrectChoices = userResponse.filter((ans) =>
          correctAnswer.includes(ans)
        );
        const userCorrectCount = userCorrectChoices.length;
 
        const userIncorrectChoices = userResponse.filter(
          (ans) => !correctAnswer.includes(ans)
        );
        const userIncorrectCount = userIncorrectChoices.length;
 
        if (userIncorrectCount > 0) {
          newMarksAwarded = negativeMarks; // Negative marks for incorrect options
          setWrongAnsweredCount((prev) => prev + 1);
          setTotalWrongMarks((prev) => prev + Math.abs(newMarksAwarded));
        } else if (
          userCorrectCount === totalCorrect &&
          userCorrectCount === userResponse.length
        ) {
          newMarksAwarded = rightMarks; // Full marks for correct options
          setRightAnsweredCount((prev) => prev + 1);
          setTotalRightMarks((prev) => prev + newMarksAwarded);
        } else if (
          userCorrectCount > 0 &&
          userCorrectCount < totalCorrect &&
          userCorrectCount === userResponse.length
        ) {
          newMarksAwarded = (rightMarks * userCorrectCount) / totalCorrect; // Partial marks
          setRightAnsweredCount((prev) => prev + 1);
          setTotalRightMarks((prev) => prev + newMarksAwarded);
        }
      }
      // Handle MCQ and other types
      else if (["MCQ", "NAT"].includes(currentQuestion.qtype)) {
        const isCorrectResponse =
          userResponse === correctAnswer ||
          (inputValue !== "" && inputValue === correctAnswer);
        newMarksAwarded = isCorrectResponse ? rightMarks : -wrongMarks;
 
        if (isCorrectResponse) {
          setRightAnsweredCount((prev) => prev + 1);
          setTotalRightMarks((prev) => prev + newMarksAwarded);
        } else {
          setWrongAnsweredCount((prev) => prev + 1);
          setTotalWrongMarks((prev) => prev + Math.abs(newMarksAwarded));
        }
      }
 
      // Update awarded marks for this question
      awardedMarks[key] = newMarksAwarded;
 
      // Increment the attempted count only if the question was answered
      setAnsweredCount((prevAnsweredCount) => prevAnsweredCount + 1);
      setNotAnsweredCount((prevCount) => prevCount - 1);
    }
 
    // Update total marks and percentage
    const newTotalMarks = calculateCorrectTotalMarks();
    setTotalMarks(Math.max(newTotalMarks, 0).toFixed(2));
    const calculatedPercentage = calculatePercentage(newTotalMarks);
    setPercentage(calculatedPercentage);
 
    // Move to the next question/section if necessary
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      nextQuestion();
    } else {
      const mockTestsArray = Object.values(mockTestsData);
      const currentSubjectIndex = mockTestsArray.findIndex(
        (subject) => subject.SubjectName === selectedSubject
      );
      const nextSectionIndex = currentSectionIndex + 1;
 
      // Check for the next section
      if (nextSectionIndex < currentSubject.sections.length) {
        setCurrentSectionIndex(nextSectionIndex);
        setCurrentQuestionIndex(0);
      } else {
        if (currentSubjectIndex < mockTestsData.length - 1) {
          const nextSubject = mockTestsData[currentSubjectIndex + 1];
          setSelectedSubject(nextSubject.SubjectName);
          setCurrentSectionIndex(0);
          setCurrentQuestionIndex(0);
        }
      }
    }
  };

  const calculateCorrectTotalMarks = () => {
    let totalMarks = 0;

    console.log("Awarded Marks:", awardedMarks); // Debugging line to check contents

    // Calculate total marks for sections other than Section B
    for (const key in awardedMarks) {
      if (!key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          totalMarks += mark;
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Total Marks after sections other than B:", totalMarks); // Debugging

    // Calculate total marks for Section B
    const sectionBMarks = [];
    for (const key in awardedMarks) {
      if (key.includes("-1-")) {
        const mark = awardedMarks[key];
        if (typeof mark === "number" && !isNaN(mark)) {
          sectionBMarks.push(mark);
        } else {
          console.warn(`Invalid mark found for key ${key}:`, mark);
        }
      }
    }

    console.log("Section B Marks:", sectionBMarks); // Debugging

    // Sort Section B marks in descending order and take the top 4
    sectionBMarks.sort((a, b) => b - a);
    totalMarks += sectionBMarks
      .slice(0, 4)
      .reduce((acc, mark) => acc + mark, 0);

    console.log("Total Marks after including Section B:", totalMarks); // Debugging

    return totalMarks;
  };
  const pieData = {
    labels: ["Correct Answers", "Wrong Answers"],
    datasets: [
      {
        label: "Score Distribution",
        data: [rightAnsweredCount, wrongAnsweredCount],
        backgroundColor: ["#4CAF50", "#F44336"], // Colors for correct and wrong answers
        borderColor: ["#4CAF50", "#F44336"],
        borderWidth: 1,
      },
    ],
  };
  const calculatePercentage = (totalMarks) => {
    const totalPossibleMarks = 180; // Define the total possible marks
    const percentage = (totalMarks / totalPossibleMarks) * 100; // Calculate the percentage
    return percentage.toFixed(2); // Return percentage rounded to 2 decimal places
  };

  const percentageMarks = ((totalMarks / maximumMarks) * 100).toFixed(2);

  const goToQuestion = (index) => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentSection.questions[index].question_id
    );
    if (!viewedQuestions[key]) {
      setViewedQuestions((prev) => ({ ...prev, [key]: true }));
      setNotAnsweredCount((prevNotAnsweredCount) => prevNotAnsweredCount + 1); // Increment notAnsweredCount
    }
    setCurrentQuestionIndex(index);
  };

  const handleSubjectChange = (subjectName) => {
    setSelectedSubject(subjectName);
    setCurrentSectionIndex(0);
    setCurrentQuestionIndex(0);
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    setCurrentQuestionIndex(0);
  };

  const markForReview = () => {
    const key = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );
    setMarkedForReview({ ...markedForReview, [key]: true });
    setViewedQuestions({ ...viewedQuestions, [key]: true });
    nextQuestion();
  };
  const handleSubmit = () => {
    const formattedTime = formatTime(spentHours, spentMinutes, spentSeconds);
    setFormattedTimeSpent(formattedTime);
    setShowPopup(true);
    setShowPopupscore(false);
    setIsTimerRunning(false);
  };

  useEffect(() => {
    if (remainingTime === 0) {
      handleSubmit(); // Automatically submit when time is up
    }
  }, [remainingTime]);

  const closePopup = () => {
    setShowPopup(false);
    window.location.href = "/VITEEE_OTS_PAGE";
    window.close();
  };

  // Calculate percentage of time spent and remaining time
  const percentageSpent =
    (formattedTimeInSeconds / totalDurationInSeconds) * 100;
  const percentageRemaining =
    ((totalDurationInSeconds - formattedTimeInSeconds) /
      totalDurationInSeconds) *
    100;

  const currentSubject = questionsData.find(
    (subject) => subject.SubjectName === selectedSubject
  );
  const currentSection = currentSubject?.sections[currentSectionIndex];
  const currentQuestion = currentSection?.questions[currentQuestionIndex];

  useEffect(() => {
    // Set the viewed status of the first question of the selected subject to true
    const firstQuestionKey = getQuestionKey(
      selectedSubject,
      0,
      currentSubject.sections[0].questions[0].question_id
    );
    setViewedQuestions({ ...viewedQuestions, [firstQuestionKey]: true });
  }, [selectedSubject]);

  const handleButtonClick = () => {
    setShowQuestions(!showQuestions);
  };

  const handleCheckboxChange = (optionIndex) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current responses for the question
    const currentResponses = responses[questionKey] || [];

    // Toggle the option in the response
    const updatedResponses = currentResponses.includes(optionIndex)
      ? currentResponses.filter((index) => index !== optionIndex)
      : [...currentResponses, optionIndex];

    // Update the state with the new responses
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: updatedResponses,
    }));
  };

  const handleNatdInputChange = (value) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: value,
    }));
  };
  const handleNatdNumberClick = (num) => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current value
    const currentValue = responses[questionKey] || "";

    // Append the number or dot to the current value
    const newValue = currentValue + num;

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: newValue,
    }));
  };

  const handleNatdBackspace = () => {
    const questionKey = getQuestionKey(
      selectedSubject,
      currentSectionIndex,
      currentQuestion.question_id
    );

    // Get the current value
    const currentValue = responses[questionKey] || "";

    // Remove the last character
    const newValue = currentValue.slice(0, -1);

    // Update the state with the new value
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionKey]: newValue,
    }));
  };
  // Handle the Clear All button click
  const handleClearAll = () => {
    // Implement the logic to clear all input values
    // For example, you might want to clear a state variable:
    handleNatdInputChange(""); // Assuming you have a state variable for the input
  };

  // Handle arrow button clicks (left and right)
  const handleNatdArrowClick = (direction) => {
    // Implement the logic to move the cursor within the input field
    // You might need to manipulate the cursor position or selection
    console.log(`Arrow clicked: ${direction}`);
  };
  const marksInfo = currentQuestion.marks.split(","); // Split the marks string into an array
  const correctMarks = marksInfo[0]; // First part is the correct answer marks
  const negativeMarks = marksInfo[1];
  const handleDownloadPdf = () => {
    const elementsToCapture = [
      document.querySelector(".downloadscslassname"),
      document.querySelector(".Congratulationssubdiv"),
      document.querySelector(".CongratulationsTimeSpent"),
      document.querySelector(".Chart_container1 .pieContainer"),
      document.querySelector(".Chart_container1 .circularProgressBarContainer"),
    ];

    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = 210; // A4 page width in mm
    const pageHeight = 297; // A4 page height in mm
    const margin = 10;
    const contentMargin = 10; // Margin between border and content
    const topBottomMargin = 15; // Additional space from top and bottom borders
    let yOffset = margin + topBottomMargin; // Initial offset including top margin

    const textDescriptions = [
      "eGRAD Tutor Mock Test Report",
      "Score Section",
      "Time Spent Analysis",
      "Correct/Wrong Answers Pie Chart",
      "Total Percentage",
    ];

    // Function to load the logo as base64
    const addLogo = (callback) => {
      const image = new Image();
      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;
        canvas.getContext("2d").drawImage(this, 0, 0);
        const base64data = canvas.toDataURL("image/png");

        const logoWidth = 80; // Adjust width of logo
        const logoHeight = 30; // Adjust height of logo
        const logoX = 10; // X position of logo
        const logoY = 10; // Y position of logo

        pdf.addImage(base64data, "PNG", logoX, logoY, logoWidth, logoHeight);

        const padding = 10; // Add some space between the logo and the text
        yOffset = logoY + logoHeight + padding;

        pdf.setFontSize(16);
        pdf.text(textDescriptions[0], margin + contentMargin, yOffset);

        callback();
      };
      image.onerror = function (error) {
        console.error("Error loading logo image:", error);
        callback(); // Proceed even if the logo fails to load
      };
      image.src = logo; // Use the imported image
    };

    const captureAndAddToPdf = (elementIndex) => {
      if (elementIndex >= elementsToCapture.length) {
        pdf.save("charts.pdf");
        return;
      }

      const element = elementsToCapture[elementIndex];
      const description = textDescriptions[elementIndex];
      const scale = element.classList.contains("downloadscslassname") ? 3 : 2;

      if (element.classList.contains("downloadscslassname")) {
        element.style.fontSize = "18px";
      }

      html2canvas(element, { scale: scale, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Calculate image dimensions
        const imgWidth = pageWidth - margin * 2 - 2 * contentMargin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Adjust image height and width if necessary
        let adjustedHeight = imgHeight;
        let adjustedWidth = imgWidth;

        if (
          yOffset + adjustedHeight + contentMargin + margin >
          pageHeight - topBottomMargin
        ) {
          // If content does not fit on the current page, add a new page
          pdf.addPage();
          yOffset = margin + topBottomMargin; // Reset yOffset for the new page
        }

        // Add text description
        pdf.setFontSize(12);
        pdf.text(description, margin + contentMargin, yOffset + contentMargin);
        yOffset += contentMargin + 10;

        // Add image
        pdf.addImage(
          imgData,
          "PNG",
          margin + contentMargin,
          yOffset + contentMargin,
          adjustedWidth,
          adjustedHeight
        );

        // Update yOffset for the next element
        yOffset += adjustedHeight + contentMargin + margin;

        if (element.classList.contains("downloadscslassname")) {
          element.style.fontSize = "";
        }

        captureAndAddToPdf(elementIndex + 1);
      });
    };

    // Start the process by adding the logo and then capturing other elements
    addLogo(() => {
      captureAndAddToPdf(1); // Start capturing elements from index 1
    });
  };


  const handleShowSolutionVideo = (video) => {
    if (currentSolutionVideo === video) {
      // Toggle video visibility
      setShowSolutionVideo(!showSolutionVideo);
      setActiveButtonsolution("SolutionVideo");
      setShowSolution(false);
    } else {
      // Show video and hide solution
      setCurrentSolutionVideo(video);
      setShowSolutionVideo(true);
      setShowSolution(false); // Explicitly hide solution
      setActiveButtonsolution("SolutionVideo");
    }
  };


  const convertToEmbedUrl = (url) => {
    if (!url || url === "null") {
      // If the URL is null or invalid, return an empty string
      return "";
    }
  
    // Handle short YouTube links like https://youtu.be/VIDEO_ID
    if (url.includes("youtu.be")) {
      const videoId = url.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/embed/${videoId}?rel=0`; // Append ?rel=0
    }
  
    // Handle standard YouTube links like https://www.youtube.com/watch?v=VIDEO_ID
    if (url.includes("watch?v=")) {
      const videoId = url.split("watch?v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoId}?rel=0`; // Append ?rel=0
    }
  
    // Default case returns the original URL if already embedded or unrecognized
    return url;
  };
  
  return (
    <div>
      <div className="Pg_OtsLogo">
        <img
          src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
          alt=""
        />
      </div>
      <div className="Pg_otsheadin1">
        <p className="Pg_TestName">{questionsData[0].Test_Name}</p>

        <div>
          <button onClick={handleButtonClick} className="ViewQuestions">
            <i class="fa-solid fa-align-justify Pg_justify"></i>
            {showQuestions ? "Hide Questions" : "View Questions"}
          </button>
          <button onClick={handleInstructions} className="ViewQuestions">
              <i class="fa-solid fa-info pg_info"></i>
              {showInstructions ? "Hide Instructions" : "View Instructions"}
            </button>
        </div>
      </div>
      <div className="pg_otsMaindiv">
        <div className="pg_otsMaindivSub">
          <div className="containerpg">
            {selectedSubject && (
              <div>
                {questionsData
                  .filter((subject) => subject.SubjectName === selectedSubject)
                  .map((subject) => (
                    <div key={subject.SubjectName} className="message-body">
                      <div className="arrowpg"></div>
                      <h2 className="subject-title1 selected">
                        {subject.SubjectName}
                      </h2>
                      <div className="i_symbol_mock_exam">
                        <i class="fa-solid fa-info pg_info"></i>
                        <div className="Mock_test_details_structure">
                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={greenBox} alt="Not Answered" />
                              <span className="NotAnsweredlegend pg_countspan">
                                0
                              </span>
                            </div>
                            <label className="mock_paras" htmlFor="">
                              Answered
                            </label>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={orangeBox} alt="Not Answered" />
                              <span className="NotAnsweredlegend pg_countspan">
                                0
                              </span>
                            </div>
                            <p className="mock_paras">Not Answered</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={grayBox} alt="Not Visited" />
                              <span className="NotVisited pg_countspan">0</span>
                            </div>
                            <p className="mock_paras">Not Visited</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={purpleBox} />
                              <span className="marked  pg_countspan">0</span>
                            </div>
                            <p className="mock_paras">Marked for Review</p>
                          </div>

                          <div className="pg_colorboxots_mock">
                            <div className="mock_test_count_details">
                              <img src={purpleTickBox} />
                              <span className="marked  pg_countspan">0</span>
                            </div>
                            <p className="mock_paras">
                              {" "}
                              Answered & Marked for Review(will also be
                              evaluted)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="handleIconClick">
              <div onClick={handleIconClick} className="handleIconClick1">
                <i className="fa-solid fa-calculator"></i>
              </div>
              {showCalculator && <ScientificCalculator />}
            </div>
          </div>
          <div className="pg_Sectionsdiv">
            <p>Sections</p>
            <p>
              Time remaining:{" "}
              {`${Math.floor(remainingTime / 3600)}:${Math.floor(
                (remainingTime % 3600) / 60
              )}:${remainingTime % 60}`}
            </p>
          </div>



       
          <div>
            {/* <div className="Pg_sectiondiv"> */}
            {/* {questionsData.map((subject) => (
                <button
                  key={subject.SubjectName}
                  onClick={() => handleSubjectChange(subject.SubjectName)}
                  className={`Pg_section ${
                    selectedSubject === subject.SubjectName ? "active" : ""
                  }`}
                >
                  {subject.SubjectName}
                </button>
              ))} */}
            {selectedSubject && (

                // *************************************************************
                <div className="sectionArrowsDivForScrolling">
                {/* Left Arrow Button */}
                <span className="leftArrowOfQPInJEE" onClick={() => scrollSection(-1)}>
                  &#8249;
                </span>
              <div className="Pg_sectiondiv">
                {currentSubject.sections.map((section, index) => (
                  <button
                    key={section.SectionName}
                    onClick={() => handleSectionChange(index)}
                    className={`Pg_section ${
                      currentSectionIndex === index ? "active" : ""
                    }`}
                  >
                    {section.SectionName}
                    <div className="i_symbol_mock_exam">
                      <i class="fa-solid fa-info pg_info"></i>
                      <div className="Mock_test_details_structure">
                        <div className="pg_colorboxots_mock">
                          <div className="mock_test_count_details">
                            <img src={greenBox} alt="Not Answered" />
                            <span className="NotAnsweredlegend pg_countspan">
                              0
                            </span>
                          </div>
                          <label className="mock_paras" htmlFor="">
                            Answered
                          </label>
                        </div>

                        <div className="pg_colorboxots_mock">
                          <div className="mock_test_count_details">
                            <img src={orangeBox} alt="Not Answered" />
                            <span className="NotAnsweredlegend pg_countspan">
                              0
                            </span>
                          </div>
                          <p className="mock_paras">Not Answered</p>
                        </div>

                        <div className="pg_colorboxots_mock">
                          <div className="mock_test_count_details">
                            <img src={grayBox} alt="Not Visited" />
                            <span className="NotVisited pg_countspan">0</span>
                          </div>
                          <p className="mock_paras">Not Visited</p>
                        </div>

                        <div className="pg_colorboxots_mock">
                          <div className="mock_test_count_details">
                            <img src={purpleBox} />
                            <span className="marked  pg_countspan">0</span>
                          </div>
                          <p className="mock_paras">Marked for Review</p>
                        </div>

                        <div className="pg_colorboxots_mock">
                          <div className="mock_test_count_details">
                            <img src={purpleTickBox} />
                            <span className="marked  pg_countspan">0</span>
                          </div>
                          <p className="mock_paras">
                            {" "}
                            Answered & Marked for Review(will also be evaluted)
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
              <span className="rightArrowOfQPInJEE" onClick={() => scrollSection(1)}>
                          &#8250;
                        </span>
                      </div>

// *********************************************************************************
            )}
            {/* </div> */}
          </div>


    
          <div className="pg_Questiontypediv">
          <p className="pg_Questiontypep">
                    Question Type:
                    {currentQuestion.qtype}
                  </p>
            <div className="pg_markingdiv">
              <p>
                Marks for correct answer:
                <span className="PG_Correct_Answers">{correctMarks} | </span>
              </p>
              <p>
                Nagative Marks:
                <span className="PG_Negitive_Answers">{negativeMarks}</span>
              </p>
            </div>
          </div>
          <div className="pg_quizpageots">
            {/* {selectedSubject && currentQuestion && (
              <div>
                <div className="pg_Questionnodiv">
                  {" "}
                  <p className="pg_Questionnop">
                    Question {currentQuestion.question_id}:
                  </p>
                </div>
                <div className="pg_quizpageots">
                  <div className="pg_quizotsQuestion">
                    {" "}
                    <img src={currentQuestion.questionImgName} alt="" />
                  </div>
                  <div className="pg_quizotsoptions">
                    {" "}
                    {currentQuestion.options.map((option) => (
                      <label key={option.option_id} className="option">
                        <input
                          type="radio"
                          id={`option-${option.option_id}`}
                          name={`question-${currentQuestion.question_id}`}
                          checked={
                            responses[
                              getQuestionKey(
                                selectedSubject,
                                currentSectionIndex,
                                currentQuestion.question_id
                              )
                            ] === option.option_index
                          }
                          onChange={() =>
                            handleOptionChange(option.option_index)
                          }
                        />
                        <span htmlFor={`option-${option.option_id}`}>
                          {option.option_index}
                        </span>
                        <img src={option.optionImgName} alt="" />
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            )} */}
            {selectedSubject && currentQuestion && (
              <div>
                <div className="pg_Questionnodiv">
                  <p className="pg_Questionnop">
                    Question {currentQuestion.question_id}:
                  </p>
                </div>
                <div className="pg_quizpageots">
                  <div className="pg_quizotsQuestion">
                    <img
                      src={currentQuestion.questionImgName}
                      alt={`Question ${currentQuestion.question_id}`}
                    />
                  </div>

                  {/* Conditional rendering based on question type */}
                  <div className="pg_quizotsoptions">
                    {currentQuestion.qtype === "MCQ" &&
                      currentQuestion.options.map((option) => (
                        <label key={option.option_id} className="option">
                          <input
                            type="radio"
                            id={`option-${option.option_id}`}
                            name={`question-${currentQuestion.question_id}`}
                            checked={
                              responses[
                                getQuestionKey(
                                  selectedSubject,
                                  currentSectionIndex,
                                  currentQuestion.question_id
                                )
                              ] === option.option_index
                            }
                            onChange={() =>
                              handleOptionChange(option.option_index)
                            }
                            aria-labelledby={`option-label-${option.option_id}`}
                          />
                          <span className="pg_span_braces" id={`option-label-${option.option_id}`}>
                            ({option.option_index})
                          </span>
                          <img
                            src={option.optionImgName}
                            alt={`Option ${option.option_index}`}
                          />
                        </label>
                      ))}

                    {currentQuestion.qtype === "MSQ" &&
                      currentQuestion.options.map((option) => (
                        <label key={option.option_id} className="option">
                          <input
                            type="checkbox"
                            id={`option-${option.option_id}`}
                            name={`question-${currentQuestion.question_id}`}
                            checked={responses[
                              getQuestionKey(
                                selectedSubject,
                                currentSectionIndex,
                                currentQuestion.question_id
                              )
                            ]?.includes(option.option_index)}
                            onChange={() =>
                              handleCheckboxChange(option.option_index)
                            }
                            aria-labelledby={`option-label-${option.option_id}`}
                          />
                          <span  className="pg_span_braces" id={`option-label-${option.option_id}`}>
                            ({option.option_index})
                          </span>
                          <img
                            src={option.optionImgName}
                            alt={`Option ${option.option_index}`}
                          />
                        </label>
                      ))}

                    {currentQuestion.qtype === "NAT" && (
                      <div className="natd-input-container ">
                        <input
                          type="text"
                          value={
                            responses[
                              getQuestionKey(
                                selectedSubject,
                                currentSectionIndex,
                                currentQuestion.question_id
                              )
                            ] || ""
                          }
                          onChange={(e) =>
                            handleNatdInputChange(e.target.value)
                          }
                          className="natd-input"
                          placeholder="Enter your answer"
                          readOnly
                        />
                        <div className="number-pad">
                          <div className="onebuttonrow">
                            {" "}
                            <button onClick={handleNatdBackspace}>
                              Backspace
                            </button>
                          </div>
                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("7")}>
                              7
                            </button>
                            <button onClick={() => handleNatdNumberClick("8")}>
                              8
                            </button>
                            <button onClick={() => handleNatdNumberClick("9")}>
                              9
                            </button>
                          </div>
                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("4")}>
                              4
                            </button>
                            <button onClick={() => handleNatdNumberClick("5")}>
                              5
                            </button>
                            <button onClick={() => handleNatdNumberClick("6")}>
                              6
                            </button>
                          </div>

                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("1")}>
                              1
                            </button>
                            <button onClick={() => handleNatdNumberClick("2")}>
                              2
                            </button>
                            <button onClick={() => handleNatdNumberClick("3")}>
                              3
                            </button>
                          </div>

                          <div className="number-row">
                            <button onClick={() => handleNatdNumberClick("0")}>
                              0
                            </button>
                            <button onClick={() => handleNatdNumberClick(".")}>
                              .
                            </button>
                            <button onClick={() => handleNatdNumberClick("-")}>
                              -
                            </button>
                          </div>
                          <div className="number-row1">
                            <button
                              onClick={() => handleNatdArrowClick("left")}
                            >
                              ←
                            </button>
                            <button
                              onClick={() => handleNatdArrowClick("right")}
                            >
                              →
                            </button>
                          </div>
                          <div className="onebuttonrow">
                            <button onClick={handleClearAll}>Clear All</button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="pg_handlebuttons">
            <div className="pg_handleReview">
              <button onClick={markForReview}>Mark For Review</button>
              <button onClick={clearResponse}>Clear Response</button>
            </div>
            <div className="pg_saveprevious">
              <div className="pg_handleReview">
                {" "}
                <button onClick={previousQuestion}>Previous</button>
                {/* <button onClick={nextQuestion}>Next</button> */}
              </div>
              <button
            class="Quiz_mockbtns Quiz_mockbtnssubmit MW768PXSubmitBtn"
            onClick={handleSubmit}
            disabled={remainingTime === 0}
          >
            Submit
          </button>
              <button className="pg_saveandnextots" onClick={saveAndNext}>
                Save &amp; Next
              </button>
            </div>
          </div>
        </div>
        <div className="pg_norightdiv">
          <div class="pg_StudentDetailsots">
            <img
              title="Guest"
              src="/static/media/guesticon.00ad06aaa8a7af8eb6c5.jpg"
              alt="guesticon"
              className="users_profile_imgots"
            />
            <p>Candidate Name: Guest</p>
          </div>

          <div className="pg_legenddiv">
            <div className="pg_colorboxes ">
              {Object.keys(counts.subjects)
                .filter((subjects) => subjects === selectedSubject)
                .map((subjectName) => (
                  <div className="pg_colorboxesdiv" key={subjectName}>
                    <div>
                      <div
                        className="pg_colorboxotssubdiv"
                        style={{ display: "flex", gap: "14px" }}
                      >
                        <div className="pg_colorboxots">
                          <div>
                            <img src={greenBox} alt="Answered" />
                            <span className="Answered pg_countspan">
                              {counts.subjects[subjectName].answeredCount}
                            </span>
                          </div>
                          <label htmlFor="">Answered</label>
                        </div>
                        <div className="pg_colorboxots">
                          <div style={{ marginBottom: "-1rem" }}>
                            <img src={orangeBox} alt="Not Answered" />
                            <span className="NotAnsweredlegend pg_countspan">
                              {counts.subjects[subjectName].notAnsweredCount}
                            </span>
                          </div>
                          <label htmlFor="">Not Answered</label>
                        </div>
                      </div>
                      <div
                        className="pg_colorboxotssubdiv"
                        style={{ marginTop: "-1rem" }}
                      >
                        <div className="pg_colorboxots">
                          <div style={{ marginBottom: "-1rem" }}>
                            <img src={grayBox} alt="Not Visited" />
                            <span className="NotVisited pg_countspan">
                              {counts.subjects[subjectName].notVisitedCount}
                            </span>
                          </div>
                          <label htmlFor="">Not Visited</label>
                        </div>
                        <div className="pg_colorboxots">
                          <div>
                            <div className="legendImgDiv ">
                              <img src={purpleBox} alt="Marked for Review" />
                            </div>
                            <span className="marked pg_countspan">
                              {
                                counts.subjects[subjectName]
                                  .markedForReviewCount
                              }
                            </span>
                          </div>
                          <p>Marked for Review</p>
                        </div>
                      </div>
                      <div className="pg_colorboxotsFW  ">
                        <div className="pRelative">
                          <div className="legendImgDiv ">
                            <img
                              src={purpleTickBox}
                              alt="Answered & Marked for Review"
                            />
                          </div>
                          <span className="toBeOnImg">
                            {
                              counts.subjects[subjectName]
                                .answeredAndMarkedForReview
                            }
                          </span>
                        </div>
                        Answered & Marked for Review (will also be evaluated)
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ width: "100%" }}>
              <div className="Pg_sectiondivno">
                {currentSubject.SubjectName}
              </div>
            </div>
            <div className="pg_divotsnumber">
              <p>Choose a Question</p>

{/* *********************************************************************** */}

<div className="pgquestion-number-container">
                  {/* Left Arrow Button */}
                  <button className="scroll-arrow left" onClick={() => scroll(-1)}>
                    &#8249; {/* Left Arrow Icon */}
                  </button>
              <div className="pgquestion-number-palette question-numberpalette pgquestion-palette pg_numberpaletdiv">
                {currentSection.questions.map((question, index) => {
                  const key = getQuestionKey(
                    selectedSubject,
                    currentSectionIndex,
                    question.question_id
                  );
                  return (
                    <button
                      key={question.question_id}
                      className={getQuestionClassName(index, key)}
                      onClick={() => goToQuestion(index)}
                    >
                      {index + 1}
                    </button>
                  );
                })}
              </div>
              <button className="scroll-arrow right" onClick={() => scroll(1)}>
                    &#8250; {/* Right Arrow Icon */}
                  </button>
                </div>



              {/* ****************************************** */}
            </div>{" "}
          </div>
          <button
            class="Quiz_mockbtns Quiz_mockbtnssubmit MW768PXSubmitBtnNone"
            onClick={handleSubmit}
            disabled={remainingTime === 0}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="scorecardforexam">
        {showPopup && (
          <div className="popup2">
            <div className="popup-content">
              {remainingTime === 0 ? (
                <div>
                  <div>
                    <p>Your Time is up!</p>
                    <p>Your test is automatically submitted successfully.</p>
                  </div>
                  {/* <ul class="allscorecountingmock">
                      <li>
                        <b>Answered:</b> {answeredCount}
                      </li>
                      <li>
                        <b>Not Answered:</b> {notAnsweredCount}
                      </li>
                      <li>
                        <b>Not Visited:</b> {counts.notVisitedCount}
                      </li>
                    </ul> */}
                  <div className="Jeemains_mocktest_yes_no_buttons_container">
                    <button
                      title="Press okay and view report"
                      className="MockTest_submit_Yes_btn"
                      // onClick={handleSubmit}
                      onClick={handleShowEvaluvationPopup}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <p>The Actual Test time is of 180 mins.</p>
                    <p>Do you want to Continue submiting the test</p>
                  </div>
                  {/* <ul class="allscorecountingmock">
                      <li>
                        <b>Answered:</b> {answeredCount}
                      </li>
                      <li>
                        <b>Not Answered:</b> {notAnsweredCount}
                      </li>
                      <li>
                        <b>Not Visited:</b> {counts.notVisitedCount}
                      </li>
                    </ul> */}
                  <div className="Jeemains_mocktest_yes_no_buttons_container">
                    <button
                      title="Save Test and Exit"
                      className="MockTest_submit_Yes_btn"
                      // onClick={handleSubmit}
                      onClick={handleShowEvaluvationPopup}
                    >
                      Yes
                    </button>
                    <button
                      title="Return to Test"
                      className="MockTest_submit_No_btn"
                      onClick={handlebuttoNo}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showQuestions && (
        <div className="questionslistpopup">
          {questionsData.map((subject, subjectIndex) => (
            <div
              key={subject.SubjectName}
              className="questionslistpopup-content"
            >
              <h3>{subject.SubjectName}</h3>
              {subject.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="Quetionpaperdiv">
                  <ul>
                    {section.questions.map((question, questionIndex) => (
                      <li
                        key={question.question_id}
                        onClick={() => goToQuestion(questionIndex)}
                      >
                        Question {question.question_id}:{" "}
                        <img src={question.questionImgName} alt="" />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
  {showInstructions && (
          <div className="questionslistpopup">
            <p className="questionslistpopup_Instructions">Instructions</p>
            <div className="questionslistpopup-content">
              <div>
                <p className="pg_Note">
                  Note that the timer is ticking while you read the
                  instructions.Close this page to return to answering the
                  quetions.
                </p>
                {/* <p className="Instructionspg">Instructions</p> */}
                <div className="pg_readinstructions">
                  Please read the instructions carefully
                </div>

                <ul className="PG_General_Instructions_Ul_tag">
                  <p className="pg_siteheding">General Instructions:</p>
                  <li value="100">
                    1.Total duration of examination is <span>180</span> minutes.
                  </li>
                  <li>
                    2.The clock will be set at the server. The countdown timer
                    in the top right corner of screen will display the remaining
                    time available for you to complete the examination. When the
                    timer reaches zero, the examination will end by itself. You
                    will not be required to end or submit your examination.
                  </li>
                  <li>
                    3.The Question Palette displayed on the right side of screen
                    will show the status of each question using one of the
                    following symbols:
                  </li>
                  <ul>
                    <li>
                      <img src={grayBox} /> You have not visited the question
                      yet.
                    </li>
                    <li>
                      <img src={orangeBox} /> You have not answered the
                      question.
                    </li>
                    <li>
                      <img src={greenBox} />
                      You have answered the question.
                    </li>
                    <li>
                      <img src={purpleBox} /> You have NOT answered the
                      question, but have marked the question for review.
                    </li>
                    <li>
                      <img src={purpleTickBox} /> The question(s) "Answered and
                      Marked for Review" will be considered for evaluation.
                    </li>
                    <li>
                      The Marked for Review status for a question simply
                      indicates that you would like to look at that question
                      again.
                    </li>
                  </ul>
                  <li>
                    4.You can click on the arrow which appears to the left of
                    question palette to collapse the question palette thereby
                    maximizing the question window. To view the question palette
                    again, you can click on which appears on the right side of
                    question window.
                  </li>
                  <li>
                    5.You can click on your "Profile" image on top right corner
                    of your screen to change the language during the exam for
                    entire question paper. On clicking of Profile image you will
                    get a drop-down to change the question content to the
                    desired language.
                  </li>
                  <li>
                    6.You can click on <i class="fa-solid fa-circle-down"></i>{" "}
                    to navigate to the bottom and{" "}
                    <i class="fa-solid fa-circle-up"></i> navigate to the top of
                    the question area, without scrolling.
                  </li>
                  <p className="pg_siteheding">
                    <span>Navigating to a Question:</span>
                  </p>
                  <li>
                    7.To answer a question, do the following:
                    <ul>
                      <li>
                        a.Click on the question number in the Question Palette
                        at the right of your screen to go to that numbered
                        question directly. Note that using this option does NOT
                        save your answer to the current question.
                      </li>
                      <li>
                        b.Click on <span>Save & Next</span> to save your answer
                        for the current question and then go to the next
                        question.
                      </li>
                      <li>
                        c.Click on <span>Mark for Review & Next</span> to save
                        your answer for the current question, mark it for
                        review, and then go to the next question.
                      </li>
                    </ul>
                  </li>
                  <p className="pg_siteheding">
                    <span>Answering a Question :</span>
                  </p>
                  <li>
                    8.Procedure for answering a multiple choice type question
                    <ul>
                      <li>
                        To select your answer, click on the button of one of the
                        options
                      </li>
                      <li>
                        To deselect your chosen answer, click on the button of
                        the chosen option again or click on the{" "}
                        <span>Clear Response </span>button
                      </li>
                      <li>
                        To change your chosen answer, click on the button of
                        another option
                      </li>
                      <li>
                        To save your answer, you MUST click on the{" "}
                        <span>Save & Next</span>
                        button
                      </li>
                      <li>
                        To mark the question for review, click on the{" "}
                        <span>Mark for Review & Next button.</span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    9.To change your answer to a question that has already been
                    answered, first select that question for answering and then
                    follow the procedure for answering that type of question.
                  </li>
                  <p className="pg_siteheding">Navigating through sections:</p>
                  <li>
                    10.Sections in this question paper are displayed on the top
                    bar of the screen. Questions in a section can be viewed by
                    clicking on the section name. The section you are currently
                    viewing is highlighted.
                  </li>
                  <li>
                    11.After clicking the Save & Next button on the last
                    question for a section, you will automatically be taken to
                    the first question of the next section.
                  </li>
                  <li>
                    12.You can shuffle between sections and questions anytime
                    during the examination as per your convenience only during
                    the time stipulated.
                  </li>
                  <li>
                    13.Candidate can view the corresponding section summary as
                    part of the legend that appears in every section above the
                    question palette.
                  </li>
                  <li>
                    14.To zoom the image provided in the question roll over it.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      <div className="scorecardforexam">
        {showEvaluvationPopup && (
          <div className="Evaluvationpopup">
            <div className="popupcontentEvaluvation">
              <p className="Evaluvationsummary">Test Summary</p>
              <div>
                <div className="EvaluvationSuccessfully">
                  Your Test Submitted Successfully
                </div>
                {/* <p className="EvaluvationsummaryThank">Thank you for submitting your test.The summary of the test given below.</p> */}
                <div className="EvaluvationsummaryTestName">
                  <div className="EvaluvationsummaryTest">Test Name :</div>
                  <div> {questionsData[0].Test_Name}</div>
                </div>
                <div>
                  <table>
                    <tr>
                      <th>Total Questions</th>
                      <th>Maximum Marks</th>
                      <th>Total Attempted</th>
                      <th>Left Questions</th>
                      <th>Correct Questions</th>
                      <th>InCorrect Questions</th>
                      <th>Total Time(in min.)</th>
                      <th>Time Taken(in min.)</th>
                      <th>Right Marks</th>
                      <th>Negative Marks</th>
                      <th>Total Marks</th>
                    </tr>
                    <tr>
                      <td>65</td>
                      <td>100</td>
                      <td> {counts.totalAnsweredCount}</td>
                      <td>{counts.totalLeftQuestions}</td>
                      <td>{rightAnsweredCount} </td>
                      <td>{wrongAnsweredCount}</td>
                      <td>180</td>
                      <td>{formattedTimeSpent}</td>
                      <td>{totalRightMarks}</td>
                      <td>{totalWrongMarks.toFixed(2)}</td>
                      <td>{totalMarks}</td>
                    </tr>
                  </table>
                  <div>
                    <button
                      onClick={handleSubmitPerformancePage}
                      className="ReportSolutions"
                    >
                      View Report/Solutions
                    </button>
                    <button
                      className="mocktestClosebtnPieChartpage"
                      onClick={closePopup}
                    >
                      Close
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {showPopuptime && (
          <div className="timepopup">
            <div className="timepopupcontent">
              <p>
                Time is not over yet! Please wait until the time is up to submit
                test.
              </p>
              <button onClick={closePopuptime}>OK</button>
            </div>
          </div>
        )}
      </div>

      <div>
        {showPopupscore && (
          <div className="popup3">
            <div className="popup-contentscore mocksPopUpContentScoreContainer">
              <div className="popup_header">
                <div className="remaining">
                  <div className="popHeaderLogo ">
                    <img
                      src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
                      alt="Logo"
                    />
                  </div>

                  <div className="JEE_MAINS_MOCKTEST_HEADING">
                    <h4>{questionsData[0].Test_Name}</h4>
                  </div>
                </div>
              </div>
              <div className="mocktestOverallPiechartsDivContainer">
                <div className="mocktestdatadiv mocktestOverallPiechartsDiv">
                  <div className="Repostpagepopupclosebutton">
                    <button
                      className="mocktestClosebtnPieChartpage"
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                  <div className="downloadscslassname">
                    <div className="mocktestdataheading mocktestPieChartsdataheading">
                      <p className="mocktestHeadingPiechartpage">
                        Test Details
                      </p>
                    </div>

                    {/* <div className="mocktestdataheading">Test Details</div> */}
                    <div className="mocktestdatasubdiv mocktestOverallPiechartsSubDiv">
                      {" "}
                      <div className="mocktestdatasubdiv11 mocksPagePieChartsTestDetailsBC">
                        Test Name:{" "}
                        <span className="mocksTestPieChartspan">
                          {questionsData[0].Test_Name}
                        </span>
                      </div>
                      <div className="mocktestdatasubdiv12 mocksPagePieChartsTestDetailsBC">
                        Subjects:{" "}
                        <span className="mocksTestPieChartspan">
                          {questionsData
                            .map((subject) => subject.SubjectName)
                            .join(", ")}
                        </span>
                      </div>
                      <div className="mocktestdatasubdiv13 mocksPagePieChartsTestDetailsBC">
                        Test Duration:{" "}
                        <span className="mocksTestPieChartspan">180 Mins</span>
                      </div>
                      <div className="mocktestdatasubdiv14 mocksPagePieChartsTestDetailsBC">
                        Total Questions:
                        <span className="mocksTestPieChartspan">60</span>
                      </div>
                      <div className="mocktestdatasubdiv15 mocksPagePieChartsTestDetailsBC">
                        Total Marks:{" "}
                        <span className="mocksTestPieChartspan">100</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add buttons for Performance and Solutions */}
                <div className="containerInPerformance mocksPagePieChartsContainerPerform">
                  <div className="button-container">
                    <div className="Progress_solution_container">
                      <button
                        id="performance_solution"
                        className={
                          activeButton === "performance" ? "active" : ""
                        }
                        onClick={handleShowPerformance}
                      >
                        Your Performance
                      </button>
                      <button
                        id="performance_solution"
                        className={activeButton === "solutions" ? "active" : ""}
                        onClick={handleShowSolutions}
                      >
                        Solutions
                      </button>
                    </div>
                  </div>

                  <div>
                    {showPerformance ? (
                      <div className="Chart_container mocksPagePieChartsTestDetailsChatContainerDiv">
                        <div className="download-button mockTestsdownloadbtnDivPicharts">
                          <button
                            className="mockTestsdownloadbtnPicharts"
                            onClick={handleDownloadPdf}
                          >
                            Download PDF{" "}
                            <HiDownload className="mocktestdownloadPiechartsIconn" />
                          </button>
                        </div>

                        <div className="Congratulationsdiv mocksPagePieChartsTestDetailsCongratsDiv">
                          {/* Download Button */}

                          <div className="Congratulationssubdiv mocksPagePieChartsTestDetailsCongratsSubDiv">
                            <div>
                              <img
                                src="/static/media/prize.e48700d2bb773b1eda58.jpg"
                                alt="Congratulations"
                              />
                            </div>
                            <div className="Mocktest_result_page_scrore_container mockspageResultsScore">
                              {/* <h2>{message}</h2> */}
                              <span className="mockscore">
                                Your score: {totalMarks}
                              </span>
                            </div>
                          </div>

                          {/* Horizontal bar to display spent time and remaining time */}
                          <div className="Congratulationssubdiv CongratulationsTimeSpent mocksPagePieChartsTestDetailsCongratsSubDiv">
                            <h2 className="mocksPagePieChartsTimeHeading">
                              Time Spent
                            </h2>
                            <div className="Time_bar_container">
                              <div className="Time_Spent_Remaining_Time_container">
                                <div className="Time_Spent">
                                  <p
                                    style={{
                                      backgroundColor: "#e96e6e",
                                      height: "15px",
                                      width: "30px",
                                      margin: "0 10px",
                                    }}
                                  ></p>
                                  <span>Time Spent</span>
                                </div>
                                <div className="Remaining_Time">
                                  <p
                                    style={{
                                      backgroundColor: "rgba(0, 0, 255, 0.44)",
                                      height: "15px",
                                      width: "30px",
                                      margin: "0 10px",
                                    }}
                                  ></p>
                                  <span>Remaining Time</span>
                                </div>
                              </div>

                              {/* Display Total Time, Time Spent, and Remaining Time */}
                              <div className="Time_spent_container">
                                {/* <p><span>Total Time:</span> {duration} mins</p> */}
                                <p>
                                  <span>Time Spent:</span> {formattedTimeSpent}{" "}
                                  mins
                                </p>
                                <p>
                                  <span>Remaining Time:</span>{" "}
                                  {remainingTimeDisplay} mins
                                </p>
                              </div>

                              {/* Horizontal Bar representing time spent and remaining time */}
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  height: "30px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#e96e6e",
                                    height: "20px",
                                    width: `${percentageSpent}%`, // Time spent width
                                  }}
                                ></div>
                                <div
                                  className="Remaining_time_container"
                                  style={{
                                    backgroundColor: "rgba(0, 0, 255, 0.44)",
                                    height: "20px",
                                    width: `${percentageRemaining}%`, // Remaining time width
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Chart_container1">
                          {/* Display the Pie Chart */}
                          <div
                            className="pieContainer"
                            // style={{ width: "300px", margin: "auto" }}
                          >
                            <Pie data={pieData} options={pieOptions} />
                            <p>Performance Analysis</p>
                          </div>
                          <div
                            className="circularProgressBarContainer"
                            // style={{ width: "155px", margin: "0 auto" }}
                          >
                            <CircularProgressbar
                              value={percentageMarks}
                              text={`${percentageMarks}%`}
                              styles={buildStyles({
                                pathColor: `rgba(255, 0, 0, ${
                                  (percentageMarks / 100) * 0.5 + 0.5
                                })`, // Red color
                                textColor: "#007bff", // Blue color for text
                                trailColor: "#ffefef", // Light red color for trail
                                backgroundColor: "#ffffff",
                              })}
                            />
                            <p>Your Total Percentage</p>
                          </div>
                        </div>

                        {/* Bar Chart to display time spent on each question */}
                        {/* <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={data}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar dataKey="Time" fill="#8884d8" barSize={20} />
                            </BarChart>
                          </ResponsiveContainer> */}
                      </div>
                    ) : (
                      <div className="mocktestsolution">
                        {/* Dropdown to select subject */}
                        <div className="Mocktest_view_solution_sections_container">
                          <label htmlFor="subjectDropdown">
                            Select Subject:{" "}
                          </label>
                          <select
                            id="subjectDropdown"
                            value={selectedSubject}
                            onChange={handleSubjectChangemenu}
                          >
                            {questionsData.map((subject, index) => (
                              <option key={index} value={subject.SubjectName}>
                                {subject.SubjectName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* Display selected subject's sections and questions */}
                        {/* {selectedSubjectData && (
                            <div>
                              {selectedSubjectData.sections.map((section) => (
                                <div key={section.SectionName}>
                                  <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                    {section.SectionName}
                                  </h2>
                               
                                  {section.questions.map((question) => {
                                   
                                    const correctAnswers =
                                      question.answer.split(",");

                                    return (
                                      <div
                                        key={question.question_id}
                                        style={{ marginBottom: "20px" }}
                                        className="solutionDiv"
                                      >
                                        <div className="questionDiv">
                                          <strong>
                                            Q{question.question_id}:{" "}
                                          </strong>
                                          <img
                                            src={question.questionImgName}
                                            alt=""
                                          />
                                        </div>
                                        <ul className="optionsUlDiv">
                                          {question.options.map((option) => (
                                            <li
                                              key={option.option_id}
                                              style={{ position: "relative" }}
                                            >
                                              ({option.option_index})
                                              <img
                                                src={option.optionImgName}
                                                alt=""
                                              />
                                            
                                              {correctAnswers.includes(
                                                option.option_index
                                              ) && (
                                                <span
                                                  className="solutions_correct_answer"
                                         
                                                >
                                                  ✔
                                                </span>
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                        <button
                                          onClick={() =>
                                            handleShowSolution(
                                              question.solution
                                            )
                                          }
                                        >
                                          {showSolution &&
                                          currentSolution === question.solution
                                            ? "Hide Solution"
                                            : "Show Solution"}
                                        </button>
                                        {showSolution &&
                                          currentSolution ===
                                            question.solution && (
                                            <div className="View_solution_container">
                                              <strong>Solution:</strong>{" "}
                                              <img
                                                className="MockTest_ReportPgae_solution_Imgtag"
                                                src={question.solution}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>
                          )} */}
                        {selectedSubjectData && (
                          <div>
                            {selectedSubjectData.sections.map(
                              (section, sectionIndex) => (
                                <div key={section.SectionName}>
                                  <h2 className="Mocktest_reportpage_Solutio_Section-heading">
                                    {section.SectionName}
                                  </h2>
                                  {section.questions.map((question) => {
                                    const correctAnswers =
                                      question.answer.split(",");
                                    const key = getQuestionKey(
                                      selectedSubject,
                                      sectionIndex,
                                      question.question_id
                                    );
                                    const userAnswer =
                                      question.qtype === "NAT" ||
                                      question.qtype === "NAT"
                                        ? getInputValue(
                                            selectedSubject,
                                            sectionIndex,
                                            question.question_id
                                          )
                                        : responses[key]; // Retrieve user's stored answer or input value for NATD/NATI

                                    // For NATD questions, check if the user answer is within the correct range
                                    let isIncorrect = false;
                                    if (
                                      question.qtype === "NAT" &&
                                      userAnswer
                                    ) {
                                      const [lowerRange, upperRange] =
                                        correctAnswers
                                          .split("-")
                                          .map((range) => parseFloat(range));

                                      isIncorrect = !(
                                        parseFloat(userAnswer) >= lowerRange &&
                                        parseFloat(userAnswer) <= upperRange
                                      );
                                    }

                                    return (
                                      <div
                                        key={question.question_id}
                                        style={{ marginBottom: "20px" }}
                                        className="solutionDiv"
                                      >
                                        <div className="questionDiv">
                                          <strong>
                                            Q{question.question_id}:{" "}
                                          </strong>
                                          <img
                                            src={question.questionImgName}
                                            alt=""
                                          />
                                        </div>

                                        {/* Handling NATD or NATI type questions */}
                                        {question.qtype === "NAT" ||
                                        question.qtype === "NAT" ? (
                                          <div className="nat-answer">
                                            <p>
                                              <strong>Correct Answer:</strong>{" "}
                                              {question.answer}
                                            </p>
                                            <p
                                              className={
                                                question.qtype === "NAT" &&
                                                isIncorrect
                                                  ? "natd-incorrect"
                                                  : ""
                                              }
                                            >
                                              <strong>Your Answer:</strong>{" "}
                                              {userAnswer ||
                                                "No answer provided"}
                                            </p>
                                          </div>
                                        ) : (
                                          <ul className="optionsUlDiv">
                                            {question.options.map((option) => {
                                              const isCorrectOption =
                                                correctAnswers.includes(
                                                  option.option_index
                                                );
                                              const isUserAnswer =
                                                userAnswer &&
                                                userAnswer.includes(
                                                  option.option_index
                                                );

                                              return (
                                                <li
                                                  key={option.option_id}
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  ({option.option_index})
                                                  <img
                                                    src={option.optionImgName}
                                                    alt=""
                                                  />
                                                  {/* Show a green ✔ if the option is correct */}
                                                  {isCorrectOption && (
                                                    <span className="solutions_correct_answer">
                                                      ✔
                                                    </span>
                                                  )}
                                                  {/* Show a red ✘ if the user selected this option but it is incorrect */}
                                                  {isUserAnswer &&
                                                    !isCorrectOption && (
                                                      <span className="incorrect-answer">
                                                        ✘
                                                      </span>
                                                    )}
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        )}
<div className="solution_video_solution_btn_container">

                                        <button
                                          onClick={() =>
                                            handleShowSolution(
                                              question.solution
                                            )
                                          }
                                        >
                                          {showSolution &&
                                          currentSolution === question.solution
                                            ? "Hide Solution"
                                            : "Show Solution"}
                                        </button>

                                        {question.vsoln !== "null" &&
                                                  question.vsoln !== null && (
                                                    <button
                                                      onClick={() =>
                                                        handleShowSolutionVideo(
                                                          question.vsoln
                                                        )
                                                      }
                                                      id="performance_solution"
                                                      className={
                                                        activeButtonsoution ===
                                                          "SolutionVideo" &&
                                                        currentSolutionVideo ===
                                                          question.vsoln
                                                          ? "active"
                                                          : ""
                                                      }
                                                    >
                                                      Solution Video
                                                    </button>
                                                  )}
</div>

{showSolution &&
                                              currentSolution ===
                                                question.solution && (
                                                <div className="View_solution_container">
                                                  <strong>Solution:</strong>{" "}
                                                  <img
                                                    className="MockTest_ReportPgae_solution_Imgtag"
                                                    src={question.solution}
                                                    alt=""
                                                  />
                                                </div>
                                              )}
                                            {showSolutionVideo &&
                                              currentSolutionVideo ===
                                                question.vsoln &&
                                              question.vsoln !== "null" &&
                                              question.vsoln !== null && (
                                                <div>
                                                  {/* Ensure that the YouTube URL is formatted correctly for embedding */}
                                                  <iframe
                                                    width="560"
                                                    height="315"
                                                    src={convertToEmbedUrl(
                                                      question.vsoln
                                                    )}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                  ></iframe>
                                                </div>
                                              )}{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div>
                    <button onClick={closePopup}>Close</button>
                  </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IITJAM_MockTest_1;
