
import bg from "./Banners/new landingbanner.png";
import b3 from "./Banners/BITSAT ONLINE TEST - WEB BANNER.jpg";
import b7 from "./Banners/NEET Banner - 1.jpg"
import b8 from "./Banners/NEET Banner - 2.jpg"
import img1 from "./Banners/img1.jpg";
import b9 from "./Banners/JEE ADVANCED MATHEMATICS WEB BANNER 1.jpg";
import b10 from "./Banners/JEE MAINS AND ADVANCED BANNER.jpg"
import b11 from "./Banners/BITSAT CRASH COURSE BANNER.jpg"
export const HomeDATA = [
  {
    // -------------------------------------------- data ---------------------------------
    scrollbar:
      "All tests will be LIVE according to the detailed schedule given. Do not confuse to the Total No. of tests in the test cards while buying as they show the number of tests LIVE at the moment..",
    // -----------------------------------banners------------------------------------------------------
    b3: [b3],
    b7: [b7],
    b8: [b8],
    img1: [img1],
    bg: [bg],
    b9: [b9],
    b10: [b10],
    b11: [b11],
  },
];


export const myBanners = [
  { img: "img3.gif", url: [b3] },
  { img: "img6.gif", url: [b7] },
  { img: "img7.gif", url: [b8] },
  { img: "img5.gif", url: [b9] },
  { img: "img6.gif", url: [b10] },
  { img: "img7.gif", url: [b11] },

];

