import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import TermsandConditions from "../TermsandConditions.pdf";
import '../NEET/NeetInstructionsPage.css';
import { IoIosArrowBack } from "react-icons/io";
 
const JEE_Advance_general_instructions = () => {
  const [isChecked, setIsChecked] = useState(false);
 
  const navigate = useNavigate();
  const { examId, mockTestId, mockTestTypeId } = useParams(); // Extract parameters from URL
 
  const handleBegin = () => {
    console.log("JEE_Advanced_MOCKTESTID", examId, mockTestId);
    if (isChecked) {
      const url = `/JeeAdvanceMocktest/${examId}/${mockTestId}/${mockTestTypeId}`;
      navigate(url, { state: { examId, mockTestId } });
    }
  };
 
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
 
  const handleClick = () => {
    navigate(`/JeeAdvNew/${examId}/${mockTestId}/${mockTestTypeId}`);
  };
 
  const sections = [
    {
      sectionNumber: 1,
      Numberofquestionsandtotalmarks: "3 questions of 12 marks",
      questionType: "MCQ with only one correct option",
      markingScheme:
        "+4 ONLY if (all) the correct option(s) is(are) chosen\n" +
        "+3 If all four options are correct but ONLY three options are chosen\n" +
        "+2 If three or more options are correct but ONLY two options are chosen, both of which are correct\n" +
        "+1 If two or more options are correct but ONLY one option is chosen and it is a correct option\n" +
        "0 If none of the options is chosen (i.e. the question is unanswered)\n" +
        "-2 In all other cases",
    },
    {
      sectionNumber: 2,
      Numberofquestionsandtotalmarks: "4 questions of 12 marks",
      questionType: "MCQ with one or more than one correct option",
      markingScheme:
        "+3 If ONLY the correct option is chosen\n" +
        "0 If none of the options is chosen (i.e. the question is unanswered)\n" +
        "-1 in all other cases",
    },
    {
      sectionNumber: 3,
      Numberofquestionsandtotalmarks: "6 questions of 24 marks",
      questionType: "Integer type",
      markingScheme:
        "+4 for the correct answer\n" +
        "0 for no attempts\n" +
        "no negative marks",
    },
    {
      sectionNumber: 4,
      Numberofquestionsandtotalmarks: "4 questions of 12 marks",
      questionType: "Match the following followed by MCQs",
      markingScheme:
        "+3 If ONLY the correct option is chosen\n" +
        "0 If none of the options is chosen (i.e. the question is unanswered)",
    },
  ];
 
  const sections1 = [
    {
      sectionNumber: 1,
      Numberofquestionsandtotalmarks: "4 questions of 12 marks",
      markingScheme:
        "+3 If ONLY the correct option is chosen\n" +
        "0 If none of the options is chosen (i.e. the question is unanswered)\n" +
        "-1 in all other cases",
    },
    {
      sectionNumber: 2,
      Numberofquestionsandtotalmarks: "4 questions of 12 marks",
      markingScheme:
        "+4 ONLY if (all) the correct option(s) is(are) chosen\n" +
        "+3 If all four options are correct but ONLY three options are chosen\n" +
        "+2 If three or more options are correct but ONLY two options are chosen, both of which are correct\n" +
        "+1 If two or more options are correct but ONLY one option is chosen and it is a correct option\n" +
        "0 If none of the options is chosen (i.e. the question is unanswered)\n" +
        "-2 In all other cases",
    },
    {
      sectionNumber: 3,
      Numberofquestionsandtotalmarks: "6 questions of 24 marks",
      markingScheme:
        "+4 for the correct answer\n" +
        "0 for no attempts\n" +
        "no negative marks",
    },
    {
      sectionNumber: 4,
      Numberofquestionsandtotalmarks: "4 questions of 12 marks",
      markingScheme:
        "+3 If ONLY the correct option is chosen\n" +
        "0 if unanswered\n" +
        "No negative marking",
    },
  ];
 
  return (
    <div className="Genralinstructions_containercss">
      <div>
        <div className="remaining">
          <div className="bitsatMockHeader">
            <img
              src="https://www.egradtutor.in/static/media/egate%20logo%201.8b1eb31347d60945925f.png"
              alt=""
            />
          </div>
          <h3 className='SubmitFormAndMail_h2'>GENERAL INSTRUCTIONS</h3>
        </div>
      </div>
 
      <div className='jee_advamced_instructions_sub_container'>
        <div className='Jee_Advanced_general_instructions_tables_container'>
          <ul className="Genralinstructions_list">
            <div>
              <h2 className='JEE_Advanced_sub_heading'>Paper 1 Pattern</h2>
              <table className='JEE_Advanced_mock_test_first_table'>
                <thead>
                  <tr>
                    <th>Section Number</th>
                    <th>Number of questions and total marks</th>
                    <th>Question Type</th>
                    <th>Marking Scheme</th>
                  </tr>
                </thead>
                <tbody>
                  {sections.map((section, index) => (
                    <tr key={index}>
                      <td>{section.sectionNumber}</td>
                      <td>{section.Numberofquestionsandtotalmarks}</td>
                      <td>{section.questionType}</td>
                      <td>
                        <ul>
                          {section.markingScheme.split("\n").map((mark, i) => (
                            <li key={i}>{mark}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ul>
          <ul className="Genralinstructions_list">
            <div>
              <h4 className='JEE_Advanced_sub_heading'>Paper 2 Pattern</h4>
              <table className='JEE_Advanced_mock_test_second_table'>
                <thead>
                  <tr>
                    <th>Section Number</th>
                    <th>Number of questions and total marks</th>
                    <th>Marking Scheme</th>
                  </tr>
                </thead>
                <tbody>
                  {sections1.map((section1, index) => (
                    <tr key={index}>
                      <td>{section1.sectionNumber}</td>
                      <td>{section1.Numberofquestionsandtotalmarks}</td>
                      <td>
                        <ul>
                          {section1.markingScheme.split("\n").map((mark, i) => (
                            <li key={i}>{mark}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ul>
        </div>
        <div className='jee_advanced_general_intructions_btns_container' style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem", display: "flex", gap: "0.5rem" }}>
          <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />
          <span style={{ fontWeight: 500, color: "black" }}>
            I accept all the
            <a href={TermsandConditions} target="_blank" style={{ fontWeight: 900, color: "blue", marginLeft: "0.5rem" }}>
              terms & conditions.
            </a>
          </span>
        </div>
        <div className="intro_next_btn__Jee_Advance_container jee_advanced_general_intructions_btns_container_ready">
          <div>
            <button className="previous-button" onClick={handleClick}>
              <IoIosArrowBack /> Previous
            </button>
          </div>
 
          <button
            className='ug_mocktest_start_button'
            onClick={handleBegin}
            disabled={!isChecked} // Disable the button if checkbox is not checked
            style={{
              backgroundColor: isChecked ? 'skyblue' : 'white',
              color: isChecked ? 'white' : 'black',
              border: '1px solid #76cbe6',
               cursor: isChecked ? 'pointer' : 'not-allowed' // Optional: Add a border to make the button more visible
            }}
          >
            I am ready to begin <AiOutlineArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};
 
export default JEE_Advance_general_instructions;