import React,{useState} from 'react';
import { useNavigate} from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import NewCandidateImage_iitJam_exam from '../../../Images/NewCandidateImageIitJamExam.jpg';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';

const IITJAM_Biotechnology_General_Instructions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const {selectedSubjectId, selectedMockTestId} = useParams();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };
    

      const handleBegin = () => {
        console.log("doremonnnnnnnnn",selectedSubjectId,selectedMockTestId);
    
            if (isChecked) {
                const url = `/ISRO_Mocktest1/${selectedSubjectId}/${selectedMockTestId}`;
                navigate(url, { state: { selectedSubjectId, selectedMockTestId } });
            }
        };

  return (
    <div className='IITJAM_instruction_page_Container'>

        <div className='Instructions_page_left_container'>
        <div className='IITJAM_instruction_page_heading'> 
            <h1 className='IIT_jam_heading'>Other Important Instructions</h1>
        </div>

        <div className='All_instructions_container'>
            <div className='instructions_headingTwo_container'>
            <h2 className='instructions_headingTwo'>Paper-specific Instructions
            Electronics Engineering (ECE) </h2>
            </div>
          
          <div className='IITJAM_Instructions_order_list_container'>
            <ul className='IITJAM_Instructions_order_list'>
                <li className='IITJAM_Instructions_list_tag'>This test paper has a total of 80 questions carrying 80 marks. The entire question paper is divided into a single <span>section</span>. Questions in that section are of (MCQ) types.</li>
                <li className='IITJAM_Instructions_list_tag'><span> Section – A </span> contains <span>Multiple Choice Questions (MCQ).</span> Each MCQ type question has four choices out of which only one choice is the correct answer. This section has 80 Questions and carry a total of 80 marks. each question carry 1 mark for correct answer and 0.33 marks deducted for each wrong answer.</li>
                
                <li className='IITJAM_Instructions_list_tag'>. A Virtual Scientific Calculator is provided on your computer screen. Any other calculators, charts, graph sheets, tables, smart watches, watches, bags, pouches, Bluetooth devices, mobile phones or electronic gadgets are NOT allowed inside the examination hall</li>

                {/* <li className='IITJAM_Instructions_list_tag'>You can use the scribble pad provided to you at the examination center for all your rough work. Write your name and registration number on the scribble pad before using it. The scribble pad MUST be returned to the invigilators after the end of the examination.</li> */}
                <li className='IITJAM_Instructions_list_tag'> Some useful data/notation required for your paper can be viewed by clicking on the Useful Data link that appears on top right hand side of the screen. The same information is also given below for your convenience.</li>
            </ul>
            <div className='Instructions_page_image_container'>
                {/* <img src = {Instructions_image} /> */}

                <table className='mock_test_first_table'>
  <thead>
    <tr>
      <th>Exam Details</th>
      <th>ISRO Exam</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Subjects & Sections </td>
      <td>ECE, ME, CSE & Single section</td>
    </tr>
    <tr>
      <td>Duration of Exam</td>
      <td>1.5 hours (90 minutes)</td>
    </tr>
    <tr>
      <td>Type of Questions</td>
      <td>Multiple Choice Questions (MCQs)</td>
    </tr>
    <tr>
      <td>Total Questions </td>
      <td>80</td>
    </tr>
    <tr>
      <td>Marking Scheme </td>
      <td><li>1 (mark for each correct answer)
        </li>
        <li>0.33 (marks deducted for each wrong answer)</li></td>
    </tr>
    <tr>
      <td>Total Marks</td>
      <td>80 marks</td>
    </tr>
  </tbody>
</table>

                
                </div>
          </div>
            </div>

            <div className="Instructions_checkbox_container">
            <div>
            <input type="checkbox" onChange={handleCheckboxChange} className="checkbox" />

            </div>
            <div>
            <span style={{ fontWeight: 500, color: "black" }}>
          I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, Bluetooth devices, etc. / any prohibited
            material with me into the Examination Hall. I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations.
          </span>
            </div>
         
        </div>

        <div className="Instructions_page_buttons_container">
          <button className="Instruction_page_pervious_btn">
            <Link to={`/Isro_ECE_Instructions/${selectedSubjectId}/${selectedMockTestId}`}>
              <IoIosArrowBack /> Previous
            </Link>
          </button>
          <div className="intro_next_btn_container">
          {isChecked ? (
            <button className="disabled-link gn_next_btn_bull" onClick={handleBegin}>
              I am ready to begin <AiOutlineArrowRight /></button>
          ) : (
            <div>
              <span className="disabled-link gn_next_btn_bull ">
                I am ready to begin <AiOutlineArrowRight />
              </span>
            </div>
          )}
        </div>
        </div>




        </div>
        <div className='Instructions_page_right_container'>
            <img src = {NewCandidateImage_iitJam_exam} alt='hi' />
        </div>
    </div>
  )
}

export default IITJAM_Biotechnology_General_Instructions

