// src/App.js
import React from "react";
import img1 from "../../../ug_homepage/components/Home_Section/Banners/BITSAT CRASH COURSE BANNER.jpg";

import { Carousel } from "react-responsive-carousel";

import bitsatOTsBanner from "../../../ug_homepage/components/Home_Section/Banners/BITSAT ONLINE TEST - WEB BANNER.jpg";

const Bitsat_Banner = () => {


  return (
    <div>
      {/* <Bitsat images={images} /> */}

      <Carousel
        autoPlay
        infiniteLoop
        showArrows={false}
        interval={4600}
        showThumbs={false}
        // showIndicators={false}
        showStatus={false}
      >
        <div>
          <img src={bitsatOTsBanner} alt="" />
        </div>

        <div>
          <img src={img1} alt="" />
        </div>
      </Carousel>
    </div>
  );
};

export default Bitsat_Banner;
