
// import explore from '../UG/Images/education.png'
// import Img1 from '../UG/Images/img1.png'
// import Img2 from '../UG/Images/img2.png'
// import Img3 from '../UG/Images/img3.png'
// import Img4 from '../UG/Images/img4.png'
// import Img5 from '../UG/Images/img5.png'
// import Img6 from '../UG/Images/img6.png'
// import hand from "../UG/Images/hand-index-fill.svg"


import explore from './images/img1.png'

import Img2 from './images/img1.png'
import Img3 from './images/img1.png'

import Img5 from './images/img1.png'
import Img6 from './images/img1.png'
import hand from "./images/img1.png"
export const Pg_ExploreData = [
    {
        IMGuRL:[explore],
        IMGuRL1:[hand],
        Title:'GATE',
        examPage: '/pggateexam',
        OnlineTestSeries: 'Online Test Series',
        OLVClasses: 'Online Live Video Classes',
        ONLINE: '',
        otslink: "/Gate_ots",
        olvclink: "/Gate_OLVC",
        cardContant: 'Pg_cardsContant',
        // explore: '/',
        // liveclasses: '/',  
    },
    {
        IMGuRL:[Img2],
        Title:'IITJAM',
        examPage: '/pgiitjamexam',
        OnlineTestSeries: 'Online Test Series',
        OLVClasses: 'Online Live Video Classes',
        ONLINE: '',
        otslink: "/iitjam_ots",
        olvclink: "/iitjam_olvc",
        cardContant: 'Pg_cardsContant',
        // explore: '/'

    },
    {
        IMGuRL:[Img3],
        Title:'ESE',
        examPage: '/pgEse_exam',
        OnlineTestSeries: 'Online Test Series',
        // OLVClasses: 'Online Live Video Classes',
        otslink: "/est_ots",
        ONLINE: '',
        cardContant: 'Pg_cardsContant',
        // explore: '/'

    },
    // {
    //     IMGuRL:[Img4],
    //     Title:'TIFR',
    //     examPage: '/Ug_Tifr_ExamPage',
    //     OnlineTestSeries: 'Online Test Series',
    //     // OLVClasses: 'Online Live Video Classes',
    //     otslink: "/tifr_ots",
    //     ONLINE: '',
    //     cardContant: 'Pg_cardsContant',
    //     // explore: '/'

    // },
    {
        IMGuRL:[Img5],
        Title:'ISRO',
        examPage: '/isro_examPage',
        OnlineTestSeries: 'Online Test Series',
        // OLVClasses: 'Online Live Video Classes',
        otslink: "/isro_ots",
        ONLINE: '',
        cardContant: 'Pg_cardsContant',
        // explore: '/'

    },
    {
        IMGuRL:[Img6],
        Title:'BARC',
        examPage: '/barc_examPage',
        OnlineTestSeries: 'Online Test Series',
        // OLVClasses: 'Online Live Video Classes',
        otslink: "/barc_ots",
        ONLINE: '',
        cardContant: 'Pg_cardsContant',
        // explore: '/'

    },
    {
        IMGuRL:[Img6],
        Title:'JEST',
        examPage: '/jest_examPage',
        OnlineTestSeries: 'Online Test Series',
        // OLVClasses: 'Online Live Video Classes',
        otslink: "/jest_ots",
        ONLINE: '',
        cardContant: 'Pg_cardsContant',
        // explore: '/'

    },
]