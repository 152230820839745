import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// ========================================================= IMPORTED TERMS AND CONDITIONS PAGES ===========================================================
import { TERMsCONDITIONS } from "./components/UG/TERMsCONDITIONS";
import { Privacypolicy } from "./components/UG/Privacypolicy";

// ========================================================= IMPORTED LANDING PAGE ===========================================================
import { Egate } from "./Landing_PAGE/Egate";

// ========================================================= IMPORTED UG FILES ===========================================================
import UG_HOME from "./components/ug_homepage/UG_HOME";
import { IITJEE_EXAM } from "./components/UG/IITJEE_EXAM";
import OTS from "./components/UG/OTS";
import { JeeOts } from "./components/UG/JeeOts";
import OLVC from "./components/UG/IITJEE_OLVC";
import { NeetExam } from "./components/UG/NeetExam";
import { NeetOtc } from "./components/UG/NeetOtc";
import { NeetOLVC } from "./components/UG/NeetOLVC";
import { BitsatExam } from "./components/UG/BitsatExam";
import { BitsatOTC } from "./components/UG/BitsatOTC";
import { BitsatOLVC } from "./components/UG/BitsatOLVC";
import { ViteeeExam } from "./components/UG/ViteeeExam";
import { ApEamcetExam } from "./components/UG/ApEamcetExam";
import { TsEamcetExam } from "./components/UG/TsEamcetExam";
import { MhcetExam } from "./components/UG/MhcetExam";
import { KcetExam } from "./components/UG/KcetExam";
import { WbjeeExam } from "./components/UG/WbjeeExam";
import { KeamExam } from "./components/UG/KeamExam";
import { SrmJeeExam } from "./components/UG/SrmJeeExam";
import AeeeOTS from "./components/UG/AeeeOTS.jsx";

// ========================================================= IMPORTED PG FILES ===========================================================
import { Pg_home_page } from "./components/PG/PG_homePage/Pg_home_page";
import { PGGATE_Exam } from "./components/PG/PGGATE_Exam.jsx";
import { PGIITJAMExam } from "./components/PG/PGIITJAMExam.jsx";
import { Ese_ExamPage } from "./components/PG/Ese_ExamPage";
import { Pg_Tifr_ExamPage } from "./components/PG/Pg_Tifr_ExamPage";
import { Pg_Isro_ExamPage } from "./components/PG/Pg_Isro_ExamPage";

import { Pg_BARC_Exam_Page } from "./components/PG/Pg_BARC_Exam_Page";

// ========================================================= IMPORTED MBA FILES ===========================================================
import { MbaHome } from "./components/MBA/MbaHome";

// ========================================================= UNUSED IMPORTED FILES ===========================================================
import Landingpage from "../src/Landingpage";
import NEET from "./components/UG/NEET";
import { Landing } from "./Landing";
import { Ug_About } from "./components/UG/Ug_homePage_k/Ug_About/Ug_About";
import { Jee_ots } from "./components/UG/Ug ots pages/jee ots olvc/jee ots/Jee_ots";
import { PgHome } from "./components/PG/PgHome";
import LandingPage from "./NewLanding_Page/LandingPage.jsx";
import ScrollToTop from "./Landing_PAGE/ScrollToTop.js";
import Examheader from "./components/UG/Examheader.jsx";
import { JeeOTSPage } from "./components/UG/new UG OTS PAGE/JeeOTSPage.jsx";
import { ViteeeOTSPage } from "./components/UG/new UG OTS PAGE/ViteeeOTSPage.jsx";
import { Ap_EapcetOTSPage } from "./components/UG/new UG OTS PAGE/Ap_EapcetOTSPage.jsx";
import { TS_EamcetOTSPAge } from "./components/UG/new UG OTS PAGE/TS_EamcetOTSPAge.jsx";
import { MhcetOTSPage } from "./components/UG/new UG OTS PAGE/MhcetOTSPage.jsx";
import { KcetOTSPage } from "./components/UG/new UG OTS PAGE/KcetOTSPage.jsx";

import { KeamOTSPage } from "./components/UG/new UG OTS PAGE/KeamOTSPage.jsx";
import { SrmjeeOTSPage } from "./components/UG/new UG OTS PAGE/SrmjeeOTSPage.jsx";
import { State } from "./components/PG/context/State.jsx";

import Payu from "./components/Payu/Payu.jsx";
import Buttonpayu from "./components/Payu/Buttonpayu.jsx";

import AeeeExam from "./components/UG/AeeeExam.jsx";
import Metexam from "./components/UG/Metexam.jsx";
import Ap_EapcetOLVC from "./components/UG/Ap_EapcetOLVC.jsx";
import WbjeeOLVC from "./components/UG/WbjeeOLVC.jsx";
import Ts_EamcetOLVC from "./components/UG/Ts_EamcetOLVC.jsx";

import Kcet_OLVC from "./components/UG/Kcet_OLVC.jsx";
import KeamOLVC from "./components/UG/KeamOLVC.jsx";
import Srmjee_OLVC from "./components/UG/Srmjee_OLVC.jsx";
import Aeee_OLVC from "./components/UG/Aeee_OLVC.jsx";
import Met_OLVC from "./components/UG/Met_OLVC.jsx";
import { Met_OTS } from "./components/UG/Met_OTS.jsx";



import CancellationRefundPolicy from "./components/UG/CancellationRefundPolicy.jsx";

// import Payubutton from "./components/Payu/Payubutton.jsx"

// import Payu from "./components/Payu/Payu.jsx";


import { ViteeeOLVC } from "./components/UG/ViteeeOLVC.jsx";
import Pg_JEST_ExamPage from "./components/PG/Pg_JEST_ExamPage.jsx";
import { GATE_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/GATE_OTS_PAGE.jsx";
import GATE_OLVC_Page from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OLVC_PAGES/GATE_OLVC_Page.jsx";
import { IIT_JAM_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/IIT_JAM_OTS_PAGE.jsx";
import IIT_JAM_OLVC from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OLVC_PAGES/IIT_JAM_OLVC.jsx";
import { EST_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/EST_OTS_PAGE.jsx";
import { TIFR_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/TIFR_OTS_PAGE.jsx";
import { ISRO_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/ISRO_OTS_PAGE.jsx";
import { BARC_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/BARC_OTS_PAGE.jsx";
import { JEST_OTS_PAGE } from "./components/PG/PG_homePage/Pg_Explor_Exam/PG_OTS_PAGS/JEST_OTS_PAGE.jsx";
import Contact from "./components/ug_homepage/components/Contact/Contact.js";
import { Pg_Countact } from "./components/PG/PG_homePage/Pg_Countact_Page/Pg_Countact.jsx";
import Faq from "./components/ug_homepage/components/Faqs/Faq.js";
import FaqPG from "./components/PG/Faqs/Faq.js";
import Pricing_Policy from "./components/UG/Pricing_Policy.jsx";
import { PracticesCode } from "./components/MBA/Practices code/PracticesCode.jsx";



import RazorpayForm from "./components/Payu/RazorpayForm.jsx";


import Download from "./components/ug_homepage/components/Downloads/Download.jsx"
import AP_EAPCETDownloadPage from './components/ug_homepage/components/Downloads/AP_EAPCETDownloadPage.jsx';
import IitjeeDownloadPage from "./components/ug_homepage/components/Downloads/IitjeeDownloadPage.jsx";

// import IitjeeDownloadPage_new from './components/ug_homepage/components/Downloads/IitjeeDownloadPage_new.jsx'
import JeeAdvancedDownloadPage from './components/ug_homepage/components/Downloads/JeeAdvancedDownloadPage.jsx';
import KcetDownloadPage from './components/ug_homepage/components/Downloads/KcetDownloadPage.jsx';
import MhcetDownloadPage from './components/ug_homepage/components/Downloads/MhcetDownloadPage.jsx';
import NeetDownloadPage from './components/ug_homepage/components/Downloads/NeetDownloadPage.jsx';
import TS_EAMCETDownloadPage from './components/ug_homepage/components/Downloads/TS_EAMCETDownloadPage.jsx';

import WbjeeDownloadPage from './components/ug_homepage/components/Downloads/WbjeeDownloadPage.jsx';
import About_us from "./components/ug_homepage/components/About_section/About_us.jsx";





// ******** NEWLY ADDED FROM STATIC MOCK TEST *****************//

import IItjamMockTest1 from './PGMockTest/IITJAM/IItjamMockTest1.jsx'
import CovertJson from "./MockTest/BITSAT/CovertJson.jsx";
import Groupcode from "./MockTest/NEET/Groupcode.jsx";





import StudentFormandMailGate from './PGMockTest/GATE/StudentFormandMailGate.jsx';

import GateMEInstructions from './PGMockTest/GATE/Subjects/GateMESubject/GateMEInstructions.jsx';
import GateMEGeneralInstructions from './PGMockTest/GATE/Subjects/GateMESubject/GateMEGeneralInstructions.jsx';

import GateAEInstructions from './PGMockTest/GATE/Subjects/GateAESubject/GateAEInstructions.jsx';
import GateAEGeneralInstructions from './PGMockTest/GATE/Subjects/GateAESubject/GateAEGeneralInstructions.jsx';

import GATEMockTest1 from "./PGMockTest/GATE/GATEMockTest1.jsx";



import GateXEGeneralInstructions from './PGMockTest/GATE/Subjects/GATEXESubject/GateXEGeneralInstructions.jsx';
import GateXEInstructions from "./PGMockTest/GATE/Subjects/GATEXESubject/GateXEInstructions.jsx"





// import Gate_DA_Instructions from './PGMockTest/GATE/Subjects/Gate_DA_Subject/Gate_DA_Instructions.jsx';
import GateMATHSInstructions from './PGMockTest/GATE/Subjects/GateMathsSubject/GateMATHSInstructions.jsx';

import GateMATHSGeneralInstructions from './PGMockTest/GATE/Subjects/GateMathsSubject/GateMATHSGeneralInstructions.jsx';

import GateMTInstructions from './PGMockTest/GATE/Subjects/GateMTSubject/GateMTInstructions.jsx';

import GateMTGeneralInstructions from './PGMockTest/GATE/Subjects/GateMTSubject/GateMTGeneralInstructions.jsx';
// import Gate_DA_General_Instructions from "./PGMockTest/GATE/Subjects/Gate_DA_Subject/Gate_DA_General_Instructions.jsx";
// import IIT_JAM_PHYSICS_Instructions from "./PGMockTest/IITJAM/Subjects/Physics/IIT_JAM_PHYSICS_Instructions.JSX";


import IItJamBiotechnologyGeneralInstructions from './PGMockTest/IITJAM/Subjects/Biotechnology/IItJamBiotechnologyGeneralInstructions.jsx';

import IItJamBiotechnologyInstructions from './PGMockTest/IITJAM/Subjects/Biotechnology/IItJamBiotechnologyInstructions.jsx';



import IItJamChemistryGeneralInstructions from './PGMockTest/IITJAM/Subjects/Chemistry/IItJamChemistryGeneralInstructions.jsx';

import IItJamChemistryInstructions from './PGMockTest/IITJAM/Subjects/Chemistry/IItJamChemistryInstructions.jsx';


import IITJAMEconomicsGeneralInstructions from './PGMockTest/IITJAM/Subjects/Economics/IITJAMEconomicsGeneralInstructions.jsx';

import IITJAMEconomicsInstructions from './PGMockTest/IITJAM/Subjects/Economics/IITJAMEconomicsInstructions.jsx';


import IITJAMGeologyGeneralInstructions from './PGMockTest/IITJAM/Subjects/Geology/IITJAMGeologyGeneralInstructions.jsx';

import IITJAMGeologyInstructions from './PGMockTest/IITJAM/Subjects/Geology/IITJAMGeologyInstructions.jsx';

import IItjamMAthsGeneralInstructions from './PGMockTest/IITJAM/Subjects/Mathematics/IItjamMAthsGeneralInstructions.jsx';

import IItJamMathsInstructions from './PGMockTest/IITJAM/Subjects/Mathematics/IItJamMathsInstructions.jsx';


import IItjamMathsStatisticsGeneralInstructions from './PGMockTest/IITJAM/Subjects/Mathematical Statistics/IItjamMathsStatisticsGeneralInstructions.jsx';
import IItJamMathematicsInstructions from './PGMockTest/IITJAM/Subjects/Mathematical Statistics/IItJamMathematicsInstructions.jsx';
import IItjamPhysicsGeneralInstructions from "./PGMockTest/IITJAM/Subjects/Physics/IItjamPhysicsGeneralInstructions.jsx";
import IItJamPhysicsInstructions from "./PGMockTest/IITJAM/Subjects/Physics/IItJamPhysicsInstructions.jsx";


import InstructionPage from "./MockTest/BITSAT/InstructionPage.jsx";
import SubmitFormAndMail from "./MockTest/BITSAT/Submitformandmail.jsx";
import Genralinstructions from "./MockTest/BITSAT/Genralinstructions.jsx";
import Mocktest_1 from "./MockTest/BITSAT/Mocktest_1.jsx";
import NEETInstructionPage from "./MockTest/NEET/NEETInstructionPage.jsx";
import NEETGenralinstructions from "./MockTest/NEET/NEETGenralinstructions.jsx";
import BITSATMocktest_1 from "./MockTest/BITSAT/BITSATMocktest_1.jsx";
import VITEEMocktest_1 from "./MockTest/VITEEE/VITEEMocktest1.jsx";
import VITEEESubmitFormandmail from "./MockTest/VITEEE/VITEEESubmitFormandmail.jsx";
import VITEEInstructionsPage1 from "./MockTest/VITEEE/VITEEInstructionPage1.jsx";
import Instructionjee from "./MockTest/JEE(MAINS)/Instructionjee.jsx"
import JeeAdvanceGeneralInstructions from "./MockTest/JEE(ADVANCE)/JeeAdvanceGeneralInstructions.jsx";
import JeeMainsGeneralInstructions from "./MockTest/JEE(MAINS)/JeeMainsGeneralInstructions.jsx";
import VITEEEGenralinstructions from "./MockTest/VITEEE/VITEEEGenralinstructions.jsx";
import JeeMainsMocktest from "./MockTest/JEE(MAINS)/JeeMainsMocktest.jsx";
import InstructionjeeAdvance from "./MockTest/JEE(ADVANCE)/InstructionjeeAdvance.jsx"
// import JeeAdvanceMocktest from "./MockTest/JEE(ADVANCE)/JeeAdvanceMocktest.jsx";
import PG_DOWNLOAD from "./components/PG/PG DOWNLOADS/PG_DOWNLOAD.jsx";
import GateDownloadPage from "./components/PG/PG DOWNLOADS/GateDownloadPage.jsx";
import IitjamDownloadPage from "./components/PG/PG DOWNLOADS/IitjamDownloadPage.jsx";
import EseDownloadPage from "./components/PG/PG DOWNLOADS/EseDownloadPage.jsx";
import TifrDownloadPage from "./components/PG/PG DOWNLOADS/TifrDownloadPage.jsx";
import IsroDownloadPage from "./components/PG/PG DOWNLOADS/IsroDownloadPage.jsx";
import BarcDownloadPage from "./components/PG/PG DOWNLOADS/BarcDownloadPage.jsx";
import JestDownloadPage from "./components/PG/PG DOWNLOADS/JestDownloadPage.jsx";
import NewMockTestformFileTopicwise from './MockTest/JEE(MAINS)/NewMockTestformFileTopicwise.jsx';
import NEETMockTest from "./MockTest/NEET/NEETMockTest.jsx";
import JeeAdvNew from "./MockTest/JEE(ADVANCE)/JeeAdvNew.jsx";
import ScientificCalculator from "./PGMockTest/GATE/ScientificCalculator.jsx";

import GatePIInstructions from './PGMockTest/GATE/Subjects/GatePhysicsSubject/GatePIInstructions.jsx';
import GatePIGeneralInstructions from './PGMockTest/GATE/Subjects/GatePhysicsSubject/GatePIGeneralInstructions.jsx';

import IsroEceGeneralInstructions from './PGMockTest/ISRO/Subjects/ECE_SUBJECT/IsroEceGeneralInstructions.jsx';
import IsroEceInstructions from './PGMockTest/ISRO/Subjects/ECE_SUBJECT/IsroEceInstructions.jsx';
import IsroMocktest1 from './PGMockTest/ISRO/IsroMocktest1.jsx';
import EseMocktest1 from './PGMockTest/ESE/EseMocktest1.jsx';


import PreliminaryExaminstructionsPage from './PGMockTest/ESE/Subjects/PreliminaryExamStage(1)/PreliminaryExaminstructionsPage.jsx';
import PreliminaryExamGeneralInstructionsPage from './PGMockTest/ESE/Subjects/PreliminaryExamStage(1)/PreliminaryExamGeneralInstructionsPage.jsx';




const App = () => {
  return (
    <State>
      <Router>
        <ScrollToTop />
        <Routes>

          {/* ======================================================== LANDING PAGE ================================================================ */}
          {/* This is a link for landing page */}
          <Route path="/" element={<Egate />} />

          {/* ======================================================== PUBLICATION PAGE ================================================================ */}
          {/* This is a link for landing page */}
          {/* ======================================================== ALL HOME PAGES ================================================================ */}

          {/* These are the links for all Home pages */}

          {/* This is a link for UG Home page */}
          <Route path="/home" element={<UG_HOME />} />
          <Route path="/download" element={<Download />} />
          <Route path="/AP_EAPCETDownloadPage" element={<AP_EAPCETDownloadPage />} />
          <Route path="/IitjeeDownloadPage" element={<IitjeeDownloadPage />} />
          <Route path="/JeeAdvancedDownloadPage" element={<JeeAdvancedDownloadPage />} />
          <Route path="/KcetDownloadPage" element={<KcetDownloadPage />} />
          <Route path="/MhcetDownloadPage" element={<MhcetDownloadPage />} />
          <Route path="/NeetDownloadPage" element={<NeetDownloadPage />} />
          <Route path="/TS_EAMCETDownloadPage" element={<TS_EAMCETDownloadPage />} />
          <Route path="/WbjeeDownloadPage" element={<WbjeeDownloadPage />} />

          {/* This ia a link for PG Home page */}
          <Route path="/PgHome" element={<Pg_home_page />} />

          {/* This is a link for MBA Home page */}
          <Route path="/MbaHome" element={<MbaHome />} />


          {/* ======================================================== CONTACT US PAGES ============================================================== */}
          {/* This is a link for UG CONTACT US PAGE */}
          <Route path="/contactUs" element={<Contact />} />

          <Route path="/About_us" element={<About_us />} />

          {/* This is a link for PG CONTACT US PAGE */}
          <Route path="/PgcontactUs" element={<Pg_Countact />} />

          {/* ======================================================== FAQ PAGES ============================================================== */}
          {/* This is a link for UG FAQ PAGE */}
          <Route path="/faq" element={<Faq />} />

          {/* This is a link for PG FAQ PAGE */}
          <Route path="/faqpg" element={<FaqPG />} />


          {/* ======================================================== ALL UG EXAM PAGES(INCLUDING OTS AND OLVC PAGES) ================================================================ */}

          {/* These are the links for all UG Exam pages  */}

          {/* This is the EXAM page OTS Page and OLVC page for IIT-JEE */}

          {/* This is the EXAM page for IIT-JEE */}
          <Route path="/iitjeeExam" element={<IITJEE_EXAM />} />

          {/* This is the OTS page for IIT-JEE */}
          <Route path="/iitjee_otc" element={<JeeOTSPage />} />

          {/* <Route path="/iitjee" element={<OTS />} /> */}
          {/* <Route path="/iitjee_otc" element={<JeeOts />} /> */}

          {/* This is the OLVC page for IIT-JEE */}
          <Route path="/iitjee_olvc" element={<OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for NEET */}

          {/* This is the EXAM page for NEET */}
          <Route path="/neetexam" element={<NeetExam />} />

          {/* This is the OTS page for NEET */}
          <Route path="/neetotc" element={<NeetOtc />} />

          {/* This is the OLVC page for NEET */}
          <Route path="/neet_olvc" element={<NeetOLVC />} />

          {/* This is the EXAM page OTS Page and OLVC page for BITSAT */}

          {/* This is the EXAM page for BITSAT */}
          <Route path="/bitsatexam" element={<BitsatExam />} />

          {/* This is the OTS page for BITSAT */}
          <Route path="/bitsatots" element={<BitsatOTC />} />

          {/* This is the OLVC page for BITSAT */}
          <Route path="/bitsat_olvc" element={<BitsatOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for VITEEE */}

          {/* This is the EXAM page for VITEEE */}
          <Route path="/viteeeexam" element={<ViteeeExam />} />

          {/* This is the OTS page for VITEEE */}
          <Route path="/VITEEE_OTS_PAGE" element={<ViteeeOTSPage />} />

          {/* This is the OLVC page for VITEEE */}
          <Route path="/VITEEE_olvc_PAGE" element={<ViteeeOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for AP-EAPCET */}

          {/* This is the EXAM page for AP-EAPCET */}
          <Route path="/apeamcetexam" element={<ApEamcetExam />} />

          {/* This is the OTS page for AP-EAPCET */}
          <Route path="/AP-EAPCET_OTS_PAGE" element={<Ap_EapcetOTSPage />} />

          {/* This is the OLVC page for AP-EAPCET */}
          <Route path="/ApEapcetOLVC" element={<Ap_EapcetOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for AP-EAPCET */}

          {/* This is the EXAM page for TS-EAMCET */}
          <Route path="/tseamcetexam" element={<TsEamcetExam />} />

          {/* This is the OTS page for TS-EAMCET */}
          <Route path="/TS-EMACET_OTS_PAGE" element={<TS_EamcetOTSPAge />} />

          {/* This is the OLVC page for TS-EAMCET */}
          <Route path="/TS_EamcetOLVC" element={<Ts_EamcetOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for MHCET */}

          {/* This is the EXAM page for MHCET */}
          <Route path="/mhcetexam" element={<MhcetExam />} />

          {/* This is the OTS page for MHCET */}
          <Route path="/MHCET_OTS_PAGE" element={<MhcetOTSPage />} />

          {/* This is the OLVC page for MHCET */}
          {/* This is the EXAM page OTS Page and OLVC page for KCET */}

          {/* This is the EXAM page for KCET */}
          <Route path="/kcetexam" element={<KcetExam />} />

          {/* This is the OTS page for KCET */}
          <Route path="/KCET_OTS_PAGE" element={<KcetOTSPage />} />

          {/* This is the OLVC page for KCET */}
          <Route path="/Kcet_OLVC" element={<Kcet_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for WBJEE */}

          {/* This is the EXAM page for WBJEE */}
          <Route path="/wbjeeexam" element={<WbjeeExam />} />

          {/* This is the OTS page for WBJEE */}
          {/* This is the OLVC page for WBJEE */}
          <Route path="/WbjeeOLVC" element={<WbjeeOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for KEAM */}

          {/* This is the EXAM page for KEAM */}
          <Route path="/keamexam" element={<KeamExam />} />

          {/* This is the OTS page for KEAM */}
          <Route path="/KEAM_OTS_PAGE" element={<KeamOTSPage />} />

          {/* This is the OLVC page for KEAM */}
          <Route path="/Keam_OLVC" element={<KeamOLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for SRMJEE */}

          {/* This is the EXAM page for SRMJEE */}
          <Route path="/srmjeeexam" element={<SrmJeeExam />} />

          {/* This is the OTS page for SRMJEE */}
          <Route path="/SRMJEE_OTS_PAGE" element={<SrmjeeOTSPage />} />

          {/* This is the OLVC page for SRMJEE */}
          <Route path="/Srmjee_OLVC" element={<Srmjee_OLVC />} />

          {/* This is the EXAM page OTS Page and OLVC page for AEEE */}

          {/* This is the EXAM page for AEEE */}
          <Route path="/AeeeExam" element={<AeeeExam />} />

          {/* This is the OTS page for AEEE */}
          <Route path="/AEEE_OTS_PAGE" element={<AeeeOTS />} />

          {/* This is the OLVC page for AEEE */}
          <Route path="/Aeee_OLVC" element={<Aeee_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for MET */}

          {/* This is the EXAM page for MET */}
          <Route path="/Metexam" element={<Metexam />} />

          {/* This is the OTS page for MET */}
          <Route path="/Met_ots" element={<Met_OTS />} />

          {/* This is the OLVC page for MET */}
          <Route path="/Met_OLVC" element={<Met_OLVC />} />


          {/* ========================================================= END OF UG EXAM LINKS ====================================================================== */}



          {/* ======================================================== ALL PG EXAM PAGES(INCLUDING OTS AND OLVC PAGES) ================================================================ */}

          {/* These are the links for all PG Exam pages  */}


          {/* This is the EXAM page OTS Page and OLVC page for GATE */}

          {/* This is the EXAM page for GATE */}
          <Route path="/pggateexam" element={<PGGATE_Exam />} />

          {/* This is the OTS page for GATE */}
          <Route path="/Gate_ots" element={<GATE_OTS_PAGE />} />

          {/* This is the OLVC page for GATE */}
          <Route path="/Gate_OLVC" element={<GATE_OLVC_Page />} />


          {/* This is the EXAM page OTS Page and OLVC page for IITJAM */}

          {/* This is the EXAM page for IITJAM */}
          <Route path="/pgiitjamexam" element={<PGIITJAMExam />} />

          {/* This is the OTS page for IITJAM */}
          <Route path="/iitjam_ots" element={<IIT_JAM_OTS_PAGE />} />

          {/* This is the OLVC page for IITJAM */}
          <Route path="/iitjam_olvc" element={<IIT_JAM_OLVC />} />


          {/* This is the EXAM page OTS Page and OLVC page for ESE */}

          {/* This is the EXAM page for ESE */}
          <Route path="/pgEse_exam" element={<Ese_ExamPage />} />

          {/* This is the OTS page for ESE */}
          <Route path="/est_ots" element={<EST_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for TIFR */}

          {/* This is the EXAM page for TIFR */}
          <Route path="/Ug_Tifr_ExamPage" element={<Pg_Tifr_ExamPage />} />

          {/* This is the OTS page for TIFR */}
          <Route path="/tifr_ots" element={<TIFR_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for ISRO */}

          {/* This is the EXAM page for ISRO */}
          <Route path="/isro_examPage" element={<Pg_Isro_ExamPage />} />

          {/* This is the OTS page for ISRO */}
          <Route path="/isro_ots" element={<ISRO_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for ISRO */}

          {/* This is the EXAM page for BARC */}
          <Route path="/barc_examPage" element={<Pg_BARC_Exam_Page />} />

          {/* This is the OTS page for BARC */}
          <Route path="/barc_ots" element={<BARC_OTS_PAGE />} />


          {/* This is the EXAM page OTS Page and OLVC page for JEST */}

          {/* This is the EXAM page for JEST */}
          <Route path="/jest_examPage" element={<Pg_JEST_ExamPage />} />

          {/* This is the OTS page for JEST */}
          <Route path="/jest_ots" element={<JEST_OTS_PAGE />} />



          {/* ========================================================= END OF PG EXAM LINKS ====================================================================== */}



          <Route path="/terms" element={<TERMsCONDITIONS />} />
          <Route path="/CancellationRefundPolicy" element={<CancellationRefundPolicy />} />
          <Route path="/policy" element={<Privacypolicy />} />
          <Route path="/pricingpolicy" element={<Pricing_Policy />} />

          {/* <Route path="/PayU" element={<Payu />} />
          <Route path='/payubutton' element={<Buttonpayu />} /> */}
          <Route path='/paymentegt' element={<RazorpayForm />} />
         

          {/* <Route path="/IitjeeDownloadPage_new" element={<IitjeeDownloadPage_new />} /> */}



          {/************************ NEWLY ADDED FROM STATIC PAGE BRANCH ***************************************/}
          {/* ********************************************************************************************** */}

      



          {/* ##################################################################################################################################################### */}
          <Route path = '/UGMockTest' element = {<NewMockTestformFileTopicwise />} />
          {/* IITJAM MOCK TEST  */}
          <Route path="/IItjamMockTest1/:selectedSubjectId/:selectedMockTestId" element={<IItjamMockTest1 />} />


          <Route path="/CovertJson" element={<CovertJson />} />

          <Route path="/Groupcode" element={<Groupcode />} />


          <Route path="/GATEMockTest1/:selectedSubjectId/:selectedMockTestId" element={<GATEMockTest1 />} />
          <Route path="/egradPgmocktest" element={<StudentFormandMailGate />} />

          {/* GATE-ME_SUBJECT_JSX_FILES */}


          <Route path='/Gate_ME_Instructions/:selectedSubjectId/:selectedMockTestId' element={<GateMEInstructions />} />


          <Route path="/Gate_ME_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<GateMEGeneralInstructions />} />
          {/* GATE-ME_SUBJECT_JSX_FILES_ END*/}


          {/* GATE-AE_SUBJECT_JSX_FILES */}
          <Route path="/Gate_AE_Instructions/:mockId" element={<GateAEInstructions />} />
          <Route path="/Gate_AE_General_Instructions/:mockId" element={<GateAEGeneralInstructions />} />
          {/* GATE-AE_SUBJECT_JSX_FILES END */}

          {/* GATE-XE_SUBJECT_JSX_FILES */}

          <Route path='/Gate_XE_General_Instructions/:mockId' element={<GateXEGeneralInstructions />} />
          <Route path="/Gate_XE_Instructions/:mockId" element={<GateXEInstructions/>} />
          {/* GATE-XE_SUBJECT_JSX_FILES END */}

          {/* GATE_Physics_SUBJECT_JSX_FILES */}

      

          {/* GATE_Physics_SUBJECT_JSX_FILES END*/}


          {/* GATE_Mathematics_SUBJECT_JSX_FILES */}


          <Route path='/Gate_MATHS_Instructions/:mockId' element={<GateMATHSInstructions />} />

          <Route path="/Gate_MATHS_General_Instructions/:mockId" element={<GateMATHSGeneralInstructions />} />

          {/* GATE_Mathematics_SUBJECT_JSX_FILES END*/}

          {/* GATE_DA_SUBJECT_JSX_FILES */}
   

          {/* <Route path="/Gate_DA_Instructions/:mockId" element={<Gate_DA_Instructions />} /> */}

          <Route path="/Gate_PI_Instructions/:selectedSubjectId/:selectedMockTestId" element={<GatePIInstructions />} />

          <Route path="/Gate_PI_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<GatePIGeneralInstructions/>}/>
          {/* <Route path="/Gate_DA_General_Instructions/:mockId" element={<Gate_DA_General_Instructions />} /> */}
          {/* GATE_DA_SUBJECT_JSX_FILES END*/}

          {/* GATE_MT_SUBJECT_JSX_FILES */}

          <Route path="/Gate_MT_Instructions/:mockId" element={<GateMTInstructions />} />

          <Route path="/Gate_MT_General_Instructions/:mockId" element={<GateMTGeneralInstructions />} />

          {/* GATE_MT_SUBJECT_JSX_FILES END*/}





          {/* IITJAM BIOTECHNOLOGY SUBJECTS FILES */}


          <Route path="/IITJAM_Biotechnology_General_Instructions/:mockId" element={<IItJamBiotechnologyGeneralInstructions />} />
          <Route path="/IIT_JAM_Biotechnology_Instructions/:mockId" element={<IItJamBiotechnologyInstructions />} />
          {/* IITJAM BIOTECHNOLOGY SUBJECTS FILES END*/}


          {/* IITJAM CHEMISTRY SUBJECTS FILES */}

          <Route path="/IITJAM_Chemistry_General_Instructions/:mockId" element={<IItJamChemistryGeneralInstructions />} />
          <Route path="/IIT_JAM_Chemistry_Instructions/:mockId" element={<IItJamChemistryInstructions />} />



          {/* IITJAM CHEMISTRY SUBJECTS FILES END*/}

          {/* IITJAM ECONOMICS SUBJECTS FILES */}


          <Route path="/IITJAM_Economics_General_Instructions/:mockId" element={<IITJAMEconomicsGeneralInstructions />} />
          <Route path="/IIT_JAM_Economics_Instructions/:mockId" element={<IITJAMEconomicsInstructions />} />


          {/* IITJAM ECONOMICS SUBJECTS FILES END*/}

          {/* IITJAM GEOLOGY SUBJECTS FILES */}

          <Route path="/IITJAM_Geology_General_Instructions/:mockId" element={<IITJAMGeologyGeneralInstructions />} />
          <Route path="/IIT_JAM_Geology_Instructions/:mockId" element={<IITJAMGeologyInstructions />} />

          {/* IITJAM GEOLOGY SUBJECTS FILES END*/}



          {/* IITJAM MATHS SUBJECTS FILES */}


          <Route path="/IITJAM_MATHS_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<IItjamMAthsGeneralInstructions />} />
          <Route path="/IIT_JAM_MATHS_Instructions/:selectedSubjectId/:selectedMockTestId" element={<IItJamMathsInstructions />} />

          {/* IITJAM MATHS SUBJECTS FILES END*/}


          {/* IITJAM MATHEMATICS_STATISTICS SUBJECTS FILES */}

          <Route path="/IITJAM_MATHS_STATISTICS_General_Instructions/:mockId" element={<IItjamMathsStatisticsGeneralInstructions />} />
          <Route path="/IIT_JAM_MATHS_STATISTICS_Instructions/:mockId" element={<IItJamMathematicsInstructions />} />

          {/* IITJAM MATHEMATICS_STATISTICS SUBJECTS FILES END*/}


          {/* IITJAM PHYSICS SUBJECTS FILES */}

          <Route path="/IITJAM_PHYSICS_General_Instructions/:mockId" element={<IItjamPhysicsGeneralInstructions />} />
          <Route path='/IIT_JAM_PHYSICS_Instructions/:mockId' element={<IItJamPhysicsInstructions />} />
          {/* IITJAM PHYSICS SUBJECTS FILES END*/}


       




          {/* <Route path='/UgJEE_MandAMockTest' element={<JEE_MandAMockTest />} />
          <Route path="/UgNEET_MockTest" element={<NEET_MockTest />} />
          <Route path="/UgBITSAT_MockTest" element={<BITSAT_MockTest />} />
          <Route path="/UgVITEEE_MockTest" element={<VITEEE_MockTest />} /> */}



{/* 
          <Route path="/mocktest" element={<MocktestBitsat />} />
          <Route path="/Mocktest_1" element={<Mocktest_1 />} /> */}

          {/* BITSAT MOCKTEST */}
          <Route path="/Genralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<Genralinstructions />} />
          <Route path="/InstructionPage/:examId/:mockTestId/:mockTestTypeId" element={<InstructionPage />} />
          <Route path="/BITSATMocktest_1/:examId/:mockTestId/:mockTestTypeId" element={<BITSATMocktest_1 />} />



          {/* JEE MAINS & ADVANCE */}
          <Route path="/Instructionjee/:examId/:mockTestId/:mockTestTypeId" element={<Instructionjee />} />
          <Route path="/JeeMainsGeneralInstructions/:examId/:mockTestId/:mockTestTypeId" element={<JeeMainsGeneralInstructions />} />
          <Route path="/Jee_mains_1/:examId/:mockTestId/:mockTestTypeId" element={<JeeMainsMocktest />} />


          {/*  jee Advance */}
          <Route path="/InstructionjeeAdvance/:examId/:mockTestId/:mockTestTypeId" element={<InstructionjeeAdvance />} />
          <Route path="/JEE_Advance_general_instructions/:examId/:mockTestId/:mockTestTypeId" element={<JeeAdvanceGeneralInstructions/>} />
          <Route path="/JeeAdvanceMocktest/:examId/:mockTestId/:mockTestTypeId" element={<JeeAdvNew/>} />
          {/* END */}

          {/* NEET MOCK TEST  */}
          <Route path="/NEETInstructionPage/:examId/:mockTestId/:mockTestTypeId" element={<NEETInstructionPage />} />
          <Route path="/NEETGenralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<NEETGenralinstructions />} />
          <Route path="/NEETMockTest/:examId/:mockTestId/:mockTestTypeId" element={<NEETMockTest />} />

          {/* VITEE MOCK TEST */}
          {/* <Route path="/VITEEInstructionsPage1/:mockId" element={<VITEEInstructionsPage1 />} />
          <Route path="/VITEEEGenralinstructions/:mockId" element={<VITEEEGenralinstructions />} />
          <Route path="/VITEEMocktest_1/:mockId" element={<VITEEMocktest_1 />} /> */}



 {/* ======================================================== PG DOWNLOADS PAGE ============================================================================= */}
 <Route path="/pgdownload" element={<PG_DOWNLOAD />} />
          <Route path="/gatedownload" element={<GateDownloadPage />} />
          <Route path="/iitjamdownload" element={<IitjamDownloadPage />} />
          <Route path="/esedownload" element={<EseDownloadPage />} />
          <Route path="/tifrdownload" element={<TifrDownloadPage/>} />
          <Route path="/isrodownload" element={<IsroDownloadPage/>} />
          <Route path="/barcdownload" element={<BarcDownloadPage/>} />
          <Route path="/jestdownload" element={<JestDownloadPage/>} />

          <Route path="/ScientificCalculator" element={<ScientificCalculator/>} />




{/* ISRO EXAm mock test files by department wise */}
<Route path = "/ISRO_ECE_General_Instructions/:selectedSubjectId/:selectedMockTestId" element = {<IsroEceGeneralInstructions/>} />
<Route path = "/Isro_ECE_Instructions/:selectedSubjectId/:selectedMockTestId" element = {<IsroEceInstructions/>} />
<Route path = "/ISRO_Mocktest1/:selectedSubjectId/:selectedMockTestId" element = {<IsroMocktest1 />} />



{/* ESE EXAm mock test files by department wise */}
<Route path = "/Preliminary_Exam_instructions_Page/:selectedSubjectId/:selectedMockTestId" element = {<PreliminaryExaminstructionsPage/>} />
<Route path = "/Preliminary_Exam_General_Instructions_Page/:selectedSubjectId/:selectedMockTestId" element = {<PreliminaryExamGeneralInstructionsPage/>} />
<Route path = "/ESE_Mocktest1/:selectedSubjectId/:selectedMockTestId" element={<EseMocktest1 />} />

        </Routes>
        {/* <Footer /> */}
        {/* <LandingPage /> */}
        {/* <PracticesCode /> */}
      </Router>
    </State>
  );
};

export default App;
