

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { NavData } from "./NavData";
import "./header.css";

const Header = () => {
  const examRef = useRef(null);
  const courseRef = useRef(null);
  const headerRef = useRef(null);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isExamsOpen, setIsExamsOpen] = useState(false);
  const [isCoursesOpen, setIsCoursesOpen] = useState(false);
  const [isLiveClassesOpen, setIsLiveClassesOpen] = useState(false);


  const toggleDropdown = (dropdown) => {
    if (dropdown === "exams") {
      setIsExamsOpen(!isExamsOpen);
      setIsCoursesOpen(false);
      setIsLiveClassesOpen(false);
      setIsHeaderActive(true);
    } else if (dropdown === "courses") {
      setIsCoursesOpen(!isCoursesOpen);
      setIsExamsOpen(false);
      setIsLiveClassesOpen(false);
      setIsHeaderActive(true);
    } else if (dropdown === "liveclasses") {
      setIsLiveClassesOpen(!isLiveClassesOpen);
      setIsExamsOpen(false);
      setIsCoursesOpen(false);
      setIsHeaderActive(true);
    }
  };

  const closeAllDropdowns = () => {
    setIsExamsOpen(false);
    setIsCoursesOpen(false);
    setIsLiveClassesOpen(false);
    setIsHeaderActive(false);
  };
  useEffect(() => {
    const handleOutSide = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };
  
    const addClickListener = () => {
      document.body.addEventListener("click", handleOutSide);
    };
    
    const removeClickListener = () => {
      document.body.removeEventListener("click", handleOutSide);
    };
  
    if (isHeaderActive) {
      addClickListener();
    }
    return () => {
      removeClickListener();
    };
  }, [isHeaderActive]);
  

  return (
    <div className="ug_header headerPC " ref={headerRef}>
      {NavData.map((navData, index) => (
        <div className="header" key={index}>
          <div className="logo-header">
            <div className={navData.logo_img_container}>
              <Link to={"/"}>
                <img src={navData.logo} alt="" />
              </Link>
            </div>
            <div className="login_menu">
              <a
                href="https://online-ug.egradtutor.in"
                target="_blank"
                rel="noopener noreferrer"
                className={navData.login}
              >
                {navData.btn1}
              </a>
              <div
                className="mobile_menu mobile_menu_non"
                onClick={() => setShowMenu(!showMenu)}
              >
                <div className={showMenu ? "rotate_right" : "lines"}></div>
                <div className={showMenu ? "no_lines" : "lines"}></div>
                <div className={showMenu ? "rotate_left" : "lines"}></div>
              </div>
            </div>
          </div>
          <div className={showMenu ? "navbox mobile-menu" : "navbox"}>
            <ul className={navData.navbar}>
              <li>
                <a href="/" className={navData.navlist} onClick={closeAllDropdowns}>
                  {navData.link1}
                </a>
              </li>
              <li>
                <a
                  ref={examRef}
                  href="#exam"
                  className="Exams_dropdown "
                  onClick={() => toggleDropdown("exams")}
                >
                  Exams <FaChevronDown className="FaChevronDown" />
                </a>
                {isExamsOpen && (
                  <div className="Exams_dropdown-content">
                    <Link to="/iitjeeExam">JEE</Link>
                    <Link to="/NeetExam">NEET</Link>
                    <Link to="/bitsatexam">BITSAT</Link>
                    <Link to="/viteeeexam">VITEEE</Link>
                  </div>
                )}
              </li>
              <li>
                <a
                  ref={courseRef}
                  href="#courses"
                  className="Exams_dropdown"
                  onClick={() => toggleDropdown("courses")}
                >
                  Courses <FaChevronDown className="FaChevronDown" />
                </a>
                {isCoursesOpen && (
                  <div className="Exams_dropdown-content">
                    <button className="otsOlvcsetIsOtsCoursesOpen">
                      ONLINE TEST SERIES
                    </button>
                    <button className="otsOlvcsetIsLiveClassesOpen">
                      ONLINE LIVE VIDEO CLASSES
                    </button>
                  </div>
                )}
              </li>
              <li>
                <a href="/About_us" className={navData.navlist} onClick={closeAllDropdowns}>
                  {navData.link4}
                </a>
              </li>
              <li>
                <a href="/contactUs" rel="noopener noreferrer" className={navData.navlist}>
                  {navData.link5}
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Header;


