import React from 'react'
import './Footer.css'
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import './StaticFooterCSS.css'
import { IoLogoFacebook } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
const Footer2 = () => {
  return (
    // <div className='footer-container footerBg'>
    //   <footer className='footerToBeFlex '>
    //     {FooterData.map((footerItem, footerIndex) => {
    //       return (
    //         <div key={footerIndex} >
    //           <h4 className={footerItem.footerCs}>{footerItem.fotterTitles}</h4>
    //           <p>{footerItem.text}</p>



    //           <ul className='footer_links_ug' id={footerItem.footerlinksug}>
    //            <li>
    //            <a id={footerItem.footer_l} href={footerItem.PrivacyPolicy} >
    //               <li>{footerItem.home}</li>
    //             </a>
    //            </li>
    //            <li>
    //            <a id={footerItem.footer_l} href={footerItem.TermsAndConditions}>
    //               <li>{footerItem.about}</li>
    //             </a>
    //            </li>
    //             <li>
    //             <a id={footerItem.footer_l} href={footerItem.RefundPolicy}>
    //               <li>{footerItem.career}{footerItem.icon}
    //               </li>
    //             </a>
    //             </li>

    //            <li>
    //            <a id={footerItem.footer_l} href={footerItem.faqLink2}>
    //               <li>{footerItem.faq}{footerItem.icon}
    //               </li>
    //             </a>


                
    //            </li>
    //           </ul>

    //           <div className='icontsFooter'>
    //             <i id='footerIcons' className={footerItem.fb}></i>
    //             <i id='footerIcons' className={footerItem.insta}></i>
    //             <i id='footerIcons' className={footerItem.linkedin}></i>
    //             <a href="https://www.youtube.com/@eGRADTutor" target='_blank'> <i id='footerIcons' className={footerItem.youtube}></i></a>
    //           </div>
    //         </div>
    //       )
    //     })}
    //   </footer>
      
    //   <div className=' footer-linkss' style={{ textAlign: 'center', borderTop: '1px solid #fff', paddingTop: '10px', paddingBottom: '10px', color: '#fff' }}> <p style={{ margin: '0 auto' }}>Copyright © 2024 eGRADTutor All rights reserved</p>
    //     {/* <div className='linkIcons' style={{display: 'flex', }}>
    //       <div className='bgIconLink'>
    //       <i class="fa-brands fa-facebook"></i>
    //       </div>

    //       <div className='bgIconLink'>
    //       <i class="fa-brands fa-instagram"></i>          
    //       </div>

    //       <div className='bgIconLink'>
    //       <i class="fa-brands fa-linkedin"></i>
    //       </div>

    //       <div className='bgIconLink'>
    //       <i class="fa-brands fa-youtube"></i>          
    //       </div>
    //     </div>  */}
    //   </div>
    // </div>
    <div className="footerContainerT2 d-flex aic f-d-col newTheme2PageContainer">
        <div className="footerT2 footerMargin2 d-flex  jcc">
            <div className="footerT2LinksPC d-flex f-d-col">
                <div className='d-flex  footerLogoAndTextDiv aic '>
                    <h4 className='footerTitleColor2'> eGradtutor</h4>
                </div>
                <p className="footerT2Text">eGRADTutor started with a team of graduates from IIT/IISc.We are experts in training students for UG, PG, MBA and CA entrance examinations.</p>
                <div className="footerT2Links d-flex f-d-col">
                    <a href="/policy">Privacy Policy</a>
                    <a href="/terms">Terms and Conditions</a>
                    <a href="/CancellationRefundPolicy">Pricing & Refund Policy</a>
                    <a href="#">Frequently Asked Questions</a>
                </div>

            </div>
            <div className="footerT2ContactUsDiv d-flex f-d-col ">
                <h4 className='footerTitleColor2'>Contact Us</h4>
                <div className="footerT2Location">
                    <div className="footerT2Address">H.NO-2-2-1132/10/C, R.K Nivas, 2nd Floor, Opposite Bakers Q, Shivam Road, New Nallakunta, Hyderabad-44.</div>
                </div>
                <div className="footerT2InfoDiv d-flex">
                    <div className='d-flex aic iconWithTextFooter'>
                    <MdCall />
                    <a href='tel:+917993270532' >+91-7993270532</a>

                    </div>
                    <div className='d-flex aic iconWithTextFooter'>
                    <IoIosMail />
                    <a href='mailto:info@egradtutor.in' >info@egradtutor.in</a>
                    </div>

                </div>
                <div className="footerT2IconsDiv d-flex">
            <div className='iconsContainerT2 d-flex aic jcc footerIcons'>
                <a href="/" >
                {/* <FaFacebookSquare /> */}
                <IoLogoFacebook />
                </a>
                <a href="/">
                    <FaInstagram />
                </a>
                <a href="/">
                    <FaLinkedin />
                </a>
                <a href="https://www.youtube.com/@eGRADTutor" >
                    <FaYoutube />
                </a>
            </div>
        </div>
            </div>
        </div>
        
        <div className="footerT2CPDiv">
            <p>
                Copyright © 2024 eGRADTutor All rights reserved
            </p>
        </div>
    </div>
  )
}
export default Footer2;