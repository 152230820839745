import React from "react";
import Examheader from "../../../UG/Examheader";
import './styles/Downloads.css'
import jeeMain from './Images/JeeMainCourse-removebg-preview.png'
import jeemains from './Images/JEE_MAINS__image-removebg-preview.png'
import apeapcetimg from './Images/Ap-Eapcetimage-removebg-preview.png'
import tseamcetimg from './Images/kcetimage-removebg-preview.png'
import kcetimage from './Images/tseamcetimage-removebg-preview.png'
import mhcetimage from './Images/mhcetimage-removebg-preview.png'
import wbjeeimage from './Images/wbjeeimage-removebg-preview.png'
import neetimage from './Images/neetimagedownload-removebg-preview.png'
import Footer2 from "../../../UG/Footer2";

const Download = () => {

    return (
        <div>
            {/* ======== Logo Header ==================== */}
            <Examheader />
            <div className="Downloadsdiv">
                <div className="DownloadsUgdiv">
                    <h1>UG EXAMS</h1>
                    <div className="DownloadsUgButton">
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={kcetimage} alt="" />
                            </div>
                            <a href="/IitjeeDownloadPage">JEE(MAINS)</a>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={jeeMain} alt="" />
                            </div>
                            {/* <a href="/JeeAdvancedDownloadPage">JEE(ADVANCED)</a> */}
                            <p>JEE(ADVANCED)</p>

                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={neetimage} alt="" />
                            </div>
                            {/* <a href="/NeetDownloadPage">NEET</a> */}
                            <p >NEET</p>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={tseamcetimg} alt="" />
                            </div>
                            {/* <a href="/AP_EAPCETDownloadPage">AP-EAPCET</a> */}
                            <p>AP-EAPCET</p>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={apeapcetimg} alt="" />
                            </div>
                            {/* <a href="/TS_EAMCETDownloadPage">TS-EAMCET</a> */}
                            <p>TS-EAMCET</p>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={jeemains} alt="" />
                            </div>
                            {/* <a href="/KcetDownloadPage">KCET</a> */}
                            <p>KCET</p>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={mhcetimage} alt="" />
                            </div>
                            {/* <a href="/MhcetDownloadPage">MHCET</a> */}
                            <p>MHCET</p>
                        </div>
                        <div className="DownloadsUgButtondiv">
                            <div className="DownloadsUgButtonimg">
                                <img src={wbjeeimage} alt="" />
                            </div>
                            {/* <a href="/WbjeeDownloadPage">WBJEE</a> */}
                            <p>WBJEE</p>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>


            <Footer2 />

        </div>
    )
}

export default Download