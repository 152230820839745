import React, { useEffect, useState } from "react";
import * as math from "mathjs";
import "./Calculator.css";
import '../../components/UG/navbar.css'
import Draggable from "react-draggable";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaRegWindowMaximize } from "react-icons/fa";
const ScientificCalculator = ({ onClose }) => {
  const [input, setInput] = useState("");
  const [result, setResult] = useState("");
  const [mode, setMode] = useState("Deg");
  const [memory, setMemory] = useState(0);
  const [showHelp, setShowHelp] = useState(false)
  const [isMod, setIsMod] = useState(false)
  const [islogXY, setIsLogXY] = useState(false)
  const [displayInput, setDisplayInput] = useState('');
  const [intermediateValue, setIntermediateValue] = useState("");
  const [intermediateExpression, setIntermediateExpression] = useState(""); 
  const handleMemoryRecall = () => {
    const operators = ['+', '-', '/', '*'];
    const lastChar = input.charAt(input.length - 1);
    console.log(lastChar, "This is the last charrrrrrr ")
    if (operators.includes(lastChar)) {
      console.log(`The last character is ${lastChar}and is included in the array`);
      setInput(prev => prev + memory.toString());
    }
    else {
      // if (memory !== null)
      setInput(memory.toString());
      console.log("The input value being set issssssssss..........", input);
    }

    console.log(memory, "This is the memory that is stored in the memory variable");
  }
  const handleMemoryClear = () => {
    setMemory(0)
    console.log("The memory is set to 0 again", memory);
    console.log(memory)
  }
  useEffect(() => {
    console.log('Memory:', memory);
    console.log('Result:', result);
  }, [memory, result]); // Dependencies array
  useEffect(() => {
    // console.log('Memory:', memory);
    console.log('Result:', result);
  }, [result]);

  const handleMemoryStore = (event) => {
    // setMemory(event.target.value);
    try {
      const evaluatedResult = math.evaluate(input);
      console.log(evaluatedResult, "This is the evaluated result", result, "Thos os the value that we need to set for the memoru variable")
      console.log(result, "result tat should be displaued pon the input type")
      setResult(evaluatedResult);
      setMemory(evaluatedResult)
    } catch (error) {
      setResult('Error');
    }
  }
  const isOperatorAtEnd = (expression) => {
    // Check if the expression ends with an operator
    const operators = ["+", "-", "*", "/", "%"];
    return operators.includes(expression.slice(-1));
  };

  const handleButtonClick = (value) => {
    if (value === "π") {
      setInput(input + Math.PI);
    } else if (value === "e") {
      setInput(input + Math.E);
    } else {
      setInput(input + value);
    }
  };




  const handleClear = () => {
    setInput("");
    setResult("");
  };
  const handleBackspace = () => {
    const resultString = result ? result.toString() : "";
    const updatedResult = resultString.slice(0, -1);
    setResult(updatedResult)
    const inputString = input ? input.toString() : "";
    const updatedInput = inputString.slice(0, -1);
    setInput(updatedInput);
    console.log(input, "This is the input after clicking backspace")
  }
  const handleSqrt = () => {
    try {
      setResult(Math.sqrt(math.evaluate(input)));
    } catch (error) {
      setResult("Error");
    }
  };

  const handleModeChange = (event) => {
    setMode(event.target.value);
  };

  const handleTrigFunction = (func) => {
    let evaluatedValue;
    try {
      let value =
        mode === "Deg"
          ? math.evaluate(input) * (Math.PI / 180)
          : math.evaluate(input);
      switch (func) {
        case "sin":
          evaluatedValue = math.sin(value)
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "cos":
          evaluatedValue = math.cos(value);
          setResult(evaluatedValue);
          setInput(evaluatedValue)
          break;
        case "tan":
          evaluatedValue = math.tan(value)
          setResult(evaluatedValue);
          setInput(evaluatedValue)
          break;
        case "asin":
          evaluatedValue = mode === "Deg"
            ? math.asin(math.evaluate(input)) * (180 / Math.PI)
            : math.asin(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue)
          break;
        case "acos":
          evaluatedValue = mode === "Deg"
            ? math.acos(math.evaluate(input)) * (180 / Math.PI)
            : math.acos(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "atan":
          evaluatedValue = mode === "Deg"
            ? math.atan(math.evaluate(input)) * (180 / Math.PI)
            : math.atan(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "sinh":
          evaluatedValue = math.sinh(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "cosh":
          evaluatedValue = math.cosh(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "tanh":
          evaluatedValue = math.tanh(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "asinh":
          evaluatedValue = math.asinh(math.evaluate(input));
          setResult(evaluatedValue);
          setInput(evaluatedValue);
          break;
        case "acosh":
          evaluatedValue = math.acosh(math.evaluate(input))
          setResult(evaluatedValue);
          setInput(evaluatedValue)
          break;
        case "atanh":
          const inputValue = math.evaluate(input);
          if (inputValue <= -1 || inputValue >= 1) {
            setResult("NaN ")
          }
          // setResult(math.atanh(math.evaluate(input)));
          else {
            evaluatedValue = math.atanh(inputValue).toString()
            setResult(evaluatedValue);
            setInput(evaluatedValue);
          }
          break;
        default:
          break;
      }

    } catch (error) {
      setResult("Error");
    }
  };
 
  const extractOperatorInfo = (input) => {
    const hasOperator = /[\+\-\*\/]/.test(input);
    if (hasOperator) {
      const operatorMatch = input.match(/([\d\.]+)\s*([\+\-\*\/])\s*([\d\.]+)/);
      if (operatorMatch) {
        const numberBeforeOperator = operatorMatch[1].trim();
        const operator = operatorMatch[2].trim();
        const numberAfterOperator = operatorMatch[3].trim();
        return { hasOperator, numberBeforeOperator, operator, numberAfterOperator };
      }
    }
    return null;
  };
  const handleOperator = (operator) => {
    // Check if "ln" is included in the expression
    // First check if the input ends with an operator using isOperatorAtEnd
  if (isOperatorAtEnd(input)) {
    setInput(input.slice(0, -1) + operator);
    console.log(`Replaced last operator with: ${operator}`);
    return; 
  }
  const inputStr=String(input || "")
    if (inputStr.includes("ln")) {
      console.log("Yes this expression included ln herrreeeeeee");
      console.log(inputStr, "this is the input strig");
      setInput(`${result}${operator}`);
      try {
        // Use regex to find the ln(...) pattern
        const lnMatch = inputStr.match(/ln\(([^)]+)\)/);
        if (lnMatch) {
          const lnValue = lnMatch[1];
          console.log(lnValue, "lnValue");
          const evaluatedLn = Math.log(lnValue);
          const newInput = inputStr.replace(lnMatch[0], evaluatedLn);
          const evaluatedResult = math.evaluate(newInput);
          setInput(evaluatedResult.toString() + operator);
          setResult(evaluatedResult);
          console.log(result, "result is being set");
        }
      } catch (error) {
        setResult("Error");
        console.error("Error evaluating ln expression:", error);
      }
      return;
    }
    if (inputStr.includes("log")) {
      console.log("Yes this expression included logg in the input");
      console.log(inputStr, "this is the input strig");
      setInput(`${result}${operator}`);
      try {
        // Use regex to find the ln(...) pattern
        const lnMatch = inputStr.match(/log\(([^)]+)\)/);
        if (lnMatch) {
          const lnValue = lnMatch[1];
          console.log(lnValue, "lnValue");
          const evaluatedLn = Math.log10(lnValue);
          const newInput = inputStr.replace(lnMatch[0], evaluatedLn);
          const evaluatedResult = math.evaluate(newInput);
          setInput(evaluatedResult.toString() + operator);
          setResult(evaluatedResult);
          console.log(result, "result is being set");
        }
      } catch (error) {
        setResult("Error");
        console.error("Error evaluating ln expression:", error);
      }
      
      return;
    }
    if(inputStr.includes("powe")){
      console.log("powe is included");
      const poweMatch = inputStr.match(/powe\(([^)]+)\)/);
      console.log(poweMatch,"This is the powerMatch");
      if (poweMatch) {
        const lnValue = poweMatch[1];
        console.log(lnValue, "lnValue");
        const evaluatedLn = Math.exp(lnValue);
        const newInput = inputStr.replace(poweMatch[0], evaluatedLn);
        const evaluatedResult = math.evaluate(newInput);
        setInput(evaluatedResult.toString() );
        setResult(evaluatedResult);
        console.log(result, "result is being set");
      }
      // setInput(result);
    }
    if(inputStr.includes("powTen")){
      console.log("powTen included in the inputString in the handleOperator function");
      try {
        const poweMatch = inputStr.match(/powTen\(([^)]+)\)/); 
        if (poweMatch) {
          const poweValue = poweMatch[1]; 
          console.log(poweValue, "poweValue for evaluation");
          const evaluatedLn = Math.pow(10,poweValue);
          const newInput = input.replace(poweMatch[0], evaluatedLn); 
          const evaluatedResult = math.evaluate(newInput); 
          setResult(evaluatedResult);
          setInput(evaluatedResult.toString()+operator);
          console.log(evaluatedResult, "result of ln evaluation");
          return;
        }
      } catch (error) {
        setResult("Error");
        console.error("Error evaluating ln expression:", error);
        return;
      }
    }
    if(inputStr.includes("sqr")){
      console.log("SQR included");
      setInput(prev=>prev+operator);
      try {
        const sqrMatch=inputStr.match(/sqr\(([^)]+)\)/);
        if(sqrMatch){
          const sqrValue=sqrMatch[1];
          console.log(sqrValue,"sqrValue");
          const evaluatedSqr=math.square(sqrValue);
          console.log(evaluatedSqr,"This is evaluated sqrt value");
          const newInputSqr=inputStr.replace(sqrMatch[0],evaluatedSqr);
          const evaluatedResult=math.evaluate(newInputSqr);
          setInput(evaluatedResult.toString()+operator);
          setResult(evaluatedResult);
          console.log("res is being set",result);
        }
      } catch (error) {
        setInput("Error");

      }
      return;
    }
    else if (input) {
      const evaluatedResult = math.evaluate(input);
      setInput(evaluatedResult.toString());
      setResult(evaluatedResult);
    }
    else if (result && !isOperatorAtEnd(result.toString())) {
      setInput(result.toString() + operator);
    }
    else if (result) {
      console.log("let us seee if the result enters into this block",result);
      setInput(result.toString() + operator);
    }
     if (input && isMod) {
      // First evaluate the current expression
      try {
        const preprocessedInput = input.replace(/Mod/g, "%");
        const evalResult = math.evaluate(preprocessedInput);
        setResult(evalResult);
        setInput(evalResult.toString() + operator);
      } catch (error) {
        setResult("Error");
        console.error("Error evaluating input:", error);
        return;
      }
    } else {

      setInput((prev) => prev + operator);
    }
  };
  const handleLogFunction = (func) => {
    let calculatedLogValue, operatorMatch, hasOperator, operator;
    let inputOrResult = input || result;
    try {
      // Calculate log based on the input
      switch (func) {
        case "log":
          calculatedLogValue = math.log(math.evaluate(inputOrResult));
          setResult(calculatedLogValue);
          console.log("This is in the log function, the input is", input);
          calculatedLogValue = math.log10(math.evaluate(inputOrResult));
          setResult(calculatedLogValue)
          hasOperator = /[\+\-\*\/]/.test(inputOrResult);
          operatorMatch = inputOrResult.match(/([\d\.]+)\s*([\+\-\*\/])\s*([\d\.]+)/);
          if (hasOperator) {
            operator = operatorMatch[2].trim(); 
            console.log(operator, "Te operator issss");
            const numberBeforeOperator = operatorMatch[1].trim();
            console.log(numberBeforeOperator, "numberBeforeOperatornumberBeforeOperator")
            console.log("Operators found in the input.");
            const numberAfterOperator = operatorMatch[3].trim();
            setInput(`${numberBeforeOperator}${operator}log(${numberAfterOperator})`)
            console.log(numberAfterOperator, "this is the number after operator");
            const logInputValue = math.log10(numberAfterOperator);
            setResult(logInputValue);
          }
          else {
            console.log("this is in the else part, it do not have any operators");
            setInput(`log(${input})`)
            console.log("No operators found. Proceeding with log single only.");
            const evaluatedValue = math.evaluate(inputOrResult);
            calculatedLogValue = math.log10(evaluatedValue);
            setResult(calculatedLogValue);
            // setInput(`ln(${inputOrResult})`)
          }
          break;
        case "log2":
          calculatedLogValue = math.log2(math.evaluate(inputOrResult));
          setResult(calculatedLogValue);
          const log2OperatorInfo = extractOperatorInfo();
          if (log2OperatorInfo) {
            const { numberBeforeOperator, numberAfterOperator, operator } = log2OperatorInfo;
            console.log(numberBeforeOperator, numberAfterOperator, operator, "After exevuting the function, numberBeforeOperator,numberAfterOperator,operator");
          }
          setInput(calculatedLogValue);
          break;
        case "loge":
          console.log(input, "This is the input");
          console.log("This is in the ln function, the input is", input);
          if (input.trim().startsWith('-')) {
            console.log("Negative number detected");
            const negativeNumber = input.trim().substring(1);
            console.log("Negative number is", negativeNumber);
            setInput(`0-ln(${negativeNumber})`)
            const calculatedNegLnVal = math.log(math.evaluate(negativeNumber))
            setResult(calculatedNegLnVal)
            return;
          }
          calculatedLogValue = math.log(math.evaluate(inputOrResult));
          setResult(calculatedLogValue)
          hasOperator = /[\+\-\*\/]/.test(inputOrResult);
          operatorMatch = inputOrResult.match(/([\d\.]+)\s*([\+\-\*\/])\s*([\d\.]+)/);
          if (hasOperator) {
            const operator = operatorMatch[2].trim();
            console.log(operator, "Te operator issss");
            const numberBeforeOperator = operatorMatch[1].trim();
            console.log(numberBeforeOperator, "numberBeforeOperatornumberBeforeOperator")
            console.log("Operators found in the input.");
            const numberAfterOperator = operatorMatch[3].trim();
            console.log(numberAfterOperator, "this is the number after operator");
            setInput(`${numberBeforeOperator}${operator}ln(${numberAfterOperator})`)
            const lnInputValue = math.log(numberAfterOperator);
            setResult(lnInputValue);
          } else {
            console.log("No operators found. Proceeding with ln only.");
            const evaluatedValue = math.evaluate(inputOrResult);
            calculatedLogValue = math.log(evaluatedValue);
            setResult(calculatedLogValue);
            setInput(`ln(${inputOrResult})`)
          }
          break;
        case "logxy":
          const [x, y] = inputOrResult.split(",").map(val => math.evaluate(val.trim()));
          if (x <= 0 || x === 1 || y <= 0) throw new Error("Base must be > 0 and not 1, and argument must be > 0.");
          calculatedLogValue = math.log(y) / math.log(x);
          setResult(calculatedLogValue);
          setInput(calculatedLogValue);
          break;
        default:
          return;
      }
    } catch (error) {
      setResult("Error");
      console.error("Error occurred:", error);
    }
  };
// const isOperatorAtEnd=()=
  const handleEvaluate = () => {
    try {
      if (input.includes("ln")) {
        console.log("Handling ln expression in evaluation");
        try {
          const lnMatch = input.match(/ln\(([^)]+)\)/); 
          if (lnMatch) {
            const lnValue = lnMatch[1]; 
            console.log(lnValue, "lnValue for evaluation");
            const evaluatedLn = Math.log(lnValue); 
            const newInput = input.replace(lnMatch[0], evaluatedLn); 
            const evaluatedResult = math.evaluate(newInput); 
            setResult(evaluatedResult);
            setInput(evaluatedResult.toString());
            console.log(evaluatedResult, "result of ln evaluation");
            return;
          }
        } catch (error) {
          setResult("Error");
          console.error("Error evaluating ln expression:", error);
          return;
        }
      }
      if(input.includes("log")){
        console.log("log is included in the input of handleEvaluate function");
          console.log("Handling ln expression in evaluation");
          try {
            const logMatch = input.match(/log\(([^)]+)\)/); 
            if (logMatch) {
              const lnValue = logMatch[1]; 
              console.log(lnValue, "lnValue for evaluation");
              const evaluatedLog = Math.log10(lnValue); 
              const newInput = input.replace(logMatch[0], evaluatedLog); 
              const evaluatedResult = math.evaluate(newInput); 
              setResult(evaluatedResult);
              setInput(evaluatedResult.toString());
              console.log(evaluatedResult, "result of ln evaluation");
              return;
            }
          } catch (error) {
            setResult("Error");
            console.error("Error evaluating ln expression:", error);
            return;
          }
      }
      if (input.includes("powe")) {
        console.log("Handling powe expression in evaluation");
        try {
          const poweMatch = input.match(/powe\(([^)]+)\)/); 
          if (poweMatch) {
            const poweValue = poweMatch[1]; 
            console.log(poweValue, "poweValue for evaluation");
            const evaluatedLn = Math.exp(poweValue);
            const newInput = input.replace(poweMatch[0], evaluatedLn); 
            const evaluatedResult = math.evaluate(newInput); 
            setResult(evaluatedResult);
            setInput(evaluatedResult.toString());
            console.log(evaluatedResult, "result of ln evaluation");
            return;
          }
        } catch (error) {
          setResult("Error");
          console.error("Error evaluating ln expression:", error);
          return;
        }
      }
      if (input.includes("powTen")) {
        console.log("powTen included in this");
        try {
          const powTenMatch=input.match(/powTen\(([^)]+)\)/)
          if(powTenMatch){
            const powTenValue= powTenMatch[1];
            const evaluatedPowTen=math.pow(10,powTenValue);
            const newInput=input.replace(powTenMatch[0],evaluatedPowTen);
            //I think there is no need to replace the newInput 
            const evaluatedPTResult=math.evaluate(newInput);
            setResult(evaluatedPTResult);
            setInput(evaluatedPTResult.toString());
            console.log(evaluatedPTResult,"evaluatedPTResult");
            return;
          }
        } catch (error) {
          setResult("Error");
          console.log("Error while evaluation");
          return;
        }
      }
      if(input.includes("sqr")){
        console.log("sqr included");
        try { 
          const sqrMatch=input.match(/sqr\(([^(]+)\)/);
          if(sqrMatch){
            const sqrValue=sqrMatch[1];
            const evaluatedSqrValue=math.square(sqrValue);
            //  In order to extract the input and set it with the expression value
            const newInput=input.replace(sqrMatch[0],evaluatedSqrValue);
            const evaluatedResult=math.evaluate(newInput);
            setInput(evaluatedResult.toString());
            console.log(evaluatedResult,"");
            setResult(evaluatedResult);
            console.log(evaluatedResult,"evaluatedResult");
            return;
          }
        } catch (error) {
          setResult("Error");
          return;
        }
        return;
      }
      // Handle "Mod" operator separately
      if (isMod) {
        console.log(input, "Evaluating Mod operation");
        const parts = input.split("Mod");
        if (parts.length === 2) {
          const valueOne = parseFloat(parts[0].trim());
          const valueTwo = parseFloat(parts[1].trim());
          if (!isNaN(valueOne) && !isNaN(valueTwo)) {
            const resultOfMod = math.mod(valueOne, valueTwo);
            console.log(resultOfMod, "Mod result");
            setResult(resultOfMod);
            setInput(resultOfMod.toString());
          } else {
            setResult("Error");
          }
        }
        // Reset the isMod flag
        setIsMod(false);
        return; 
      }
      else if (islogXY) {
        console.log("Handling logxBasey");
        const parts = input.split("logxBasey");
        if (parts.length === 2) {
          const valueOne = parseFloat(parts[0].trim());
          const valueTwo = parseFloat(parts[1].trim());
          if (!isNaN(valueOne) && !isNaN(valueTwo) && valueTwo !== 1 && valueTwo > 0) {
            const logValue = math.log(valueOne) / math.log(valueTwo);
            console.log("logxBasey result:", logValue);
            setResult(logValue);
            setInput(logValue.toString());
          } else {
            setResult("Error");
          }
        }
        setIsLogXY(false);
        return; 
      }
      console.log("General expression evaluation:", input);
      if (input.includes("Mod")) {
        const preprocessedInput = input.replace("Mod", "%");
        const evalResult = math.evaluate(preprocessedInput);
        setResult(evalResult);
        setInput(evalResult.toString());
        console.log("Entered into Mod preprocessed function");
      }
      else {
        console.log("Entered into the else mode");
        console.log(input,"this is the input i have to calculate");
        setResult(math.evaluate(input));
      }
    } catch (error) {
      setResult("Error");
      console.error("Evaluation error:", error);
    }
  };

  const handlePowerFunction = (func) => {
    let calculatedResult, calculatedLogValue;
    let inputOrResult = input || result;
    try {
      switch (func) {
        case "exp":
          calculatedResult = math.exp(math.evaluate(input))
          console.log("In exp function");
          // if input has some operators, then we need to seperate it and then evaluate
          const operatorResult=extractOperatorInfo(inputOrResult)
          console.log(operatorResult,"operatorResultOperatorResult")
          if(operatorResult){
            const {hasOperator, numberBeforeOperator, operator, numberAfterOperator}=operatorResult;
            console.log(operatorResult,hasOperator, numberBeforeOperator, operator, numberAfterOperator,"hasOperator, numberBeforeOperator, operator, numberAfterOperator");
            setInput(`${numberBeforeOperator}${operator}powe(${numberAfterOperator})`)
            const calculatedPoweValue=math.exp(math.evaluate(numberAfterOperator))
            console.log(calculatedPoweValue,"calculatedPoweValue")
            setResult(calculatedPoweValue)
          }
          //  if no operators are present, we need to just set to below
          else{
            setInput(`powe(${inputOrResult})`)
          }
          break;
        case "10^x":
          calculatedResult = math.pow(10, math.evaluate(input))
          console.log("In 10 pow x function");
          console.log(input,"This is the input state");
          const powTenOpResult=extractOperatorInfo(input) //in place of input, inputOrResult, if it not works 
          console.log(powTenOpResult);
          if(powTenOpResult){
            const{hasOperator,numberBeforeOperator,numberAfterOperator,operator}=powTenOpResult;
            const calcResult=math.pow(10,math.evaluate(numberAfterOperator));
            console.log("Calculation of number after operator",calcResult);
            setResult(calcResult);
            setInput(`${numberBeforeOperator}${operator}powTen(${numberAfterOperator})`)
          }
          else{
            setInput(`powTen(${input})`)
            setResult(calculatedResult);
          }
          break;
        case "x^2":
          // const inputToEval = input || result
          console.log(result, input,)
          calculatedResult = math.square(math.evaluate(inputOrResult));
          setResult(calculatedResult);
          console.log("This is the calculated result", calculatedResult);
          const sqrOpResult=extractOperatorInfo(input);
          console.log(sqrOpResult);
          if(sqrOpResult){
            const{operator,numberAfterOperator,numberBeforeOperator}=sqrOpResult;
            setInput(`${numberBeforeOperator}${operator}sqr(${numberAfterOperator})`);
            const sqrResult=math.square(math.evaluate(numberAfterOperator));
            console.log(sqrResult);
            setResult(sqrResult);
          }
          else{
            setInput(`sqr(${input})`);
            setResult(calculatedResult)
          }
          console.log(result, "this is result");
          console.log(input, "this is the input");
          break;
        case "x^3":
          
          calculatedResult = math.cube(math.evaluate(input))
          console.log("Cube value");
          const calculatedCubeResult=math.cube(math.evaluate(inputOrResult));
          console.log(calculatedCubeResult);
          setResult(calculatedCubeResult);
          const cubeOpResult=extractOperatorInfo(input);
          if(cubeOpResult){
            const{operator,numberAfterOperator,numberBeforeOperator,hasOperator}=cubeOpResult;
            setInput(`${numberBeforeOperator}${operator}cube(${numberAfterOperator})`);
            const cubeResult=math.cube(math.evaluate(numberAfterOperator));
            console.log(cubeResult);
            setResult(cubeResult);
          }
          else{
            setInput(`cube(${input})`);
            setResult(calculatedCubeResult)
          }
          break;
        case "x^y":
          const [base, exp] = input.split(",").map((val) => math.evaluate(val));
          calculatedResult = math.pow(base, exp)
          setResult(calculatedResult);
          setInput(calculatedResult);
          break;
        default:
          break;
      }
    } catch (error) {
      setResult("Error");
    }
  };
  const handleMPlusButton = () => {

    setMemory(prev => prev + parseFloat(input))
    console.log(memory, "This is the memory after clicking the M+ button")
  }
  const handleMMinusButton = () => {
    setMemory(prev => prev - parseFloat(input));
    console.log(memory, "This is the memory after clicking the M- button")
  }
  useEffect(() => {
    // console.log("Result has been updated:", result);
  }, [result]);

  const handleLogOfXWithBasey = () => {
    if (input !== null) {
      setIsLogXY(true);
      setInput(prev => prev + "logxBasey")
    }
  }
  const handleMod = () => {
    if (!input.includes("Mod")) {
      setInput(prev => prev + "Mod")
    }
    if (input === null) {
      setResult("Error")
    }
    else {
      setIsMod(true)
    }

  };
  const handleFactorial = () => {
    try {
      const factorialValue = math.factorial(math.evaluate(input))
      setResult(factorialValue);
      setInput(factorialValue)
    } catch (error) {
      setResult("Error");
    }
  };

  const handlePercentage = () => {
    try {
      setResult(math.evaluate(input) / 100);
    } catch (error) {
      setResult("Error");
    }
  };

  const handleInverse = () => {
    try {
      setResult(1 / math.evaluate(input));
    } catch (error) {
      setResult("Error");
    }
  };

  const handleCubRoot = () => {
    try {
      setResult(math.cbrt(math.evaluate(input)));
    } catch (error) {
      setResult("Error");
    }
  };

  const handleAbs = () => {
    try {
      setResult(math.abs(math.evaluate(input)));
    } catch (error) {
      setResult("Error");
    }
  };

  const [isVisible, setIsVisible] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);

  const closePopup = () => {
    setIsVisible(false);
  };

  const minimizePopup = () => {
    setIsMinimized(prev => !prev);

  };
  const handleHelpClick = ({ onClose }) => {
    setShowHelp(prev => !prev);
  }
  return (
    <Draggable>
      <div className={isMinimized ? "hideTheCalc Calculatormaindivpopup" : "Calculatormaindiv Calculatormaindivpopup"}>
        <div className="Calculatorheader">
          {" "}
          <h4 className="calcName">Scientific Calculator</h4>{" "}
          <div className={`Calculatorheaderright ${isMinimized ? 'minimized' : ''} ${isVisible ? 'show' : ''}`}>
            <button className="Calculatorhelp" onClick={handleHelpClick} >
              {showHelp ? "Back" : "Help"}
            </button>
            {isMinimized ?
              (<FaRegWindowMaximize style={{fontSize: "28px", cursor: "pointer" }} onClick={minimizePopup} />) :
              (<i className="fa-solid fa-minus minusButton" onClick={minimizePopup}></i>)
            }
            <i className="fa-solid fa-xmark" onClick={onClose}></i>
          </div>
        </div>

        {showHelp ? (<>
          <div className="helpDivInCalc">
            <h2 className="instructionsHeading">Calculator Instructions</h2>
            <div>
              <p> You can operate the calculator using the buttons provided on screen with your mouse. </p>
              <p>
                Allows you to perform basic and complex mathematical operations such as modulus, square root, cube root, trigonometric, exponential, logarithmic, hyperbolic functions, etc.</p>
            </div>
            <h2 className="headdingDos">Do's:</h2>
            <ul>
              <li> Be sure to press [C] when beginning a new calculation.</li>
              <li> Simply an equation using parenthesis and other mathematical operators.</li>
              <li> Use the predefined operations such as p (Pi), log, Exp to save time during calculation.</li>
              <li> Use memory function for calculating cumulative totals.</li>
              <strong>
                [M+]: Will add displayed value to memory.
              </strong>
              <br />
              <strong>
                [MR]: Will recall the value stored in memory.
              </strong>
              <br />
              <strong>
                [M-]: Subtracts the displayed value from memory.
              </strong>
              <br />
              <li> Be sure select the angle unit (Deg or Rad) before beginning any calculation.</li>
              <strong>Note: By default angle unit is set as Degree</strong>
            </ul>
            <h2 className="toBeRed"><span>Don'ts:</span></h2>
            <ul>
              <li>"Perform multiple operations together."</li>
              <li>"Leave parenthesis unbalanced."</li>
              <li>"Change the angle unit (Deg or Rad) while performing a calculation.."</li>
            </ul>
            <h2><span>Limitations:</span></h2>
            <ul>
              <li>"Keyboard operation is disabled."</li>
              <li>"The output for a Factorial calculation is precise up to 14 digits."</li>
              <li>"The output for Logarithmic and Hyperbolic calculations is precise up to 5 digits."</li>
              <li>"Modulus (mod) operation performed on decimal numbers with 15 digits would not be precise."</li>
              <br />
              <strong> Use mod operation only if the number comprises of less than 15 digits i.e. mod operation provides best results for smaller numbers.</strong>
              <br />
              <li>The range of value supported by the calculator is 10(-323) to 10(308).</li>
            </ul>

          </div>
        </>
        ) : (
          <>
            <div className="calculator1 ">
              <input type="text" value={input} readOnly className="calculatorinput" />
              <div className="calculatorinput">{result}</div>
              <div className="calculatorbuttons">
                <div className="firstcolom">
                  <button className="calculatorbutton" onClick={handleMod}>
                    mod
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("sinh")}
                  >
                    sinh
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("asinh")}
                  >
                    sinh⁻¹
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleButtonClick("π")}
                  >
                    π
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("sin")}
                  >
                    sin
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("asin")}
                  >
                    sin⁻¹
                  </button>
                </div>
                <div className="firstcolom">
                  <div className="mode">
                    <label>
                      <input
                        type="radio"
                        value="Deg"
                        checked={mode === "Deg"}
                        onChange={handleModeChange}
                      />
                      Deg
                    </label>
                  </div>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("cosh")}
                  >
                    cosh
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("acosh")}
                  >
                    cosh⁻¹
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleButtonClick("e")}
                  >
                    e
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("cos")}
                  >
                    cos
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("acos")}
                  >
                    cos⁻¹
                  </button>
                </div>
                <div className="firstcolom">
                  <div className="mode">
                    <label>
                      <input
                        type="radio"
                        value="Rad"
                        checked={mode === "Rad"}
                        onChange={handleModeChange}
                      />
                      Rad
                    </label>
                  </div>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("tanh")}
                  >
                    tanh
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("atanh")}
                  >
                    tanh⁻¹
                  </button>
                  <button className="calculatorbutton" onClick={handleFactorial}>
                    n!
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("tan")}
                  >
                    tan
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleTrigFunction("atan")}
                  >
                    tan⁻¹
                  </button>
                </div>
                <div className="threedcolom">
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("exp")}
                  >
                    Exp
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleLogFunction("log2")}
                  >
                    log₂X
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={
                      // () => handleLogFunction("logxy")
                      handleLogOfXWithBasey

                    }
                  >
                    {/* logₓy */}
                    log<sub>y</sub>x
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("x^y")}
                  >
                    xʸ
                  </button>
                  <button className="calculatorbutton" onClick={handleCubRoot}>
                    ³√x
                  </button>
                </div>
                <div className="threedcolom">
                  <button
                    className="calculatorbutton"
                    onClick={() => handleButtonClick("(")}
                  >
                    (
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleLogFunction("loge")}
                  >
                    ln
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("exp")}
                  >
                    eˣ
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("x^3")}
                  >
                    x³
                  </button>
                  <button className="calculatorbutton" onClick={handleCubRoot}>

                    ∛
                  </button>
                </div>
                <div className="threedcolom">
                  <button
                    className="calculatorbutton"
                    onClick={() => handleButtonClick(")")}
                  >
                    )
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handleLogFunction("log")}
                  >
                    log
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("10^x")}
                  >
                    10ˣ
                  </button>
                  <button
                    className="calculatorbutton"
                    onClick={() => handlePowerFunction("x^2")}
                  >
                    x²
                  </button>
                  <button className="calculatorbutton" onClick={handleAbs}>
                    |X|
                  </button>
                </div>
                <div className="calculatortoplines">
                  <div className="firstlineright">
                    <button className="calculatorbutton" onClick={handleMemoryClear}>MC</button>
                    <button className="calculatorbutton" onClick={handleMemoryRecall}>MR</button>
                    <button className="calculatorbutton" onClick={handleMemoryStore} >MS</button>
                    <button className="calculatorbutton" onClick={handleMPlusButton} >M+</button>
                    <button className="calculatorbutton" onClick={handleMMinusButton} >M-</button>
                  </div>
                  <div className="firstlineright">
                    <button
                      className="calculatorbutton calculatorBackspace"
                      onClick={handleBackspace}
                    >
                      {/* <i className="fa-solid fa-arrow-left"></i> */}
                      <FaArrowLeftLong />
                    </button>
                    <button
                      className="calculatorbutton calculatorClear"
                      onClick={handleClear}
                    >
                      C
                    </button>
                    <button className="calculatorbutton">+/-</button>
                    <button className="calculatorbutton" onClick={handleSqrt}>
                      √
                    </button>
                  </div>
                  <div className="firstlineright">
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("7")}
                    >
                      7
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("8")}
                    >
                      8
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("9")}
                    >
                      9
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleOperator("/")}
                    >
                      /
                    </button>
                    <button className="calculatorbutton" onClick={handlePercentage}>
                      %
                    </button>
                  </div>
                  <div className="firstlineright">
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("4")}
                    >
                      4
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("5")}
                    >
                      5
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("6")}
                    >
                      6
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleOperator("*")}
                    >
                      *
                    </button>
                    <button className="calculatorbutton" onClick={handleInverse}>
                      1/x
                    </button>
                  </div>
                  <div className="secondlineright">
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("1")}
                    >
                      1
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("2")}
                    >
                      2
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick("3")}
                    >
                      3
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleOperator("-")}
                    >
                      -
                    </button>
                  </div>
                  <div className="calculatorequalto" >
                    <button
                      className="calculatorbutton calculatorequalto1"
                      onClick={handleEvaluate}
                    >
                      =
                    </button>
                  </div>
                  <div className="secondlineright secondlineright11 ">
                    <button
                      className="calculatorbutton calculatorzero"
                      onClick={() => handleButtonClick("0")}
                    >
                      0
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleButtonClick(".")}
                    >
                      .
                    </button>
                    <button
                      className="calculatorbutton"
                      onClick={() => handleOperator("+")}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        }
      </div>
   </Draggable>
  );
};

export default ScientificCalculator;



















// import React, { useState } from "react";
// import Draggable from "react-draggable";
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { FaRegWindowMaximize } from "react-icons/fa";
// import "./Calculator.css";
// const ScientificCalculator = ({ onClose }) => {
//   const [expression, setExpression] = useState(""); // To store the input expression
//   const [result, setResult] = useState(""); // To store the calculated result
//   const [isVisible, setIsVisible] = useState(true);
//   const [isMinimized, setIsMinimized] = useState(false);
//   const [showHelp, setShowHelp] = useState(false);
//   const [mode, setMode] = useState("Deg");
//   // Function to handle button click
//   const handleButtonClick = (value) => {
//     setExpression((prev) => prev + value);
//   };

//   const minimizePopup = () => {
//     setIsMinimized((prev) => !prev);
//   };
//   const handleHelpClick = ({ onClose }) => {
//     setShowHelp((prev) => !prev);
//   };

//   // Function to handle special operators like ln, log, trigonometric functions, etc.
//   const handleSpecialFunction = (func) => {
//     try {
//       let modifiedExpression = expression;
//       switch (func) {
//         case "sin":
//         case "cos":
//         case "tan":
//           modifiedExpression = `Math.${func}((${expression}) * Math.PI / 180)`; // Convert to radians
//           break;
//         case "asin":
//         case "acos":
//         case "atan":
//           modifiedExpression = `Math.${func}(${expression}) * 180 / Math.PI`; // Convert to degrees
//           break;
//         case "sinh":
//         case "cosh":
//         case "tanh":
//           modifiedExpression = `Math.${func}(${expression})`;
//           break;
//         case "asinh":
//         case "acosh":
//         case "atanh":
//           modifiedExpression = `Math.${func}(${expression})`;
//           break;
//         case "ln":
//           modifiedExpression = `Math.log(${expression})`;
//           break;
//         case "log":
//           modifiedExpression = `Math.log10(${expression})`;
//           break;
//         case "logxy": // Logarithm with base y
//           const [base, num] = expression.split(",");
//           modifiedExpression = `Math.log(${num}) / Math.log(${base})`; // log base x of y
//           break;
//         case "log2": // Logarithm with base 2
//           modifiedExpression = `Math.log(${expression}) / Math.log(2)`; // log base 2 of x
//           break;
//         case "√":
//           modifiedExpression = `Math.sqrt(${expression})`;
//           break;
//         case "∛":
//           modifiedExpression = `Math.cbrt(${expression})`; // Cube root
//           break;
//         case "x³":
//           modifiedExpression = `Math.pow(${expression}, 3)`; // x cubed
//           break;
//         case "y√x":
//           const [rootBase, radicand] = expression.split(",");
//           modifiedExpression = `Math.pow(${radicand}, 1/${rootBase})`; // y-th root of x
//           break;
//         case "10^x":
//           modifiedExpression = `Math.pow(10, ${expression})`;
//           break;
//         case "e^x":
//           modifiedExpression = `Math.exp(${expression})`;
//           break;
//         case "x^2":
//           modifiedExpression = `Math.pow(${expression}, 2)`;
//           break;
//         case "x^y":
//           modifiedExpression = `${expression}**`; // To append for further power input
//           break;
//         case "1/x":
//           modifiedExpression = `1/(${expression})`;
//           break;
//         case "π":
//           modifiedExpression = `${expression} * Math.PI`;
//           break;

//         case "e":
//           modifiedExpression = `${expression} * Math.E`;
//           break;
//         case "exp":
//           modifiedExpression = `Math.exp(${expression})`; // e^x
//           break;
//         case "n!":
//           const factorial = (n) => (n === 0 ? 1 : n * factorial(n - 1));
//           modifiedExpression = factorial(parseInt(expression, 10));
//           break;
//         case "mod":
//           const [dividend, divisor] = expression.split(",");
//           modifiedExpression = `${dividend} % ${divisor}`; // Modulus operation
//           break;
//         case "abs": // Absolute value
//           modifiedExpression = `Math.abs(${expression})`;
//           break;
//         case "percent": // Percentage
//           modifiedExpression = `${expression} / 100`; // Convert to decimal
//           break;
//           case "negate": // Negate the expression
//           modifiedExpression = `-${expression}`;
//           break;
//         default:
//           return;
//       }

//       setExpression(modifiedExpression);
//     } catch (error) {
//       setResult("Error");
//     }
//   };

//   // Function to evaluate the final expression
//   const calculate = () => {
//     try {
//       const evalResult = eval(expression); // Using eval to evaluate expressions dynamically
//       setResult(evalResult);
//     } catch (error) {
//       setResult("Error");
//     }
//   };

//   // Clear the input and result
//   const clear = () => {
//     setExpression("");
//     setResult("");
//   };

//   // Delete the last character from the expression
//   const backspace = () => {
//     setExpression(expression.slice(0, -1));
//   };
//   const handleModeChange = (event) => {
//     setMode(event.target.value);
//   };

//   return (
//     <Draggable>
//       <div
//         className={
//           isMinimized
//             ? "hideTheCalc Calculatormaindivpopup"
//             : "Calculatormaindiv Calculatormaindivpopup"
//         }
//       >
//         <div className="Calculatorheader">
//           <h4 className="calcName">Scientific Calculator</h4>{" "}
//           <div
//             className={`Calculatorheaderright ${
//               isMinimized ? "minimized" : ""
//             } ${isVisible ? "show" : ""}`}
//           >
//             <button className="Calculatorhelp" onClick={handleHelpClick}>
//               {showHelp ? "Back" : "Help"}
//             </button>
//             {isMinimized ? (
//               <FaRegWindowMaximize
//                 style={{ fontSize: "28px", cursor: "pointer" }}
//                 onClick={minimizePopup}
//               />
//             ) : (
//               <i
//                 className="fa-solid fa-minus minusButton"
//                 onClick={minimizePopup}
//               ></i>
//             )}
//             <i className="fa-solid fa-xmark" onClick={onClose}></i>
//           </div>
//         </div>
//         {showHelp ? (
//           <>
//             <div className="helpDivInCalc">
//               <h2 className="instructionsHeading">Calculator Instructions</h2>
//               <div>
//                 <p>
//                   {" "}
//                   You can operate the calculator using the buttons provided on
//                   screen with your mouse.{" "}
//                 </p>
//                 <p>
//                   Allows you to perform basic and complex mathematical
//                   operations such as modulus, square root, cube root,
//                   trigonometric, exponential, logarithmic, hyperbolic functions,
//                   etc.
//                 </p>
//               </div>
//               <h2 className="headdingDos">Do's:</h2>
//               <ul>
//                 <li> Be sure to press [C] when beginning a new calculation.</li>
//                 <li>
//                   {" "}
//                   Simply an equation using parenthesis and other mathematical
//                   operators.
//                 </li>
//                 <li>
//                   {" "}
//                   Use the predefined operations such as p (Pi), log, Exp to save
//                   time during calculation.
//                 </li>
//                 <li> Use memory function for calculating cumulative totals.</li>
//                 <strong>[M+]: Will add displayed value to memory.</strong>
//                 <br />
//                 <strong>[MR]: Will recall the value stored in memory.</strong>
//                 <br />
//                 <strong>
//                   [M-]: Subtracts the displayed value from memory.
//                 </strong>
//                 <br />
//                 <li>
//                   {" "}
//                   Be sure select the angle unit (Deg or Rad) before beginning
//                   any calculation.
//                 </li>
//                 <strong>Note: By default angle unit is set as Degree</strong>
//               </ul>
//               <h2 className="toBeRed">
//                 <span>Don'ts:</span>
//               </h2>
//               <ul>
//                 <li>"Perform multiple operations together."</li>
//                 <li>"Leave parenthesis unbalanced."</li>
//                 <li>
//                   "Change the angle unit (Deg or Rad) while performing a
//                   calculation.."
//                 </li>
//               </ul>
//               <h2>
//                 <span>Limitations:</span>
//               </h2>
//               <ul>
//                 <li>"Keyboard operation is disabled."</li>
//                 <li>
//                   "The output for a Factorial calculation is precise up to 14
//                   digits."
//                 </li>
//                 <li>
//                   "The output for Logarithmic and Hyperbolic calculations is
//                   precise up to 5 digits."
//                 </li>
//                 <li>
//                   "Modulus (mod) operation performed on decimal numbers with 15
//                   digits would not be precise."
//                 </li>
//                 <br />
//                 <strong>
//                   {" "}
//                   Use mod operation only if the number comprises of less than 15
//                   digits i.e. mod operation provides best results for smaller
//                   numbers.
//                 </strong>
//                 <br />
//                 <li>
//                   The range of value supported by the calculator is 10(-323) to
//                   10(308).
//                 </li>
//               </ul>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="calculator1 ">
//               <input
//                 type="text"
//                 value={expression}
//                 placeholder="0"
//                 disabled
//                 className="calculatorinput"
//               />
//               <div className="calculatorinput">
//                 {result ? "=" + result : ""}
//               </div>
//             </div>

//             <div className="calculatorbuttons">
//               <div className="firstcolom">
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("mod")}
//                 >
//                   mod
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("sinh")}
//                 >
//                   sinh
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("asinh")}
//                 >
//                   sinh⁻¹
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("π")}
//                 >
//                   π
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("sin")}
//                 >
//                   sin
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("asin")}
//                 >
//                   sin⁻¹
//                 </button>
//               </div>

//               <div className="firstcolom">
//                 <div className="mode">
//                   <label>
//                     <input
//                       type="radio"
//                       value="Deg"
//                       checked={mode === "Deg"}
//                       onChange={handleModeChange}
//                     />
//                     Deg
//                   </label>
//                 </div>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("cosh")}
//                 >
//                   cosh
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("acosh")}
//                 >
//                   cosh⁻¹
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("e")}
//                 >
//                   e
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("cos")}
//                 >
//                   cos
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("acos")}
//                 >
//                   cos⁻¹
//                 </button>
//               </div>
//               <div className="firstcolom">
//                 <div className="mode">
//                   <label>
//                     <input
//                       type="radio"
//                       value="Rad"
//                       checked={mode === "Rad"}
//                       onChange={handleModeChange}
//                     />
//                     Rad
//                   </label>
//                 </div>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("tanh")}
//                 >
//                   tanh
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("atanh")}
//                 >
//                   tanh⁻¹
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("n!")}
//                 >
//                   n!
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("tan")}
//                 >
//                   tan
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("atan")}
//                 >
//                   tan⁻¹
//                 </button>
//               </div>
//               <div className="threedcolom">
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("exp")}
//                 >
//                   Exp
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("log2")}
//                 >
//                   log₂X
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("logxy")}
//                 >
//                   log<sub>y</sub>x
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("x^y")}
//                 >
//                   xʸ
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("y√x")}
//                 >
//                   y√x
//                 </button>
//               </div>
//               <div className="threedcolom">
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleButtonClick("(")}
//                 >
//                   (
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("ln")}
//                 >
//                   ln
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("e^x")}
//                 >
//                   eˣ
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("x³")}
//                 >
//                   x³
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("∛")}
//                 >
//                   ∛
//                 </button>
//               </div>
//               <div className="threedcolom">
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleButtonClick(")")}
//                 >
//                   {" "}
//                   )
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("log")}
//                 >
//                   log
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("10^x")}
//                 >
//                   10ˣ
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("x^2")}
//                 >
//                   x²
//                 </button>
//                 <button
//                   className="calculatorbutton"
//                   onClick={() => handleSpecialFunction("abs")}
//                 >
//                   |X|
//                 </button>
//               </div>

//               <div className="calculatortoplines">
//                 <div className="firstlineright">
//                   <button className="calculatorbutton">MC</button>
//                   <button className="calculatorbutton">MR</button>
//                   <button className="calculatorbutton">MS</button>
//                   <button className="calculatorbutton">M+</button>
//                   <button className="calculatorbutton">M-</button>
//                 </div>
//                 <div className="firstlineright">
//                   <button
//                     className="calculatorbutton calculatorBackspace"
//                     onClick={backspace}
//                   >
//                     {/* ← */}
//                     <FaArrowLeftLong />
//                   </button>
//                   <button
//                     className="calculatorbutton calculatorClear"
//                     onClick={clear}
//                   >
//                     C
//                   </button>
//                   <button className="calculatorbutton calculatorClear" onClick={() => handleSpecialFunction("negate")}>+/-</button>
//                   <button
//                     className="calculatorbutton"
//                     onClick={() => handleSpecialFunction("√")}
//                   >
//                     √
//                   </button>
                 
//                 </div>

//                 <div className="firstlineright">
//                   <button
//                     className="calculatorbutton"
//                     onClick={() => handleButtonClick("7")}
//                   >
//                     7
//                   </button>
//                   <button
//                     className="calculatorbutton"
//                     onClick={() => handleButtonClick("8")}
//                   >
//                     8
//                   </button>
//                   <button
//                     className="calculatorbutton"
//                     onClick={() => handleButtonClick("9")}
//                   >
//                     9
//                   </button>
//                   <button
//                     className="calculatorbutton"
//                     onClick={() => handleButtonClick("/")}
//                   >
//                     /
//                   </button>
//                   <button  className="calculatorbutton" onClick={() => handleSpecialFunction("percent")}>
//                     %
//                   </button>
//                 </div>
//                 <div className="firstlineright">
//                 <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("4")}
//               >
//                 4
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("5")}
//               >
//                 5
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("6")}
//               >
//                 6
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("*")}
//               >
//                 *
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleSpecialFunction("1/x")}
//               >
//                 1/x
//               </button>
//                 </div>
//                 <div className="secondlineright">
//                 <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("1")}
//               >
//                 1
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("2")}
//               >
//                 2
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("3")}
//               >
//                 3
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("-")}
//               >
//                 -
//               </button>
//               </div>
//               <div className="calculatorequalto" >
//               <button className="calculatorbutton calculatorequalto1" onClick={calculate}>
//                 =
//               </button>

//                 </div>
//                 <div className="secondlineright secondlineright11 ">

//                 <button
//                 className="calculatorbutton calculatorzero"
//                 onClick={() => handleButtonClick("0")}
//               >
//                 0
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick(".")}
//               >
//                 .
//               </button>
//               <button
//                 className="calculatorbutton"
//                 onClick={() => handleButtonClick("+")}
//               >
//                 +
//               </button>
              
//                 </div>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     </Draggable>
//   );
// };
// export default ScientificCalculator;
